import React, { useState } from 'react';
import { List, Col, Input, message, Divider, Form, Spin } from 'antd';
import CardHeader from '../../../../components/CardHeader';
import coreApiClient from '../../../../store/apiService';

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const EmailSettings = (props) => {
    const [editingPrimaryEmail, setEditingPrimaryEmail] = useState(false)
    const [editingSecondaryEmail, setEditingSecondaryEmail] = useState(false)

    const userProfile = props.userProfile ? props.userProfile : {};

    const [form] = Form.useForm();

    const updateEmail = e => {
      e.preventDefault()
      const newEmail = form.getFieldValue('email');

      if (validateEmail(newEmail)) {
        coreApiClient.profile.updateDetail({
          email: newEmail,
        })
        .then(res => {
            message.success('Successfully updated user email')
            setEditingPrimaryEmail(false)
            props.fetchUserProfile()   
        })
        .catch(error => {
            // handle error
            message.error(error.message)
            setEditingPrimaryEmail(false)
        });
      } else {
        message.error('Please input a valid email address')
      }
    }

    const data = [
      {
        title: 'Primary Email',
        description: editingPrimaryEmail ? 
          <Form form={form}>
            <Form.Item style={{width:'250px'}} name='email' rules={[
              { required: true, message: 'Please provide a valid email'},
              { type: 'email', message: 'The input is not valid email!'}
              ]}>
              <Input />
            </Form.Item> 
          </Form>
          : 
          <span>{userProfile.email}</span>,
        action:
          <div>
            {editingPrimaryEmail ? 
            <div>
              <a href='#' onClick={(e) => updateEmail(e)}>
                Save
              </a>
              <Divider type='vertical' />
              <a href='#' onClick={() => setEditingPrimaryEmail(false)}>Cancel</a>
            </div> 
            : 
            <a onClick={() => {
              form.setFieldsValue({email: userProfile.email});
              setEditingPrimaryEmail(true);
            }}>Change</a>}
          </div>,
      },
      // {
      //   title: 'Secondary Email',
      //   description: <span>Secondary Email: {this.props.email}</span>,
      //   action: <span><a onClick={() => this.setState({editingPrimaryEmail: !this.state.editingPrimaryEmail})}>Change</a></span>,
      // },
    ];

        return (
          <Col md={18} lg={20}
          style={{paddingLeft:'40px'}}
          >
            <CardHeader title='Email' subtitle='Settings' description='Manage email preferences' />
            {props.loadingUserProfile ? 
            <div style={{textAlign:'center', paddingTop:'50px'}}><Spin /></div>
            :
            <div>
              <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={item => (
                  <List.Item actions={[item.action]}>
                      <List.Item.Meta
                      title={<span>{item.title}</span>}
                      description={item.description}
                      />
                  </List.Item>
                  )}
              />
            </div>}
          </Col>
        )
}

export default EmailSettings;

