import React, {useState} from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import { Card, Row, Divider, Button } from 'antd';

const CustomTrelloCard = props => {
  const [displayDelete, toggleDeleteIcon] = useState(false)

  return (
      <Card
      onMouseEnter={() => toggleDeleteIcon(true)}
      onMouseLeave={() => toggleDeleteIcon(false)}
      bodyStyle={{padding:'15px'}}
      style={{width:'350px', borderRadius:'4px', marginTop:'12px', cursor: 'grab'}}
      bordered={false}
      >
          <div>
              <strong style={{fontSize:'14px', wordBreak:'break-all', whiteSpace:'initial'}}>{props.title}</strong>
              <p style={{whiteSpace:'initial', wordBreak:'break-all', fontSize:'13px',color:'#666'}}>{props.description}</p>
              <span style={{fontSize:'12px', fontWeight:500, color:'#333'}}>{props.due_date ? `Due: ${props.due_date}` : null }</span>
              <div style={{
                  position:'absolute',
                  height:'10px',
                  width: 'auto',
                  right: 0, 
                  top: 0, 
                  padding: '1px 8px', 
                  fontSize: '12px',
                  transition: 'all 0.5s',
                  opacity: displayDelete ? 1 : 0
              }}>
                  <Button type="link" icon={<DeleteOutlined />} onClick={() => props.deleteTask(props.object_id)} />
              </div>
          </div>
      </Card>
  );
}

export default CustomTrelloCard;