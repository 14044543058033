import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Select, Input, Row, Form, DatePicker, Col, message, Spin, Empty } from 'antd';
import workflowApiClient from '../../../store/apiService';
import coreApiClient from '../../../store/apiService';
import { HomeOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const CreateMaintenanceDrawer = (props) => {
  const [loading, setloading] = useState(false)
  const [visible, setVisibility] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [propertiesList, setPropertiesList] = useState([]);
  const [loadingPropertiesList, setLoadingPropertiesList] = useState(false);
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [loadingUnits, setLoadingUnitsState] = useState(false);

  useEffect(() => {
    setLoadingPropertiesList(true)
    coreApiClient.properties.fetchList()
    .then(res => {
        console.log(res.data)
        setPropertiesList(res.data.data)
        setLoadingPropertiesList(false)
    })
    .catch(error => {
        console.log(error)
        setLoadingPropertiesList(false)
    })
}, []);

  const [form] = Form.useForm();
  const taskDetail = props.taskDetail;

  const showDrawer = () => {
    setVisibility(true)
  };

  const onClose = () => {
    form.resetFields()
    setVisibility(false)
  };

  
  const unitsList = (propertyUnits) => {
    const units = [];
    let sortedUnits = propertyUnits.sort((a, b) => a.unit_name.localeCompare(b.unit_name))
    for (let i = 0; i < sortedUnits.length; i++) {
      units.push(
        <Option value={sortedUnits[i].unit_name}>{sortedUnits[i].unit_name} - {sortedUnits[i].unit_type}</Option>
      );
    }
    return units;
  }

    const propertyItems = propertiesList.map((property) =>
        <Option value={property.id}>{property.address}</Option>
      );

    const handleSubmit = (values) => {
        console.log(values)
        for(var key in values) {
          if(values[key] === "") {
             delete values[key]
          }
      }
        if(props.editTask) {
          values['created_on'] = taskDetail.created_on
          values['address'] = taskDetail.address
          workflowApiClient.maintenance.updateDetail(values, taskDetail.id)
          .then(res => {
              message.success('Task updated')
              props.fetchTasksList()
              onClose()
          })
          .catch(error => {
              console.log(error)
          })
        } else {
          values['address'] = propertiesList.find(item => item.id === values['property_id']).address
          workflowApiClient.maintenance.createDetail(values)
          .then(res => {
              message.success('Task created')
              props.fetchTasksList()
              onClose()
          })
          .catch(error => {
              console.log(error)
          })
        }
    }

    const handlePropertySelection = (propertyId) => {
      setSelectedProperty(propertyId)
      setLoadingUnitsState(true)
        coreApiClient.properties.fetchUnits(propertyId)
            .then(res => {
                console.log(res.data)
                setPropertyUnits(res.data.data);
                setLoadingUnitsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingUnitsState(false)
            })
    }

    return (
      <div style={{width:'100%'}}>
        <Button icon={<PlusOutlined />} size='large' type="primary" style={{ background:'#43c463', border:'none', float:'right'}} onClick={showDrawer}>
          Task
        </Button>
        <Drawer
          title={<Row style={{background:'#ff7a59', padding:'18px'}}>
          <h3 style={{margin:'0px', color:'white'}}>Add Task</h3>
          </Row>}
          width={720}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
            <div>
                <Form 
                layout='vertical' 
                onFinish={handleSubmit}
                form={form}
                style={{width:'100%'}}
                >  
                  <Row>
                    <Col span={12}
                    style={{paddingRight:'20px'}}
                    >
                    <FormItem
                    label="Property"
                    name='property_id' 
                    rules={[{ required: true, message: 'Select a property' }]}
                    >
                      <Select
                      placeholder="Select a property to assign the task"
                      loading={loadingPropertiesList}
                      notFoundContent={loadingPropertiesList ? <div style={{textAlign:'center'}}><Spin size="small" /></div>: <Empty description={'No properties found'} imageStyle={{ height: 60 }} image={<HomeOutlined style={{ fontSize: 60, color:'#dce0e6' }} />}/>}
                      onSelect={value => handlePropertySelection(value)}
                      >   
                          {propertyItems}
                      </Select>
                    </FormItem>
                    </Col>
                    <Col span={12}
                    >
                      <FormItem
                      label="Unit (optional)"
                      name='unit_id' 
                      >
                        <Select
                        placeholder="Select a unit to assign the task"
                        loading={loadingUnits}
                        disabled={selectedProperty && propertyUnits.length > 0 ? false : true} 
                        >   
                          {unitsList(propertyUnits)}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                      <Col span={12}
                      style={{paddingRight:'20px'}}
                      >
                          <FormItem
                        label="Category"
                        name='maintenance_category' 
                        rules={[{ required: true, message: 'Provide category' }]}
                        >
                          <Select
                          placeholder="Select a category"
                          >   
                              <Option value='Appliances'>Appliances</Option>
                              <Option value='Plumbing'>Plumbing</Option>
                              <Option value='Landscaping'>Landscaping</Option>
                              <Option value='Outdoor'>Outdoor</Option>
                              <Option value='Electrical'>Electrical</Option>
                              <Option value='General Household'>General Household</Option>
                              <Option value='Other'>Other</Option>
                          </Select>
                        </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            label="Task Status"
                            name='task_status' 
                            rules={[{ required: true, message: 'Provide status' }]}
                            >
                                <Select
                                placeholder="Select the status"
                                >   
                                    <Option value='New'>New Issue</Option>
                                    <Option value='In-progress'>In-progress</Option>
                                    <Option value='Completed'>Completed</Option>
                                </Select>
                            </FormItem>
                        </Col>
                      </Row>
                        <Row>
                          <Col span={24}
                          >
                            <FormItem
                            label="Issue"
                            hasFeedback
                            name='issue' 
                            rules={[{ required: true, message: 'Provide issue' }]}
                            >
                              <Input />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12} style={{paddingRight:'20px'}}>
                          <FormItem
                            label="Task Priority"
                            name='task_priority'
                            >
                              <Select
                              placeholder="Select a priority"
                              >   
                                  <Option value='Low'>Low</Option>
                                  <Option value='Moderate'>Moderate</Option>
                                  <Option value='High'>High</Option>
                                  <Option value='Critical'>Critical</Option>
                              </Select>
                            </FormItem>
                            </Col>
                            <Col span={12}>
                            <FormItem
                            label="Due Date"
                            name='due_date'
                            >
                                <DatePicker />
                            </FormItem>
                          </Col>
                        </Row>
                        <FormItem
                        label="Issue Description"
                        style={{marginBottom:'0px'}}
                        name='issue_description'
                        >
                             <TextArea 
                             placeholder="Enter a task description (optional)" 
                             autosize={{ rows: 5, maxRows: 10 }} 
                             />
                        </FormItem>
                </Form>
            </div>
            <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
              <Button 
              onClick={onClose} 
              style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button 
              onClick={() => form.submit()} 
              type="primary"
              style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
              >
                Submit
              </Button>
            </div>
          </Drawer>
      </div>
    );
}

export default CreateMaintenanceDrawer;