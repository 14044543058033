import React from 'react'
import { Tabs, Col } from 'antd';
import OverviewTab from './OverviewTab';
import UnitsTabs from './UnitsTabs';
import ResidentsList from '../../unitDetail/components/ResidentsList';

const { TabPane } = Tabs;

export default function DetailTabs(props) {
    const propertyId = props.propertyId;
    const propertyDetail = props.propertyDetail;
    const propertyUnits = props.propertyUnits;
    const loadingUnits =props.loadingUnits;

    return (
        <div style={{marginTop:'15px'}}>
            <Tabs defaultActiveKey="1" size='default'>
                <TabPane tab="Overview" key="1"  style={{outline: 'none'}}>
                    <OverviewTab propertyDetail={propertyDetail} propertyUnits={propertyUnits} />
                </TabPane>
                {propertyDetail.asset_type === 'Single-Family' ? 
                <TabPane tab="Residents" key="2" style={{outline: 'none'}}>
                    <Col lg={24}>
                        <ResidentsList 
                        {...props} 
                        residentsList={propertyUnits.length > 0 ? propertyUnits[0].residents : []} 
                        loadingResidents={props.loadingUnits} 
                        unitDetail={propertyUnits[0]}
                        handleResidentEdit={props.fetchPropertyUnits}
                        />
                    </Col>
                </TabPane>
                :
                <TabPane tab="Units" key="2" style={{outline: 'none'}}>
                    <UnitsTabs
                    propertyId={propertyId}
                    propertyUnits={propertyUnits} 
                    loadingUnits={loadingUnits} 
                    {...props}
                    />
                </TabPane>}
                <TabPane tab="Payments" key="3" style={{outline: 'none'}}>
                Content of Tab Pane 3
                </TabPane>
                {/* <TabPane tab="Listings" key="4">
                Content of Tab Pane 3
                </TabPane> */}
            </Tabs>
        </div>
    )
}
