import React, {Component} from 'react'

import { Input, Button, Card, Col, Row, DatePicker, message } from 'antd';

class CustomFormCard extends Component {
  state = {
    title: undefined,
  }

  updateField = (field, value) => {
    this.setState({[field]: value})
  }

  handleAdd = () => {
    if (this.state.title) {
      this.props.onAdd(this.state)
    } else {
      message.error('Must specify a title')
    }
  }

  render() {
    const {onCancel} = this.props
    return (
      <div style={{background: '#E3E3E3', borderRadius:'4px'}}>
        <Card
        bodyStyle={{padding:'10px'}}
        style={{width:'350px', borderRadius:'4px', marginTop:'12px'}}
        bordered={false}
        >
            <div>
              <Input required validationErrors={{
                  isDefaultRequiredValue: 'Field is required'
              }} size='default' placeholder="Title" onChange={e => this.updateField('title', e.target.value)} />
            </div>
            <div>
              <Input.TextArea style={{marginTop:'8px'}} rows={2} size='default' placeholder="Description" onChange={e => this.updateField('description', e.target.value)} />
            </div>
            <div>
              <DatePicker style={{marginTop:'8px', width:'100%'}} size='default' placeholder="Due Date" onChange={(date, dateString) => this.updateField('due_date', dateString)} />
            </div>
            <div style={{paddingTop:'5px', paddingBottom:'8px', background:'#fff', textAlign:'right'}}>
              <Button style={{marginTop:'8px', marginRight:'8px'}} onClick={onCancel} size='small'>Cancel</Button>
              <Button style={{marginTop:'8px'}} type='primary' onClick={this.handleAdd} size='small'>Add</Button>
            </div>
        </Card>
      </div>
    )
  }
}


export default CustomFormCard;