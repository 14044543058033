import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined, MailOutlined, } from '@ant-design/icons';
import { Form, Input, Button, Spin, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/auth';
import {Card} from 'antd';
import asset26 from '../../assets/Asset 26.svg';
import asset5 from '../../assets/Asset 5.svg';
import SEO from '../../components/SEO';
// import FacebookLogin from 'react-facebook-login';

const FormItem = Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoginForm = (props) => {
  const [social, setSocial] = useState(false)

  useEffect(() => {
    return () => {
      console.log('will unmount');
    }
  }, []);

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    props.resetPassword(values.email)
  }

  if (props.resetConfirm !== null) {
    return <Redirect to='/account/password/reset/confirm/' />;
  }
    
    return (
        <Col sm={20} md={12} lg={8}  style={{marginTop:'100px', textAlign:'center'}}>
          <SEO title={'Password Reset'} />
          <div style={{marginBottom:'15px'}}>
              <a href='https://realtystack.io' style={{margin:'0 12px'}}>
                  <img className="logo" alt="" src={asset5}
                  style={{ height:'48px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
                  />
                  <img className="logo" alt="RealtyStack" src={asset26}
                  style={{ marginLeft:'8px', paddingTop:'4px', height:'48px', marginTop:'15px', transition: 'all .2s'}} 
                  />
              </a>
          </div>
          <Card>
            {
                props.loading ?

                <Spin indicator={antIcon} />

                :
            
                <Form onFinish={handleSubmit} className="login-form" form={form}>
                    <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Request Password Reset</h1>
                    <p>Please provide your account email to recieve a temporary password</p>
                    <FormItem
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                    >
                      <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    </FormItem>
                    <FormItem style={{marginBottom:'0px', marginTop:'8px'}}>
                        <Button size='large' block loading={props.loading} style={{background:'#43c463', border:'none', marginTop:'5px'}} type="primary" htmlType="submit" className="login-form-button">
                            Request Password Reset
                        </Button>
                    </FormItem>
                </Form>
            }
          </Card>
          <Row style={{padding:'20px'}} justify='center'>
            <NavLink style={{marginLeft: '10px', color:'white', borderBottom:'1px solid white'}} to='/login/'>Login</NavLink>
          </Row>
          </Col>
    );
}

const mapStateToProps = (state) => {
    return{
        loading: state.user.authLoading,
        error: state.user.error,
        isAuthenticated : state.user.isAuthenticated,
        authenticatedUser : state.user.authenticatedUser,
        resetConfirm: state.user.resetConfirm,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (email) => dispatch(actions.authPasswordReset(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);