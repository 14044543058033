import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Tabs, Spin, Select, Divider, Checkbox, Form } from 'antd';
import paymentsApiClient from '../../../../store/apiService';
import BankPaymentCreate from '../../../accounts/settings/components/BankPaymentCreate';
// import {CardElement, injectStripe} from 'react-stripe-elements';
// import PlaidLink from 'react-plaid-link'

const TabPane = Tabs.TabPane;
const Option = Select.Option;

function paymentSelection(externalAccounts){
  const paymentSource = [];
  for (let i = 0; i < externalAccounts.length; i++) {
    paymentSource.push(
      <Option value={externalAccounts[i].id}>{externalAccounts[i].object === 'card' ? externalAccounts[i].brand : externalAccounts[i].bank_name} - &bull;&bull;&bull;&bull; {externalAccounts[i].last4}</Option>
    );
  }
  return paymentSource;
}

const LeaseCreateStepThree = (props) => {
    const [externalAccounts, setExternalAccounts] = useState([])
    const [externalAccountsLoading, setExternalAccountsLoading] = useState(false)
    const [addNewAccount, setAddNewAccount] = useState(false)

    useEffect(() => {
      setExternalAccountsLoading(true)
      paymentsApiClient.account.fetchDetail(props.authenticatedUser['custom:stripe_account_id'])
      .then(res => {
          const stripeAccount = res.data;
          const externalAccounts = res.data.external_accounts.data;
          console.log(stripeAccount);
          setExternalAccounts(externalAccounts)
          setExternalAccountsLoading(false)
      })
      .catch(error => {
          // handle error
          console.log(error);
          setExternalAccountsLoading(false)
      });
    }, [])

  const [form] = Form.useForm()

    return (
      <div className="checkout">
        <Card
        bodyStyle={{padding:'10px 0px 0px 0px'}}
        > 
        {externalAccountsLoading ? <div style={{ textAlign:'center'}}><LoadingOutlined style={{ fontSize: 45, padding:'100px', }} spin /></div> :
          <div style={{padding:'30px'}}>
          <h3>Select a current payment method or add a new checking account or debit card</h3>
          <p>Which account would you like to recieve the funds?</p>  
          <Form 
          form={form}
          name="global_state"
          fields={props.fields}
          onFieldsChange={(changedFields, allFields) => {
            props.onChange(allFields);
          }}
          >
            <Form.Item
            name='destination_account'
            >
              <Select style={{width:'300px', marginBottom:'15px'}}>
                {paymentSelection(externalAccounts ? externalAccounts : [])}
              </Select>
            </Form.Item>
            <p>Or</p>
            { addNewAccount ? 
              <BankPaymentCreate {...props} />
              :
              <p><Button icon={<PlusOutlined />} onClick={() => setAddNewAccount(true)} type='link'>New Account</Button></p>
            }
            <Divider />
            <h3>Security Deposit</h3>
            <p>Would you like to use the same account for the security deposit?</p> 
            <Form.Item
            name='destination_account_deposit'
            >
              <Checkbox value='yes'></Checkbox>
            </Form.Item> 
          </Form>
          </div>
        }
        </Card>
      </div>
    );
}

const mapStateToProps = state => {
  return {
      authenticatedUser : state.user.authenticatedUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseCreateStepThree);