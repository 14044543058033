// Auth Actions
export const AUTH_START = 'AUTH_START';
export const AUTH_REGISTERED = 'AUTH_REGISTERED';
export const AUTH_CONFIRM = 'AUTH_CONFIRM';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_RESET_SUCCESS = 'AUTH_PASSWORD_RESET_SUCCESS';
export const AUTH_PASSWORD_CHANGE_SUCCESS = 'AUTH_PASSWORD_CHANGE_SUCCESS';
// User Profile Actions
// UPDATE User Profile Actions
export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const UPDATE_NOTIFICATION_PREFERENCES_START = 'UPDATE_NOTIFICATION_PREFERENCES_START';
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_NOTIFICATION_PREFERENCES_FAIL = 'UPDATE_NOTIFICATION_PREFERENCES_FAIL';
// GET User Profile Actions
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
// GET User Profile Actions
export const GET_USER_ACTIVITY_START = 'GET_USER_ACTIVITY_START';
export const GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS';
export const GET_USER_ACTIVITY_FAIL = 'GET_USER_ACTIVITY_FAIL';

// GET User Profile Actions
export const GET_STRIPE_TRANSFERS_START = 'GET_STRIPE_TRANSFERS_START';
export const GET_STRIPE_TRANSFERS_SUCCESS = 'GET_STRIPE_TRANSFERS_SUCCESS';
export const GET_STRIPE_TRANSFERS_FAIL = 'GET_STRIPE_TRANSFERS_FAIL';

// GET User Profile Actions
export const GET_PROPERTY_ACTIVITY_START = 'GET_PROPERTY_ACTIVITY_START';
export const GET_PROPERTY_ACTIVITY_SUCCESS = 'GET_PROPERTY_ACTIVITY_SUCCESS';
export const GET_PROPERTY_ACTIVITY_FAIL = 'GET_PROPERTY_ACTIVITY_FAIL';