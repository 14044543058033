import React, { useState, useEffect } from 'react'

import { Card, Divider, Table, Tag, List, Row, Col, Statistic, Menu, Dropdown, Form, PageHeader, Avatar } from 'antd';
import moment from 'moment';
import coreApiClient from '../../../store/apiService';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ordinalSuffixOf } from '../../../store/utility';

const FormItem = Form.Item;


const invoiceColumns = [{
    title: 'Amount',
    dataIndex: 'amount_due',
    render: amount => amount,
    align: 'right'
  }, {
    title: 'Currency',
    dataIndex: 'currency',
    render: currency => String(currency).toUpperCase(),
  }, {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color='geekblue'>{status}</Tag>,
  }, {
    title: 'Description',
    dataIndex: 'description',
  }, {
    title: 'Customer',
    dataIndex: 'customer_email',
  }, {
    title: 'Period',
    dataIndex: 'period_start',
    render: (text, record) => <div>{moment.unix(record.period_start).format('MMM Do, YYYY')} - {moment.unix(record.period_end).format('MMM Do, YYYY')}</div>,
  }, {
    title: 'Created Date',
    dataIndex: 'created_on',
    render: text => {moment(text).format('MMM Do, YYYY')},
  }, {
    title: 'Billing Reason',
    dataIndex: 'billing_reason',
    render: billing_reason => String(billing_reason).charAt(0).toUpperCase() + String(billing_reason).slice(1).replace('_', ' '),
  }];
  

const columns = [{
    title: 'Amount',
    dataIndex: 'amount_due',
    render: amount => amount,
    align: 'right'
  }, {
    title: 'Currency',
    dataIndex: 'currency',
    render: currency => String(currency).toUpperCase(),
  }, {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color='geekblue'>{status}</Tag>,
  }, {
    title: 'Description',
    dataIndex: 'Description',
  }, {
    title: 'Customer',
    dataIndex: 'customer_email',
  }, {
    title: 'Period',
    dataIndex: 'period_start',
    render: (text, record) => <div>{moment.unix(record.period_start).format('MMM Do, YYYY')} - {moment.unix(record.period_end).format('MMM Do, YYYY')}</div>,
  }, {
    title: 'Created Date',
    dataIndex: 'created',
    render: text => {moment(text).format('MMM Do, YYYY')},
  }, {
    title: 'Billing Reason',
    dataIndex: 'billing_reason',
    render: billing_reason => String(billing_reason).charAt(0).toUpperCase() + String(billing_reason).slice(1).replace('_', ' '),
  }];
  

const LeaseDetail = (props) => {
    const [leaseDetail, setLeaseDetail] = useState(undefined);
    const [subscription, setSubscription] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [latestInvoice, setLatestInvoice] = useState({});
    const [destinationAccount, setDestinationAccount] = useState({});
    const [subscriptions, setSubscriptions] = useState([]);
    const [loadingLeaseDetail, setLoadingLeaseDetail] = useState(false);

    useEffect(() => {
        const leaseId = props.match.params.leaseId;
        setLoadingLeaseDetail(true)
        coreApiClient.leases.fetchDetail(leaseId)
        .then(res => {
            console.log(res.data)
            let lease = res.data;
            setLoadingLeaseDetail(false)
            setLeaseDetail(lease)
            
            let destinationAccount = lease.destination_account
            setDestinationAccount(destinationAccount)
            
            let subscriptions = lease.subscriptions
            setSubscriptions(subscriptions)

            let invoices = lease.invoices //.lines.data
            setInvoices(invoices)

            let latestInvoice = subscriptions[0].latest_invoice //.lines.data
            setLatestInvoice(latestInvoice)

            subscriptions.forEach(subscription => {
                coreApiClient.invoice.fetchUpcoming(subscription.customer)
                .then(res => {
                    console.log(res.data)
                    let invoice = res.data;
                })
                .catch(error => {
                    console.log(error)
                })
            })
        })
        .catch(error => {
            console.log(error)
            setLoadingLeaseDetail(false)
        })
    }, []);


        const paginationProps = {
          size: 'middle',
          pageSize: 10,
          hideOnSinglePage: true,
          style: {marginRight:'30px'},
        }

        const menu = (
          <Menu>
            {/* <Menu.Item key="1"><Icon type="delete" />Delete</Menu.Item>
            <Menu.Item key="2"><Icon type="export" />Export</Menu.Item> */}
          </Menu>
        );

      //   const handlePropertyEditing = () => {
      //     const property = this.props.propertyDetail;
      //     this.setState({propertyEditing: !this.state.propertyEditing})
      //     this.props.form.setFieldsValue({
      //         asset_type: property.asset_type,
      //         property_status: property.property_status,
      //         photo: property.photo,
      //         property_condition: property.property_condition,
      //     });
      // }

    return (
    <div>
        {leaseDetail ?
        <div>
        <PageHeader
        onBack={() => props.history.goBack()}
        title="Leases"
        style={{padding:'0px', paddingLeft:'10px'}}
        />
        <Card
        style={{ margin:'10px'}}
        bodyStyle={{padding:'0px'}}
        >
            <Row style={{padding:'20px', width:'100%'}}>
                <Col md={18} lg={20}>
                <h2>{moment(leaseDetail.start_date).format('MMM Do, YYYY')}<span> - </span>{leaseDetail.end_date ? moment(leaseDetail.end_date).format('MMM Do, YYYY') : 'Month-to-Month'}</h2>
                    <span>{leaseDetail.property ? leaseDetail.property.address : '-'}, </span>
                    <span style={{marginRight:'20px'}}>{leaseDetail.unit ? leaseDetail.unit.unit_name : '-'}</span>
                    {/* <Tag color='volcano'>{subscription.status}</Tag> */}                 
                </Col>
                <Col md={6} lg={4}>
                    <Dropdown.Button style={{float:'right'}} overlay={menu}>Edit</Dropdown.Button> 
                </Col>
            </Row>
            <Row type='flex' style={{background:'#f7fafc', padding:'20px', borderTop:'1px solid #e8e8e8'}}>
                <Col>
                <Statistic title={<h3>Current Balance Due</h3>} prefix='$' value={latestInvoice.amount_remaining}/>
                </Col>
                <Col>
                <Divider style={{height:'100%', margin:'0px 15px'}} type='vertical' />
                </Col>
                <Col>
                <Statistic valueStyle={{ color: '#3f8600', fontSize:'14px' }} title={<h3>Next Invoice</h3>} prefix='$' value={leaseDetail.plan ? leaseDetail.plan.amount : '-'}/>
                </Col>
                <Col>
                <Divider style={{height:'100%', margin:'0px 15px'}} type='vertical' />
                </Col>
                <Col>
                <Statistic valueStyle={{ fontSize:'14px' }} title={<h3>Reocurring Rent</h3>} prefix='$' value={leaseDetail.plan ? leaseDetail.plan.amount : '-'}/>
                </Col>
            </Row>
        </Card>
        <Row>
        <Col md={24} lg={12} xl={16} style={{padding:'10px'}}>
        <Card
        title='Lease Details'
        >
            <Form layout='horizontal'>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Amount"
                    >
                    <span className="ant-form-text">${leaseDetail.amount} / month</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Property"
                    >
                    <span className="ant-form-text">{leaseDetail.property.address}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Unit"
                    >
                    <span className="ant-form-text">{leaseDetail.unit.unit_name}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Lease Type"
                    >
                    <span className="ant-form-text">{leaseDetail.lease_type ? leaseDetail.lease_type.charAt(0).toUpperCase() + leaseDetail.lease_type.slice(1) : '-'}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Monthly Due Date"
                    >
                    <span className="ant-form-text">{ordinalSuffixOf(leaseDetail.monthly_due_date)}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormItem
                    label="Created"
                    >
                    <span className="ant-form-text">{moment(leaseDetail.created_on).format('MMM Do, YYYY hh:mm a')}</span>
                    </FormItem>
                </Col>
                <Col md={12}>
                    <FormItem
                    label="Updated"
                    >
                    <span className="ant-form-text">{moment(leaseDetail.updated_on).format('MMM Do, YYYY hh:mm a')}</span>
                    </FormItem>
                </Col>
            </Row>
            </Form>
        </Card>
        </Col>
        <Col md={24} lg={12} xl={8} style={{padding:'10px'}}>
            <Card
            title='Residents'
            >
                <List
                itemLayout="horizontal"
                dataSource={leaseDetail.residents}
                renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar>{item.first_name.charAt(0)}{item.last_name.charAt(0)}</Avatar>}
                    title={<span>{item.first_name} {item.last_name}</span>}
                    description={item.email}
                    />
                </List.Item>
                )}
                />
            </Card>
        </Col>
        </Row>
        <Card
        style={{margin:'10px'}}
        title='Payment Details'
        >
            <Form layout='horizontal'>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Billing Method"
                    >
                    <span className="ant-form-text">{String(subscription.billing).charAt(0).toUpperCase() + String(subscription.billing).slice(1).replace('_', ' ')}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Current Period"
                    >
                        <span className="ant-form-text">{subscription.current_period_start} - {subscription.current_period_end}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormItem
                    label="Destination Institution"
                    >
                    <span className="ant-form-text">{destinationAccount.bank_name}</span>
                    </FormItem>
                </Col>
                <Col md={12}>
                    <FormItem
                    label="Destination Account"
                    >
                    <span className="ant-form-text"><div><span>Last 4 digits: &bull;&bull;&bull;&bull;</span><span>{destinationAccount.last4}</span></div></span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Default Payment Method"
                    >
                    <span className="ant-form-text">{subscription.default_payment_method}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Days Until Due"
                    >
                    <span className="ant-form-text">{subscription.days_until_due}</span>
                    </FormItem>
                </Col>
            </Row>
            </Form>
            </Card>
            <Card
            title='Current Period'
            style={{margin:'10px'}}
            bodyStyle={{padding:'0px'}}
            >
                <Table 
                columns={columns} 
                dataSource={[latestInvoice]}
                pagination={paginationProps}
                />
            </Card>
            <Card
            title='Upcoming'
            style={{margin:'10px'}}
            bodyStyle={{padding:'0px'}}
            >
                <Table 
                columns={invoiceColumns} 
                dataSource={invoices}
                pagination={paginationProps}
                />
            </Card>
            <Card
            title='Transactions'
            style={{margin:'10px'}}
            bodyStyle={{padding:'0px'}}
            >
                <Table 
                columns={columns} 
                // dataSource={invoiceList}
                pagination={paginationProps}
                />
            </Card>
        </div>
        :  <div style={{textAlign:'center'}}><LoadingOutlined style={{ fontSize: 45, color:'#1890ff' }} spin /></div>}
    </div>
    )
}
  
export default LeaseDetail;