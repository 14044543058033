import React from 'react'
import { Col, Row } from 'antd';
import PaymentsTable from './PaymentsTable';
import PropertyDetails from './PropertyDetails';
import PhotoUpload from './PhotoUpload';

export default function OverviewTab(props) {

    const propertyDetail = props.propertyDetail;

    return (
        <Row>
            <Col md={24} lg={18}>
                <PaymentsTable />
                <PropertyDetails propertyDetail={propertyDetail} propertyUnits={props.propertyUnits} />
            </Col>
            <Col md={24} lg={6} style={{paddingLeft:'30px'}}>
                <div>
                    <h2 style={{fontWeight:400, color: '#3c484f'}}>PHOTOS</h2>
                </div>
                <PhotoUpload propertyDetail={propertyDetail} />
                <div style={{marginTop:'20px'}}>
                    <h2 style={{fontWeight:400, color: '#3c484f'}}>MAP</h2>
                </div>
                <iframe
                title={`${propertyDetail.address}`}
                width="100%" 
                height="300" 
                frameborder="0" 
                style={{border:0, marginTop:'0px'}}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCiqzlF6snAzw0Z_d5pi-IPdDbs3ry3BhI&q=${propertyDetail.address},${propertyDetail.city},${propertyDetail.state}`}
                allowfullscreen
                >
                </iframe>
            </Col>
        </Row>
    )
}
