import React from 'react'
import { Link } from 'react-router-dom';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Row, Col, Card, Statistic, Button } from 'antd';

class SubscriptionView extends React.Component {
  render() {
    return (
        <div>
          <h2 style={{marginTop:'30px', textAlign:'center'}}>Realtystack offers a 30 day trial of our Pro membership to tryout all of the added benefits</h2>
          <Row type="flex" justify='center' gutter={18} style={{marginTop:'25px'}}>
            <Col style={{textAlign:'center', marginTop:'30px'}}>
                <Card hoverable >
                    <h2 style={{marginTop:'20px', fontSize:'24px', fontWeight:'500'}}>Free</h2>
                    <Statistic style={{fontSize:'22px', marginBottom:'15px'}} value={'$0'} suffix='/ mo.'/>                
                    <strong>Features</strong>
                    <ul style={{listStyle:'none', textAlign:'left', marginTop:'15px'}}>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Online Rent Payments</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Screen Applicants</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Finances</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Create Listings</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Maintenance Requests</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Reports</li>
                        <li><CloseOutlined style={{marginRight:'15px', color:'#f5222d'}} />Manage Team</li>
                    </ul>
                    <Button size='large' style={{marginTop:'15px'}} block disabled>Current Plan</Button>
                </Card>
            </Col>
            <Col style={{textAlign:'center'}}>
                <Card style={{padding:'30px 10px'}} hoverable>
                    <h2 style={{marginTop:'20px', fontSize:'24px', fontWeight:'500'}}>Pro</h2>
                    <Statistic style={{fontSize:'22px', marginBottom:'15px'}} value={'$5'} suffix='/ mo.'/>
                    <strong>Features</strong>
                    <ul style={{listStyle:'none', textAlign:'left', marginTop:'15px'}}>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Online Rent Payments</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Screen Applicants</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Finances</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Create Listings</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Maintenance Requests</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Reports</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Team</li>
                    </ul>
                    <Link to='/account/settings/subscription/create/' >
                        <Button size='large' block style={{background:'#43c463', border:'none', marginTop:'15px'}} type='primary'>Upgrade</Button>
                    </Link>
                </Card>
            </Col>
            <Col style={{textAlign:'center', marginTop:'30px'}}>
                <Card hoverable>
                    <h2 style={{marginTop:'20px', fontSize:'24px', fontWeight:'500'}}>Enterprise</h2>
                    <Statistic style={{fontSize:'22px', marginBottom:'15px'}} value={'Custom Pricing'} suffix=''/>
                    <strong>Features</strong>
                    <ul style={{listStyle:'none', textAlign:'left', marginTop:'15px'}}>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Online Rent Payments</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Screen Applicants</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Finances</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Create Listings</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Maintenance Requests</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Reports</li>
                        <li><CheckOutlined style={{marginRight:'15px', color:'#43c463'}} />Manage Team</li>
                    </ul>
                    <Link to='/account/settings/subscription/create/' >
                        <Button size='large' block style={{background:'#43c463', border:'none', marginTop:'15px'}} type='primary'>Contact</Button>
                    </Link>
                </Card>
            </Col>
          </Row>
          </div>
    );
  }
}

export default SubscriptionView;