import React from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Row, Col, Rate, Tag } from 'antd';

const FormItem = Form.Item;

export default function UnitDetails(props) {
    const unitDetail = props.unitDetail;

    return (
        <div>
            <div>
                <h2 style={{fontWeight:400, color: '#3c484f'}}>Unit Details</h2>
            </div>
            <Card
            bordered={false}
            >
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Property Manager"
                        >
                            <span className="ant-form-text">{unitDetail.property_manager ? unitDetail.property_manager : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Rent"
                        style={{paddingRight:'30px'}}
                        >
                            <span className="ant-form-text">$ {unitDetail.rent}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Unit Type"
                        style={{paddingRight:'30px'}}
                        >
                            <span className="ant-form-text">{unitDetail.unit_type ? unitDetail.unit_type : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Sq Footage"
                        >
                            <span className="ant-form-text">{unitDetail.sq_footage ? unitDetail.sq_footage : '-'}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Bath Count"
                        >
                            <span className="ant-form-text">{unitDetail.bath_count}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Bed Count"
                        >
                            <span className="ant-form-text">{unitDetail.bed_count}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="MLS#"
                        >
                            <span className="ant-form-text">{unitDetail.mls_number ? unitDetail.mls_number : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Parking Count"
                        >
                            <span className="ant-form-text">{unitDetail.parking_count ? unitDetail.parking_count : '-'}</span>
                        </FormItem>
                    </Col>
                </Row>
                <FormItem
                label="Description"
                >
                    <span className="ant-form-text">{unitDetail.unit_description ? unitDetail.unit_description : '-'}</span>
                </FormItem>
            </Card>
            <div>
                <h3 style={{fontWeight:400, color: '#3c484f', marginTop:'12px', marginBottom:'10px'}}>Features / Ammenities</h3>
            </div>
            <Card
            bordered={false}
            >
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Laundry"
                        >
                            <span className="ant-form-text">{unitDetail.laundry ? unitDetail.laundry : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Storage"
                        style={{paddingRight:'30px'}}
                        >
                            <span className="ant-form-text">{unitDetail.storage ? unitDetail.storage : '-'}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Utilities Included"
                        >
                            <span className="ant-form-text">{unitDetail.utilities_included ? 'Yes' : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Furnished"
                        >
                            <span className="ant-form-text">{unitDetail.furnished ? 'Yes' : '-'}</span>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                <FormItem>
                    {unitDetail.dishwasher ? <Tag color="green">dishwasher</Tag> : <Tag color="red">dishwasher</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.carpet ? <Tag color="green">carpet</Tag> : <Tag color="red">carpet</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.hardwood_floors ? <Tag color="green">hardwood floors</Tag> : <Tag color="red">hardwood floors</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.handicap_accessable ? <Tag color="green">handicap accessible</Tag> : <Tag color="red">handicap accessible</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.balcony ? <Tag color="green">balcony</Tag> : <Tag color="red">balcony</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.central_heating ? <Tag color="green">central heating</Tag> : <Tag color="red">central heating</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.microwave ? <Tag color="green">microwave</Tag> : <Tag color="red">microwave</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.cable_ready ? <Tag color="green">cable ready</Tag> : <Tag color="red">cable ready</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.fireplace ? <Tag color="green">fireplace</Tag> : <Tag color="red">fireplace</Tag>}
                </FormItem>
                <FormItem>
                    {unitDetail.stainless_steel_applicances ? <Tag color="green">stainless steel applicances</Tag> : <Tag color="red">stainless steel applicances</Tag>}
                </FormItem>
                </Row>
                <Row>
                    <Col span={12}>
                        <FormItem
                        label="Pets Policy"
                        style={{paddingRight:'30px'}}
                        >
                            <span className="ant-form-text">{unitDetail.pets_policy ? unitDetail.pets_policy : '-'}</span>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                        label="Smoking Policy"
                        style={{paddingRight:'30px'}}
                        >
                            <span className="ant-form-text">{unitDetail.smoking_policy ? unitDetail.smoking_policy : '-'}</span>
                        </FormItem>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
