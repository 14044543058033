import React from 'react';
import { Card, Row, Col, Form } from 'antd';
import moment from 'moment';

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

const LeaseCreateReview = (props) => {
    const fields = props.fields;
    return (
      <Card>
        <Form hideRequiredMark>
        <h3>Resident Info</h3>
        <Row>
            <Col lg={12}>
                <Form.Item
                label='Property'
                >
                    <span>{fields.property ? props.propertiesList.find(item => item.id === fields.property).address : 'None'}</span>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item  
                label='Unit'
                >
                    <span>
                        {fields.unit ? props.propertyUnits.find(item => item.id === fields.unit).unit_name  : 'None'}
                    </span>                  
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='Residents'
                >
                    <span>
                        {fields.residents ? JSON.parse(JSON.stringify(fields.residents)).map(resident_id => {
                        let resident = props.residentsList.find(resident => resident.id === resident_id)
                        return resident ? <p>{resident.first_name} {resident.last_name}</p> : ''
                        }) : 'None'}
                    </span>                    
                </Form.Item>
            </Col>
        </Row>
        <h3>Lease Details</h3>
        <Row>
            <Col lg={12}>
                <Form.Item
                label='Rent'
                >
                    <strong>${fields.amount ? fields.amount : 'None'}</strong>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='Lease Type'
                >
                    <span>{fields.lease_type ? fields.lease_type : 'None'}</span>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='Start Date'
                >
                    <strong>{fields.start_date ? moment(fields.start_date).format('MMMM Do YYYY') : 'None'}</strong>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='End Date'
                >
                    <strong>{fields.end_date ? moment(fields.end_date).format('MMMM Do YYYY') : 'None'}</strong>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='Monthly Due Date'
                >
                    <span>{fields.monthly_due_date ? ordinal_suffix_of(fields.monthly_due_date) : 'None'}</span>                   
                </Form.Item>
            </Col>
        </Row>
        <h3>Account Info</h3>
        <Row>
            <Col lg={12}>
                <Form.Item
                label='Institution'
                >
                    <p>{fields.bank_institution ? fields.bank_institution : 'None'}</p>                    
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item
                label='Account Name'
                >
                    <p>{fields.bank_account_name ? fields.bank_account_name : 'None'}</p>                    
                </Form.Item>
            </Col>
        </Row>
        </Form>
      </Card>
    );
}

export default LeaseCreateReview;