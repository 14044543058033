import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Result, Button } from 'antd';

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest} render={props =>
        localStorage.getItem('idToken') ? (
            <Layout>
                <Component {...props} />
            </Layout>
        ) : 
        localStorage.getItem('idToken') ? (
              <Result 
              status='403' 
              title='403' 
              subTitle='Sorry you do not have access to this page'
              extra={
              <div>
                <Button type="primary"><Link to='/'>Return to home</Link></Button>
                <Button onClick={() => props.history.goBack()}>Go back</Button>                
              </div>}
              />
        ) : (
          <Redirect to={{ pathname: '/login/', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute