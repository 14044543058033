import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';
import PageHeader from '../../../components/PageHeader';
import { EnvironmentOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Card, Divider, Avatar, Button, Tag, List, Skeleton, Empty } from 'antd';
import coverPhoto from '../../../assets/orange-banner.png';
import coreApiClient from '../../../store/apiService';
import moment from 'moment';

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

const Profile = (props) => {
    const [activities, setActivities] = useState([])
    const [loadingActivies, setLoadingActivities] = useState(false)

    useEffect(() => {
        setLoadingActivities(true)
        coreApiClient.activity.fetchList()
            .then(res => {
                console.log(res.data)
                let activitiesList = res.data.data;
                activitiesList.sort((a,b) => moment(b.timestamp) - moment(a.timestamp));
                setActivities(activitiesList)
                setLoadingActivities(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingActivities(false)
            })
    }, [])

        const authenticatedUser = props.authenticatedUser;

        return (
            <div>
                <PageHeader title='User' extra='Profile' />
                <Row>
                <Col md={24} lg={12} style={{padding: '15px'}}>
                    <Card
                    bordered={false}
                    style={{textAlign:'center'}}
                    cover={<img alt="Cover" style={{width: '100%', height:'120px', objectFit:'cover'}} src={coverPhoto} /> }
                    >
                        <Col md={24} lg={12} style={{marginTop:'-75px'}}>
                            <div style={{marginBottom:'5px'}}>
                                <Avatar style={{ backgroundColor:'#43c463'}} size={110} icon={<UserOutlined />} src={authenticatedUser.profile_picture} />
                                <div style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: 18, marginTop:'15px' }}>
                                    <span style={{ fontWeight:400, marginRight:'6px' }}>{authenticatedUser.given_name}</span>
                                    <span style={{ fontWeight:600 }}>{authenticatedUser.family_name}</span>
                                </div>
                            </div>
                            <div>
                                <p style={{ color: 'rgba(0, 0, 0, 0.43)' }}>
                                    <span>{authenticatedUser['custom:account_type']}</span>
                                </p>
                            </div>
                            <p style={{ color: 'rgba(0, 0, 0, 0.43)' }}>
                                <EnvironmentOutlined style={{marginRight:'8px'}} />
                                <span>{authenticatedUser['custom:city']}, {authenticatedUser['custom:state']}</span>
                            </p>
                            <p style={{ color: 'rgba(0, 0, 0, 0.43)' }}>
                                <MailOutlined style={{marginRight:'8px'}} />
                                <a href={`mailto:${authenticatedUser.email}`}>{authenticatedUser.email}</a>
                            </p>
                            <p style={{ color: 'rgba(0, 0, 0, 0.43)' }}>
                                <PhoneOutlined style={{marginRight:'8px'}} />
                                <a>{formatPhoneNumber(authenticatedUser.phone_number)}</a>
                            </p>
                            <p style={{ color: 'rgba(0, 0, 0, 0.43)' }}>
                                <span>Subscription: </span>
                                <Tag color="geekblue">{authenticatedUser['custom:account_type']}</Tag>
                            </p>
                        </Col>
                    </Card>
                </Col>
                <Col md={24} lg={12} style={{padding: '15px'}}>
                    <div>
                        {/* <h2 style={{fontWeight:400, color: '#3c484f', fontSize: '28px'}}>Activity</h2> */}
                        <Card title={'Recent Activity'}>
                        {loadingActivies ? 
                            <List
                            dataSource={[1,2,3]}
                            renderItem={item => (
                                <List.Item>
                                    <Skeleton loading={loadingActivies} active paragraph={{rows:1}}/>
                                </List.Item>
                            )}
                            />
                            :
                            <List
                            dataSource={activities}
                            pagination={{
                                defaultPageSize: 4,
                                simple: true
                            }}
                            locale={{emptyText: <Empty description='No Recent Activity' />}}
                            renderItem={item => (
                                <List.Item extra={[moment.utc(item.timestamp).local().fromNow()]}>
                                        <List.Item.Meta
                                        // avatar={<Avatar icon={}/>}
                                        title={<span>{item.action.charAt(0).toUpperCase() + item.action.slice(1)}</span>}
                                        description={<span>{item.description}</span>}
                                        />
                                </List.Item>
                            )}
                            />}
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
        );
}

const mapStateToProps = state => {
    return {
      authenticatedUser: state.user.authenticatedUser,
    }
  }

const mapDispatchToProps = dispatch => {
  return {
    checkAuthToken: () => dispatch(actions.authCheckState()),
    fetchProfile: () => dispatch(actions.getUserProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);