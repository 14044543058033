import React from 'react';
import {DollarOutlined} from '@ant-design/icons'
import { Card, Table, Empty } from 'antd';

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    }
  ];

  const data = [];

export default function PaymentsTable() {
    return (
        <div>
          <h3 style={{fontWeight:400, textTransform:'uppercase', color:'#3c484f', fontSize:'19px'}}>
              Payments
          </h3>
          <Card
          bordered={false}
          style={{boxShadow:'rgba(115, 162, 208, 0.1) 0px 23px 20px -20px, rgba(115, 162, 208, 0.06) 0px 0px 15px 0px'}}
          >
            <h3 style={{fontWeight:400, textTransform:'uppercase', color:'#3c484f', fontSize:'17px'}}>
                Recent <span style={{fontWeight:600}}>Activity</span>
            </h3>
              <div>
                <Table
                columns={columns} 
                dataSource={data}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                locale={{emptyText: <Empty description='No Recent Activity' />}}
                size='small'
                style={{border: 'none', margin:'0px -25px -25px -25px'}}
                pagination={{
                    simple: true,
                    total: 5,
                    hideOnSinglePage: true,
                }}
                />
              </div>
          </Card>
        </div>
    )
}
