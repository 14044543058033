import React from 'react';
import { Button, Card, Form, Input, Col, Row, message } from 'antd';
import CardHeader from '../../../../components/CardHeader';
import {CardElement,useStripe,useElements} from '@stripe/react-stripe-js';
import paymentsApiClient from '../../../../store/apiService';

const CardPaymentCreate = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  const authenticatedUser = props.authenticatedUser;
  const handleSubmit = async (values) => {
    const {token} = await stripe.createToken(elements.getElement(CardElement), {
      "currency": "USD",
      ...values
    });
    console.log(token)
    if (authenticatedUser.account_type === 'Resident') {
      let customerId = authenticatedUser['custom:stripe_customer_id'];
      paymentsApiClient.customers.createSource({
        source: token.id,
      }, customerId)
        .then(res => {
            const stripeSource = res.data;
            console.log(stripeSource);
            message.success('Successfully added account')
            props.history.push('/account/settings/payments/')
        })
        .catch(error => {
            // handle error
            console.log(error);
        });
    } else {
      paymentsApiClient.externalAccount.createDetail({
        "public_token": null,
        "external_account_token": token.id,
        "idempotency_key": Date.now().toString()
      })
        .then(res => {
            const stripeAccount = res.data;
            console.log(stripeAccount)
            props.history.push('/account/settings/payments/')
        })
        .catch(error => {
            // handle error
            console.log(error);
        });
    }
  };

  return (
    <Col md={18} lg={20}
      style={{paddingLeft:'40px'}}
      >
        <CardHeader title='Add Card' subtitle='Payments' description='Securely add a debit card to your account for recieving funds' />
        <div className="checkout" style={{margin:'20px'}}>
          <Row  justify='center'>
            <Col sm={20} md={16} lg={12}>
              <Card>
                <p>Please fill in all debit card information</p>
                <Form form={form} onFinish={handleSubmit}>
                  <Form.Item name='name'>
                    <Input size="large" placeholder="Name on card" />
                  </Form.Item>
                  <Form.Item name='address_line1'>
                    <Input size="large" placeholder="Billing address" />
                  </Form.Item>
                  <Form.Item name='address_line2'>
                    <Input size="large" placeholder="Address line 2 (optional)" />
                  </Form.Item>
                  <Form.Item name='address_city'>
                    <Input size="large" placeholder="City" />
                  </Form.Item>
                  <Form.Item name='address_state'>
                    <Input size="large" placeholder="State" />
                  </Form.Item>
                  <Form.Item name='address_zip'>
                    <Input size="large" placeholder="Zip Code" />
                  </Form.Item>
                <div style={{
                  border: '1px solid #d9d9d9', 
                  padding:'8.5px 11px', 
                  borderRadius: '2px', 
                  background: '#e9ecef'
                  }}
                >
                <CardElement 
                className='stripe-card-element' 
                options={{
                  iconStyle: 'solid',
                  style: {
                    base: {
                      iconColor: '#002140',
                      color: 'rgba(0, 0, 0, 0.65)',
                      fontSize: '16px',
                      //'::selection': '#e9ecef !important'
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#fce883',
                      },
                      '::placeholder': {
                        color: '#c7c9cf',
                      },
                    },
                    invalid: {
                      //iconColor: '#FFC7EE',
                      //color: '#FFC7EE',
                    },
                  },
                }}
                />
                </div>
                <Button 
                size='default' 
                style={{background:'#43c463', border:'none', marginTop:'15px', float:'right'}} 
                type="primary" 
                htmlType='submit'
                disabled={!stripe}
                >
                  Submit
                </Button>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
  );
};

export default CardPaymentCreate;