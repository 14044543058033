import React, { useState, useEffect } from 'react'
import PageHeader from '../../../components/PageHeader'
import { DeleteOutlined, ContactsOutlined, ExportOutlined, LoadingOutlined } from '@ant-design/icons';
import {Row,Card,Table,Col,Input,Button,Tag,
    Empty,Divider,Popconfirm, } from 'antd';
import CreateContact from './components/CreateContact';
import moment from 'moment';
import {formatPhoneNumber} from '../../../store/utility';
import coreApiClient from '../../../store/apiService';
import ContactDetail from './components/ContactDetail';

const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

export default function Contacts() {
    const [searchText, setSearchText] = useState('');
    const [loadingContactsList, setLoadingContactsList] = useState(false);
    const [contactsList, setContactsList] = useState([]);

    useEffect(() => {
        setLoadingContactsList(true)
        coreApiClient.contacts.fetchList()
        .then(res => {
            console.log(res.data)
            setContactsList(res.data.data)
            setLoadingContactsList(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingContactsList(false)
        })
    }, []);

    const fetchContactsList = () => {
      setLoadingContactsList(true)
        coreApiClient.contacts.fetchList()
        .then(res => {
            console.log(res.data)
            setContactsList(res.data.data)
            setLoadingContactsList(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingContactsList(false)
        })
    }

    const handleDeleteContact = (contactId) => {
      setLoadingContactsList(true)
        coreApiClient.contacts.deleteDetail(contactId)
        .then(res => {
            console.log(res.data)
            const newContactsList = contactsList.filter(contact => contact.id !== contactId)
            setContactsList(newContactsList)
            setLoadingContactsList(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingContactsList(false)
        })
    }

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => <ContactDetail contactDetail={record} />,
      }, {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email - b.email,
        render: (text, record) => <a href={`mailto:${text}`} style={{color:'#1890ff'}}>{text ? <span style={{display:'inline-flex'}}>{text}<ExportOutlined style={{fontSize:'10px', paddingLeft:'5px', paddingTop:'7px'}} /></span> : '-'}</a>
      }, {
        title: 'Phone',
        dataIndex: 'phone',
        render: (text, record) => text ? formatPhoneNumber(text) : '-',
      }, {
        title: 'Contact Type',
        dataIndex: 'type',
        filters: [
            { text: 'Investor', value: 'Investor' },
            { text: 'Broker', value: 'Broker' },
            { text: 'Property Manager', value: 'Property Manager' },
            { text: 'Resident', value: 'Resident' },
            { text: 'Agent', value: 'Agent' },
            { text: 'Contractor', value: 'Contractor' },
            { text: 'Seller', value: 'Seller' },
            { text: 'Other', value: 'Other' },
          ],
        onFilter: (value, record) => record.type.includes(value),
        render: text => {
            let color = 'geekblue';
            if (text === 'Investor') {
                color = 'volcano';
              } else if (text === 'Broker') {
                color = 'orange';                  
              } else if (text === 'Property Manager') {
                color = 'gold';                      
            } else if (text === 'Resident') {
                color = 'green';                      
            } else if (text === 'Agent') {
                color = 'cyan';                      
            } else if (text === 'Contractor') {
                color = 'blue';                     
            } else if (text === 'Seller') {
                color = 'geekblue';
            } else {
                color = 'purple';                      
            };
          return (<span>{text ? <Tag color={color}>{text}</Tag> : '-'}</span>);
        },
      },{
        title: 'Last Updated',
        dataIndex: 'updated_on',
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.updated_on) - moment(b.updated_on),
        render: (text, record) => text ? moment(text).format('MM-DD-YY hh:mm a') : '-',
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span style={{display:'inline-flex'}}>
            <CreateContact editContact={true} contactDetail={record} fetchContactsList={fetchContactsList} />
            <Divider type="vertical" style={{height:'auto'}} />
            <div>
            <Popconfirm okText='Yes' title='Delete this contact?' onConfirm={() => handleDeleteContact(record.id)}>
              <Button type='link'>
                <DeleteOutlined style={{paddingLeft:'10px'}} />
              </Button>
            </Popconfirm> 
            </div>        
          </span>
        ),
    }];

    return (
        <div>
            <PageHeader title='Contacts' />
            <div style={{ marginBottom: '30px', display:'flex' }}>
                <Input.Search 
                size='large'
                style={{ width: '300px', minWidth:'300px'}}
                placeholder="Search contacts"
                onChange={e => setSearchText(e.target.value.toLowerCase())}
                />
                <CreateContact fetchContactsList={fetchContactsList} />
            </div>
            <Row>
                <Card
                bordered={false}
                style={{boxShadow:'rgba(115, 162, 208, 0.1) 0px 23px 20px -20px, rgba(115, 162, 208, 0.06) 0px 0px 15px 0px'}}
                bodyStyle={{height:'100%'}}
                >
                    <Row>
                        <Col>
                            <Table
                            columns={columns} 
                            tableLayout={'fixed'}
                            dataSource={searchText ? contactsList.filter(item => JSON.stringify(item).toLowerCase().includes(searchText)) : contactsList}
                            loading={{
                                size: 'large',
                                indicator: loadingIcon,
                                spinning: loadingContactsList,
                                style:{marginTop: '100px'}
                            }}
                            locale={{ emptyText: 
                              <Row justify='center' style={{marginTop:'25px', width:'100%'}}>
                                {loadingContactsList ? null :
                                  <Col md={24}>
                                      <Empty 
                                      description={<div style={{width:'100%'}}>
                                      <h2 style={{color:'#495057', marginTop:'15px'}}>No contacts</h2>
                                      <p>Looks like you havent added any contacts yet.</p>
                                      </div>} 
                                      style={{height:'auto'}} 
                                      image={<ContactsOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} />
                                  </Col>
                                }
                              </Row>
                              }}
                            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
                            size='default'
                            style={{border: 'none', margin:'-25px'}}
                            pagination={{
                                size: 'small',
                                pageSize: 10,
                                hideOnSinglePage: true,
                            }}
                            // scroll={{ x: '100vw' }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Row>
        </div>
    )
}
