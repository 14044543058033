import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CustomerServiceOutlined, ExperimentOutlined, InboxOutlined, ToolOutlined } from '@ant-design/icons';
import {
    Input, Button, Card, Row, Col, Radio, Form,
    Avatar, Upload, Alert, PageHeader, message, Spin, notification,
} from 'antd';
import coreApiClient from '../../../store/apiService';
import Storage from '@aws-amplify/storage';

const SupportRequest = (props) => {
    const [loading, setLoading] = useState(false);
    const [fileObj, setFileObj] = useState(null)
    const [fileName, setFileName] = useState(null)

    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        setLoading(true)

        if (values.file) {
            Storage.put(values.file, fileObj, { level: 'private' })
            .then (result => console.log(result))
            .catch(err => console.log(err));
        }

        coreApiClient.support.createDetail({
            user_id: props.userID,
            request_type: values.request_type,
            title: values.title,
            description: values.description,
        })
        .then(res => {
            notification.success({
                message: 'Request received',
                description: 'Your request has been recieved by our support team. If necessary you will be contacted. Thanks!',
            })
            props.history.push('/')
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            notification.error({
                message: 'Request error',
                description: 'Your request cannot be processed right now. Please try again later',
            })
            props.history.push('/')
            setLoading(false)
        })
    };

    const normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    const handleChange = (info) => {
        form.setFieldsValue({
            file: `${props.userID}/support/${info.file.name}`
        })

        setFileObj(info.file.originFileObj)
        setFileName(info.file.name)
    }


    return (
        <div>
            <PageHeader 
            onBack={() => props.history.goBack()} 
            title="Help Request" 
            subTitle="Submit a issue / support request"
            extra={<Button onClick={() => props.history.goBack()} ghost type='primary'>Cancel</Button>} 
            />
            {loading ? <div style={{textAlign:'center'}}><Spin /></div> :
            <Row style={{margin:'20px'}} justify='center'>
                <Col md={24} lg={18} offset={3}>
                    <Card bordered={false}>
                        <Form 
                        onFinish={handleSubmit}
                        layout='vertical'
                        form={form}
                        >
                            <Row type='flex' justify='center'>
                                <Col span={24}>
                                  <Form.Item
                                  style={{width:'100%', textAlign:'center'}}
                                  name="request_type"
                                  rules={[{ required: true, message: 'Please select a type' }]}
                                  >
                                    <Radio.Group size='large'>
                                        <Row type='flex' justify='center'>
                                        <Col span={8}>
                                            <Card 
                                            hoverable 
                                            style={{margin:'10px', background: form.getFieldValue('request_type') === 'Suggestion' ? '#2196f34a' : '#fff',
                                            border: form.getFieldValue('request_type') === 'Suggestion' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                                            onClick={() => form.setFieldsValue({request_type: 'Suggestion'})}
                                            >
                                                <div style={{marginBottom:'15px'}}><Avatar size={45} icon={<ExperimentOutlined />} style={{background:'#13c2c2'}}/></div>
                                                <Radio value='Suggestion' style={{whiteSpace:'pre-wrap'}}>Suggestion / Request</Radio>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card 
                                            hoverable 
                                            style={{margin:'10px', background: form.getFieldValue('request_type') === 'Problem' ? '#2196f34a' : '#fff',
                                            border: form.getFieldValue('request_type') === 'Problem' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                                            onClick={() => form.setFieldsValue({request_type: 'Problem'})}
                                            >
                                                <div style={{marginBottom:'15px'}}><Avatar size={45} icon={<ToolOutlined />} style={{background:'#13c2c2'}}/></div>
                                                <Radio value='Problem' style={{whiteSpace:'pre-wrap'}}>Encountered a Problem</Radio>
                                            </Card>
                                        </Col>
                                        <Col span={8}>
                                            <Card 
                                            hoverable 
                                            style={{margin:'10px', background: form.getFieldValue('request_type') === 'Help' ? '#2196f34a' : '#fff',
                                            border: form.getFieldValue('request_type') === 'Help' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                                            onClick={() => form.setFieldsValue({request_type: 'Help'})}
                                            >
                                                <div style={{marginBottom:'15px'}}><Avatar size={45} icon={<CustomerServiceOutlined />} style={{background:'#13c2c2'}}/></div>
                                                <Radio value='Help' style={{whiteSpace:'pre-wrap'}}>Need Help</Radio>
                                            </Card>
                                        </Col>
                                        </Row>
                                    </Radio.Group>
                                    </Form.Item>
                                    <Form.Item 
                                    label="Issue / Request"
                                    name="title"
                                    rules={[{ required: true, message: 'Please input your title' }]}
                                    >
                                        <Input size='large' placeholder='Provide a subject for your request' />
                                    </Form.Item>
                                    <p>Please provide a short description of your issue / request. Please include as many details as necessary to resolve the issue and or request.</p>
                                    <Form.Item
                                    name="description"
                                    rules={[{ required: true, message: 'Please provide a description' }]}
                                    >   
                                        <Input.TextArea size='large' rows={4} placeholder='Please provide a detailed description of your request'>
                                        </Input.TextArea>
                                    </Form.Item>
                                    <p>Upload any relevant screenshots or files to resolve the issue / request.</p>
                                    <Form.Item
                                    name="file"
                                    valuePropName='fileList'
                                    getValueFromEvent={normFile}
                                    >
                                    <div className="dropbox">
                                        <Upload.Dragger 
                                        name="file"
                                        onChange={handleChange}
                                        >
                                            <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        </Upload.Dragger>,
                                    </div>
                                    </Form.Item>
                                    <Alert
                                    message="Informational Notes"
                                    description="Please only submit one request per issue. If you have opened a previous request please allow our support team to get back to you."
                                    type="info"
                                    showIcon
                                    />
                                    <p></p>
                                    <Form.Item>
                                        <Button 
                                        type="primary" 
                                        size='large' 
                                        htmlType="submit" 
                                        onClick={() => form.submit()} 
                                        style={{float:'right', background:'#43c463', border:'none'}}
                                        >
                                        Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>   
                    </Card>
                </Col>
            </Row>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return{
        userID : state.user.authenticatedUser.sub,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportRequest);