import React, { useState, useEffect } from 'react'
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Storage from '@aws-amplify/storage';

function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
}

export default function PhotoUpload(props) {
    const [fileList, setFileList] = useState([])
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')

    useEffect(() => {
        Storage.list(`${props.propertyDetail.user_id}/properties/${props.propertyDetail.id}/uploads/`, { level: 'private' })
        .then (res => {
            console.log(res)
            res.forEach(photo => {
                Storage.get(photo.key, { level: 'private' })
                .then(item => {
                    console.log(item)
                    setFileList(oldArray => [...oldArray, {
                        uid: photo.key,
                        name: photo.key.replace(/^.*[\\\/]/, ''),
                        status: 'done',
                        url: item,
                    }])
                })
            });
        })
        .catch(error => {
            console.log(error)
        });
    }, [])

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
  
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    };

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
        <div className="clearfix">
            <Upload
            accept='image/*'
            action={(file) => {
                return new Promise((resolve, reject) => {
                    console.log(file)
                    Storage.put(`${props.propertyDetail.user_id}/properties/${props.propertyDetail.id}/uploads/${file.name}`, file, { level: 'private' })
                    .then (result => {
                        console.log(result)
                        message.success('Uploaded photo')
                        resolve({"result": "success"})
                    })
                    .catch(error => {
                        console.log(error)
                        message.error('Photo could not be uploaded')
                        reject(error)
                    });
                })
            }}
            showUploadList={{ showPreviewIcon: true, showDownloadIcon: true, showRemoveIcon: true }}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={({ fileList }) => setFileList(fileList)}
            onRemove={(file) => {
                return new Promise((resolve, reject) => {
                    console.log(file)
                    Storage.remove(`${props.propertyDetail.user_id}/properties/${props.propertyDetail.id}/uploads/${file.name}`, { level: 'private' })
                    .then (result => {
                        console.log(result)
                        message.success('Removed photo')
                        resolve({"result": "success"})
                    })
                    .catch(error => {
                        console.log(error)
                        message.success('Photo could not be removed')
                        reject(error)
                    });
                })
            }}
            >
            {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={() => setPreviewVisible(false)}
            >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </div>
    )
}
