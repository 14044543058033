import React from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { BankOutlined, CreditCardOutlined, PlusOutlined } from '@ant-design/icons';
import {
  List,
  Col,
  Button,
  Empty,
  Tag,
  Row,
  Popconfirm,
  message,
  Divider,
  Collapse,
  Spin,
  Skeleton,
  Card,
} from 'antd';
import CardHeader from '../../../../components/CardHeader';
import paymentsApiClient from '../../../../store/apiService';

class ResidentPaymentSettings extends React.Component {
  state = {
    stripeCustomer: null,
    externalAccounts: null,
    loading: false,
    platformPayments: 'inactive',
  }

  componentDidMount() {
    this.setState({fetchingstripeCustomer: true })
    let customerId = this.props.authenticatedUser['custom:stripe_customer_id'];
    paymentsApiClient.customers.fetchDetail(customerId)
        .then(res => {
            const stripeCustomer = res.data;
            console.log(stripeCustomer);
            const externalAccounts = stripeCustomer.sources.data;
            console.log(externalAccounts);
            this.setState({
              stripeCustomer: stripeCustomer,
              externalAccounts: externalAccounts,
              fetchingstripeCustomer: false,
            })
        })
        .catch(error => {
            // handle error
            console.log(error);
            this.setState({
              fetchingstripeCustomer: false,
            })
        });
  }

    render () {

      const confirm = (externalAccountId) => {
        this.setState({loading: true })
        paymentsApiClient.externalAccount.deleteDetail(externalAccountId)
            .then(res => {
              this.setState({loading: true })
              paymentsApiClient.account.fetchDetail(this.props.authenticatedUser['custom:stripe_account_id'])
                  .then(res => {
                      const stripeCustomer = res.data;
                      const externalAccounts = res.data.external_accounts.data;
                      console.log(stripeCustomer);
                      this.setState({
                        stripeCustomer: stripeCustomer,
                        externalAccounts: externalAccounts,
                        platformPayments: stripeCustomer.capabilities.card_payments,
                        loading: false,
                      })
                  })
                  .catch(error => {
                      // handle error
                      console.log(error);
                      this.setState({
                        loading: false,
                      })
                  });
            })
            .catch(error => {
                // handle error
                console.log(error);
                this.setState({
                  loading: false,
                })
            });
      }

      console.log(this.state.externalAccounts)
      const bankAccounts = this.state.externalAccounts ? this.state.externalAccounts.filter(item => item.object === 'bank_account') : [];
      const paymentCards = this.state.externalAccounts ? this.state.externalAccounts.filter(item => item.object === 'card') : [];

        return (
          <Col md={18} lg={20}
          style={{paddingLeft:'40px'}}
          >
            <CardHeader title='Payment' subtitle='Settings' description='Manage payment cards and connected bank accounts' />
            {this.state.fetchingstripeCustomer ? 
            <div style={{textAlign:'center'}}>
              <Skeleton loading={this.state.fetchingstripeCustomer} active paragraph={{rows:2}} />
              <Skeleton loading={this.state.fetchingstripeCustomer} active paragraph={{rows:2}} />
            </div>
            :
            <div>
              <div style={{borderBottom:'1px solid #e8e8e8', padding:'12px 0px'}}>
                <div>
                  <span style={{fontSize:'16px', marginBottom:'4px', color:'rgba(0, 0, 0, 0.65)', fontWeight:'500'}}>
                  Bank Accounts
                  </span>
                  <Link to={'/account/settings/payments/bank/create/'} style={{float:'right'}}>
                    <Button size='small' type='primary' icon={<PlusOutlined />} style={{background:'#43c463', border:'none'}}>
                    Add Account
                    </Button>
                  </Link>
                </div>
                <List
                  size="small"
                  style={{marginTop:'10px'}}
                  loading={this.state.loading}
                  bordered
                  locale={{emptyText:<Empty image={<BankOutlined style={{fontSize:'50px'}} />} description='You currently have no linked accounts'/>}}
                  dataSource={bankAccounts}
                  renderItem={item => (
                    <Collapse bordered={false}>
                      <Collapse.Panel header={
                        <List.Item 
                        //style={{paddingBottom:'0px'}}
                        actions={[
                          <Popconfirm title="Are you sure you want disconnect this account?" placement='left' onConfirm={() => confirm(item.id)} okText="Yes" cancelText="No">
                            <a>Remove</a>
                          </Popconfirm>
                        ]}>
                        <List.Item.Meta
                        title={<div><span style={{marginRight:'10px', fontWeight:600}}>{item.bank_name}</span><Tag color='green'>{item.status}</Tag>{item.default_for_currency ? <Tag color='volcano'>default</Tag>: null}</div>}
                        description={<div><span>Last 4 digits: &bull;&bull;&bull;&bull;</span><span>{item.last4}</span></div>}
                        />
                        </List.Item>}
                      >
                        <Col style={{paddingLeft:'40px'}}>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Currency: </span> {item.currency ? item.currency.toUpperCase() : '-'}</p>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Routing Number: </span> {item.routing_number}</p>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Country: </span> {item.country}</p>
                        </Col>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                />
              </div>
              <div style={{borderBottom:'1px solid #e8e8e8', padding:'12px 0px'}}>
                <div>
                  <span style={{fontSize:'16px', marginBottom:'4px', color:'rgba(0, 0, 0, 0.65)', fontWeight:'500'}}>
                  Cards
                  </span>
                  <Link to={'/account/settings/payments/card/create/'} style={{float:'right'}}>
                    <Button type='primary' size='small' icon={<PlusOutlined />} style={{background:'#43c463', border:'none'}}>
                    Add Card
                    </Button>
                  </Link>
                </div>
                <List
                  size="small"
                  style={{marginTop:'10px'}}
                  loading={this.state.loading}                    
                  bordered
                  locale={{emptyText:<Empty image={<CreditCardOutlined style={{fontSize:'50px'}} />} description='You currently have no linked cards'/>}}
                  dataSource={paymentCards}
                  renderItem={item => (
                    <Collapse bordered={false}>
                      <Collapse.Panel 
                      header={
                        <List.Item 
                        actions={[
                          <Popconfirm title="Are you sure you want to remove this card?" placement='left' onConfirm={() => confirm(item.id)} okText="Yes" cancelText="No">
                          <a>Remove</a>
                          </Popconfirm>]
                        }
                        >
                        <List.Item.Meta
                        title={
                          <div>
                            <span style={{fontWeight:600}}>
                            {item.brand}
                            </span>
                            <Divider type='vertical'/>
                            <span style={{fontWeight:'400'}}>{item.funding.charAt(0).toUpperCase() + item.funding.slice(1)}</span>
                          </div>}
                        description={<div><span>Last 4 digits: &bull;&bull;&bull;&bull;</span><span>{item.last4}</span></div>}                    
                        />
                        </List.Item>}
                        >
                        <Col style={{paddingLeft:'40px'}}>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Currency: </span> {item.currency ? item.currency.toUpperCase() : '-'}</p>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Expires: </span> {item.exp_month}/{item.exp_year}</p>
                          <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Country: </span> {item.country}</p>
                        </Col>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                />
              </div>
            </div>
            }
          </Col>
        );
    }
}

export default ResidentPaymentSettings;
