import React, { useEffect, useState } from 'react';
import coreApiClient from '../../../store/apiService';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import {
    Form, Button, Icon, Select, Input, Row, Checkbox, Modal
  } from'antd';

const FormItem = Form.Item;
const { Option } = Select;

const CreateResidentModal = (props) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisibility] = useState(false)

  const [form] = Form.useForm();
  const unitDetail = props.unitDetail;
  const propertyId = props.match.params.propertyId;
  const unitId = props.match.params.unitId;
  const residentId = props.match.params.residentId;

  const showModal = () => {
    setVisibility(true)
    form.setFieldsValue({'unit_name': unitDetail.unit_name})
    if(props.editResident) {
      const residentDetail = props.residentDetail ? props.residentDetail : {};
      form.setFieldsValue(residentDetail)
    }
  };

  const handleCancel = () => {
    form.resetFields()
    setVisibility(false)
  };

  const handleSubmit = (values) => {
      console.log(values)
      values['unit_id'] = unitDetail.id
      values['property_id'] = propertyId
      setLoading(true)
      if(props.editResident) {
        const residentDetail = props.residentDetail ? props.residentDetail : {};
        coreApiClient.residents.updateDetail(values, propertyId, unitDetail.id, residentDetail.id)
        .then(res => {
            props.handleResidentEdit()
            handleCancel()
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
      } else {
        coreApiClient.residents.createDetail(values, propertyId, unitId)
        .then(res => {
            props.handleResidentEdit()
            handleCancel()
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
      }
  }


    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16, offset: 0 },
        };

    return (
      <div style={{width:'100%'}}>
        {props.buttonType === 'link' ? <a onClick={showModal}>{props.residentDetail.first_name} {props.residentDetail.last_name}</a> :
          props.buttonType === 'edit' ?
          <span onClick={showModal}><EditOutlined style={{marginRight:'8px'}} />Edit</span>
          :
          props.buttonType === 'ghost' ?
          <Button 
          icon={<PlusOutlined />} 
          type='primary' 
          ghost 
          onClick={showModal}
          size='small'
          >
              Resident
          </Button>
          :
          <Button 
          style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
          type='primary' 
          size='default'
          onClick={showModal}
          icon={<PlusOutlined />}
          >
              Resident
          </Button>
        }
        <Modal
          visible={visible}
          bodyStyle={{padding:'0px'}}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>Cancel</Button>,
            <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
              Submit
            </Button>,
          ]}
        >
            <Row style={{background:'#ff7a59', padding:'18px'}}>
                <h3 style={{margin:'0px', color:'white'}}>Add Resident</h3>
            </Row>
            <Row type='flex' justify='center' style={{padding:'24px'}}>
                <Form 
                layout='horizontal'
                form={form}
                onFinish={handleSubmit}
                style={{width:'100%'}}
                >                  
                  <FormItem
                  {...formItemLayout}
                  label="Unit"
                  name='unit_name'
                  rules={[{ required: true, message: 'Choose unit' }]}
                  >
                    <Input placeholder="Select a unit" disabled={true} />
                  </FormItem>
                  <FormItem
                      label="First Name"
                      name='first_name'
                      rules={[{ required: true, message: 'Provide name' }]}
                      {...formItemLayout}
                  >
                    <Input placeholder="First name" />
                  </FormItem>
                  <FormItem
                      label="Last Name"
                      name='last_name'
                      rules={[{ required: true, message: 'Provide name' }]}
                      {...formItemLayout}
                  >
                          <Input placeholder="Last name" />
                  </FormItem>
                  <FormItem
                      label="Email"
                      name='email'
                      rules={[{ required: true, message: 'Provide email' }]}
                      hasFeedback
                      {...formItemLayout}
                  >
                    <Input placeholder="Email" />
                  </FormItem>
                  <FormItem
                      label="Phone"
                      name='phone'
                      {...formItemLayout}
                  >
                    <Input placeholder="Phone" />
                  </FormItem>
                </Form>
            </Row>
        </Modal>
      </div>
    );
}

export default CreateResidentModal;