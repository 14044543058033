import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import {formatPhoneNumber} from '../../../store/utility';
import coreApiClient from '../../../store/apiService';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';

import { DownOutlined, HomeOutlined, MinusOutlined, PictureOutlined, PlusOutlined, TeamOutlined, UpOutlined} from '@ant-design/icons';

import {
    Select,InputNumber,Button,Upload,Input,Card,Row,Col,message,Checkbox,
    Radio,Switch,Collapse,Divider,DatePicker,Form, Badge, Avatar
} from 'antd';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;

let id = 0;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}


function beforeUpload(file) {
    const isIMG = file.type === 'image/jpeg' || file.type === 'image/JPEG' || file.type === 'image/PNG' || file.type === 'image/png';
    if (!isIMG) {
      message.error('You can only upload image file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isIMG && isLt2M;
}

const CreateProperty = (props) => {
    const [loading, setLoading] = useState(false)
    const [currentAssetSelection, setCurrentAssetSelection] = useState(null)
    const [showMore, toggleShowMore] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [propertyFileObj, setPropertyFileObj] = useState(null)
    const [propertyFileName, setpropertyFileName] = useState(null)
    const [propertyDetail, setPropertyDetail] = useState(undefined)

    const [keys, setKeys] = useState([])

    const [form] = Form.useForm();

    const handleChange = (e) => {
        console.log(e);
        getBase64(e.file.originFileObj, imageUrl => {
            Auth.currentCredentials()
            .then(res => {
                console.log(res)
                const identityId = res.identityId;
                localStorage.setItem('identityId', identityId);

                form.setFieldsValue({
                    photo: `${identityId}/${props.userID}/properties/${e.file.name}`
                })
    
                setImageUrl(imageUrl)
                setPropertyFileObj(e.file.originFileObj)
                setpropertyFileName(e.file.name)
            })
            .catch(err => { console.log(err) });
        });
    }

    useEffect(() => {
        if(props.location.pathname.includes('edit')) {
            let propertyId = props.match.params.propertyId;
            coreApiClient.properties.fetchDetail(propertyId)
            .then(res => {
                console.log(res.data)
                let property = res.data
                Object.keys(property).forEach(function(key) {
                    if (key === 'purchase_date' || key === 'loan_start_date' || key === 'policy_start_date' || key === 'policy_expiration_date') {
                        property[key] = moment(property[key])
                        console.log(key, property[key]);
                    }  
                });
                setPropertyDetail(res.data)
                form.setFieldsValue(res.data)
                setKeys(res.data['utility_info'] ? [...Array(res.data['utility_info'].length).keys()] : [])
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [])

    const handleSubmit = (values) => {
            console.log('Form Values: ', values);
            setLoading(true)
            if (values.photo) {
                Storage.put(`${props.userID}/properties/${propertyFileName}`, propertyFileObj, { level: 'private' })
                .then (result => console.log(result))
                .catch(err => console.log(err));
            }

            if (keys.length > 0) {
                values['utility_info'] = keys.filter(item => item).map(key => ({
                    utility_type: values[`utility_type[${key}]`] ? values[`utility_type[${key}]`] : null,
                    utility_provider: values[`utility_provider[${key}]`] ? values[`utility_provider[${key}]`] : null,
                    monthly_estimate: values[`monthly_estimate[${key}]`] ? values[`monthly_estimate[${key}]`] : null,
                }))
            }

            if(props.location.pathname.includes('edit')) {
                if (values.photo !== propertyDetail.photo) {
                    Storage.put(`${props.userID}/properties/${propertyFileName}`, propertyFileObj, { level: 'private' })
                    .then (result => console.log(result))
                    .catch(err => console.log(err));
                }
                
                let propertyId = propertyDetail.id
                values['created_on'] = propertyDetail.created_on ? propertyDetail.created_on : ''
                values['purchase_interest_rate'] = values.purchase_interest_rate ? String(values.purchase_interest_rate) : null
                values['loan_interest_rate'] = values.loan_interest_rate ? String(values.loan_interest_rate) : null
                coreApiClient.properties.updateDetail(values, propertyId)
                .then(res => {
                    console.log(res.data)
                    props.history.push(`/properties/${propertyId}`)
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error)
                    setLoading(false)
                })
            } else {
                coreApiClient.properties.createDetail(values)
                .then(res => {
                    console.log(res.data)
                    props.history.push('/properties/')
                    setLoading(false)
                })
                .catch(error => {
                    console.log(error)
                    setLoading(false)
                })
            }
      }

    const remove = k => {    
        // can use data-binding to set
        setKeys(keys.filter(key => key !== k))
    };
    
    const add = () => {
        const nextKeys = keys.concat(keys.length++);
        // can use data-binding to set
        // important! notify form to detect changes
        setKeys(nextKeys)
    };

    const path = props.location.pathname;
    const formItemLayout = {
        wrapperCol: {
            md: { span: 22, offset: 0 },
            lg: { span: 11, offset: 0 },
        }};
    
    const formItemLayoutDuo = {
        wrapperCol: { span: 22, offset: 0 },
        };

    
    const routes = [
        {
        title: 'Properties',
        url: '/properties/',
        },
        {
        title: propertyDetail ? propertyDetail.property_name : 'Create',
        url: `/properties/${propertyDetail ? propertyDetail.id : 'create'}/`,
        },
        {
        title: path.includes('Edit') ? 'Edit' : '',
        },
    ];

    const formItems = keys.map((k, index) => (
        <Card
        title="Utility Item"
        bordered={false}
        style={{ marginTop:'15px' }}
        extra={
            keys.length > 1 ? (
                <Button icon={<MinusOutlined />} onClick={() => remove(k)}>
                    Remove
                </Button>
                ) 
            : null
        }
        >
            <Row>
                <Col span={8}>
                    <FormItem
                    {...formItemLayoutDuo}
                    label="Utility Type"
                    required={false}
                    key={k}
                    name={`utility_type[${k}]`}
                    >
                        <Select placeholder="Choose the utility type">
                            <Option value="Phone">Phone</Option>
                            <Option value="Cable/Satellite">Cable/Satellite</Option>
                            <Option value="Internet">Internet</Option>
                            <Option value="Water">Water</Option>
                            <Option value="Garbage/Sewer">Garbage/Sewer</Option>
                            <Option value="Electricity">Electricity</Option>
                            <Option value="Gas Services">Gas Services</Option>
                            <Option value="Landscaping">Landscaping</Option>
                            <Option value="HOA">HOA</Option>
                            <Option value="City Services">City Services</Option>
                            <Option value="Laundry Services">Laundry Services</Option>
                            <Option value="Pest Control">Pest Control</Option>
                            <Option value="Home Security">Home Security</Option>
                        </Select>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                    {...formItemLayoutDuo}
                    label="Provider"
                    name={`utility_provider[${k}]`}
                    >
                        <Input
                        />
                    </FormItem>
                </Col>
                <Col span={8}>
                <FormItem
                {...formItemLayoutDuo}
                label="Approximate Cost (monthly)"
                name={`monthly_estimate[${k}]`}
                >
                    <InputNumber
                    style={{width:'100%'}}
                    min={0} 
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    />
                </FormItem>
            </Col>
            </Row>
        </Card>
    ));

    const uploadButton = (
        <div style={{padding:'60px 60px', width:'100%'}}>
            <PictureOutlined style={{fontSize:'65px', color:'#999999'}} />
            <div className="ant-upload-text">Upload Photo</div>
        </div>
    );

    return (
        <div style={{overflowY:'hidden'}}>
        <PageHeader
        title={path.includes('create') ? 'Create' : 'Edit'}
        breadCrumbs={routes}
        extra={'Property'}
        action={<Button ghost type='primary' style={{float:'right'}} onClick={() => props.history.goBack()}>Cancel</Button>}
        />
        <div style={{padding:'10px 0px 30px 0px'}}>
            <Form 
            layout='vertical' 
            onFinish={handleSubmit}
            initialValues={{ keys: [] }}
            form={form}
            >
            <Row>
            <Col span={6} style={{paddingRight:'20px'}}>
                <Row type='flex' justify='center'>
                    <FormItem
                    style={{width:'100%'}}
                    name='photo'
                    getValueFromEvent={handleChange}
                    >   
                        <Upload 
                        name="avatar" 
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        buttonStyle={{width:'100%'}}
                        >
                        {imageUrl ? <img src={imageUrl} style={{width:'inherit'}} alt="avatar" /> : 
                        (props.propertyDetail ? <img src={props.propertyDetail.photo} style={{width:'inherit'}} alt="avatar" /> : uploadButton)}
                        </Upload>
                    </FormItem>
                </Row>
            </Col>
            <Col md={24} lg={18}>
                    <Card
                    title="General Info"
                    bordered={false}
                    >
                        <FormItem
                            label="Property Name"
                            name='property_name'
                            rules={[{ required: true, message: 'Please provide a name' }]}
                            {...formItemLayout}
                        >
                            <Input size="large" placeholder="Provide a property name" />
                        </FormItem>
                            <FormItem
                            label="Property Type"
                            name='asset_type'
                            rules={[{ required: true, message: 'Please choose a type', whitespace: true }]}
                            >
                                <RadioGroup size='large'>
                                    <Row>
                                    <Col md={24} lg={12} style={{padding:'5px 10px'}}>
                                        <Card 
                                        hoverable 
                                        style={{background: currentAssetSelection === 'Single-Family' ? '#2196f34a' : '#fff',
                                        border: currentAssetSelection === 'Single-Family' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                                        onClick={() => {
                                            setCurrentAssetSelection('Single-Family')
                                            form.setFieldsValue({asset_type: 'Single-Family'})
                                        }}
                                        >
                                            <p>
                                                <strong>
                                                <Avatar icon={<HomeOutlined style={{fontSize:'20px', color:'#fff'}}/>} style={{marginRight:'8px', backgroundColor:'#ff7a59'}}/>
                                                Single Family Home (SFH)
                                                </strong>
                                            </p> 
                                            <p>Only allows <strong>one unit</strong> per single address</p>
                                            <Radio value='Single-Family'>Single-Family</Radio>
                                        </Card>
                                    </Col>
                                    <Col md={24} lg={12} style={{padding:'5px 10px'}}>
                                        <Card 
                                        hoverable 
                                        style={{background: currentAssetSelection === 'Multi-Family' ? '#2196f34a' : '#fff',
                                        border: currentAssetSelection === 'Multi-Family' ? '1px solid #1890ff' : '1px solid #e8e8e8',
                                        }} 
                                        onClick={() => {
                                            setCurrentAssetSelection('Multi-Family')
                                            form.setFieldsValue({asset_type: 'Multi-Family'})
                                        }}
                                        >
                                            <p>
                                                <strong>
                                                <Avatar icon={<TeamOutlined style={{fontSize:'20px', color:'#fff'}}/>} style={{marginRight:'8px', backgroundColor:'#ff7a59'}}/>
                                                Multi-Family
                                                </strong>
                                            </p> 
                                            <p style={{display:'inline-block'}}><span>Allows for </span><strong>multiple units</strong><span> per single address</span></p>
                                            <Radio value='Multi-Family'>Multi-Family</Radio>
                                        </Card>
                                    </Col>
                                    {/* <Col span={8} style={{padding:'0px 10px'}}>
                                        <Card 
                                        hoverable 
                                        style={{background: currentAssetSelection === 'Commercial' ? '#2196f34a' : '#fff',
                                        border: currentAssetSelection === 'Commercial' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                                        onClick={() => {
                                            setCurrentAssetSelection('Commercial')
                                            form.setFieldsValue({asset_type: 'Commercial'})
                                        }}
                                        >
                                            <p>
                                                <strong>
                                                <Avatar icon={<ShopOutlined style={{fontSize:'20px', color:'#fff'}}/>} style={{marginRight:'8px', backgroundColor:'#ff7a59'}}/>
                                                Commercial
                                                </strong>
                                            </p> 
                                            <p>A single building designated for commercial activities</p>
                                            <Radio value='Commercial'>Commercial</Radio>
                                        </Card>
                                    </Col> */}
                                    </Row>
                                </RadioGroup>
                            </FormItem>
                        
                        <Row>
                            <Col span={12}>
                                <FormItem
                                    label="Address"
                                    name='address'
                                    rules={[{ required: true }]}
                                    {...formItemLayoutDuo}
                                >
                                    <Input size="large" />
                                </FormItem>                          
                            </Col>
                            <Col span={12}>
                                <FormItem
                                {...formItemLayoutDuo}
                                label="Operational Status"
                                hasFeedback
                                name='property_status'
                                rules={[{ required: true }]}
                                >
                                    <Select size="large">
                                        <Option value="Active"><Badge status='success' text={'Active'}/></Option>
                                        <Option value="Listed"><Badge status='processing' text={'Listed'}/></Option>
                                        <Option value="Pending"><Badge status='warning' text={'Pending'}/></Option>
                                        <Option value="Sold"><Badge status='default' text={'Sold'}/></Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>                           
                        <Row>
                            <Col span={12}>
                                <FormItem
                                label="City"
                                name='city'
                                rules={[{ required: true }]}
                                {...formItemLayoutDuo}
                                >
                                    <Input size="large" />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                label="State"
                                name='state'
                                rules={[{ required: true }]}
                                {...formItemLayoutDuo}
                                >
                                    <Input size="large" />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                label="Zip Code"
                                name='zip_code'
                                rules={[{ required: true }]}
                                {...formItemLayoutDuo}
                                >
                                    <Input size="large" />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                label="Country"
                                name='country'
                                rules={[{ required: true }]}
                                {...formItemLayoutDuo}
                                >
                                    <Input size="large" />
                                </FormItem>
                            </Col>
                        </Row>
                        {form.getFieldValue('asset_type') === 'Single-Family' ?
                        <div>
                            <Row>
                                <Col span={8}>
                                    <FormItem
                                    label="Rent"
                                    hasFeedback
                                    name='rent'
                                    rules={[{ required: true }]}
                                    >
                                        <InputNumber
                                        style={{width:'90%'}}
                                        min={1} 
                                        max={9999999999} 
                                        size='large'
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                    label="Bed Count"
                                    name='bed_count'
                                rules={[{ required: true }]}
                                    >
                                        <InputNumber min={1} max={500} style={{width:'90%'}} size='large' />
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem
                                    label="Bath Count"
                                    name='bath_count'
                                rules={[{ required: true }]}
                                    >
                                        <InputNumber min={1} max={500} style={{width:'90%'}} size='large' />
                                    </FormItem>
                                </Col>
                            </Row>
                        </div> :
                        null }
                </Card> 
                <div>
                <div style={{visibility: showMore ? 'visible': 'hidden', height: showMore ? 'auto' : 0, width: showMore ? 'auto' : 0}}>
                <Collapse 
                bordered={false}
                style={{margin:'20px 0px'}}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                >
                    <Panel header="Add Details (optional)" key="1" forceRender={true}>
                        <Card
                        title="Detailed Info"
                        bordered={false}
                        style={{ marginTop:'15px', marginBottom:'15px' }}
                        >
                            <FormItem
                                label='Description'
                                name='property_description'
                                {...formItemLayout}
                                >
                                    <TextArea 
                                    placeholder="Enter a property description" 
                                    autosize={{ minRows: 2, maxRows: 6 }} 
                                    />
                            </FormItem>
                            <Row>
                                <Col span={12}>
                                    <FormItem
                                    label="Property Manager"
                                    name='property_manager'
                                    {...formItemLayoutDuo}
                                    >
                                        <Input />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                    label="County"
                                    name='county'
                                    {...formItemLayoutDuo}
                                    >
                                        <Input />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row type='flex'>
                                <Col>
                                    <FormItem
                                    label="Year Built"
                                    name='year_built'
                                    {...formItemLayout}
                                    style={{paddingRight:'15px'}}
                                    >
                                        <InputNumber style={{width:'200px'}} min={1700} max={2030} maxLength={4} />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem
                                    label="Sq Footage"
                                    name='sq_footage'
                                    {...formItemLayout}
                                    style={{paddingRight:'15px'}}
                                    >
                                        <InputNumber style={{width:'200px'}} min={1} max={9999999999} />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem
                                    label="MLS #"
                                    name='mls_number'
                                    {...formItemLayout}
                                    style={{paddingRight:'15px', width:'200px'}}
                                    >
                                        <Input style={{width:'200px'}} />
                                    </FormItem>
                                </Col>
                            </Row>
                            {form.getFieldValue('asset_type') === 'Single-Family' ?
                                <Row type='flex'>
                                <Col>
                                    <FormItem
                                    label="Security Deposit"
                                    hasFeedback
                                    name='security_deposit'
                                    >
                                        <InputNumber
                                        style={{width:'90%'}}
                                        min={1} 
                                        max={9999999999} 
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </FormItem>
                                </Col>
                                <Col>
                                    <FormItem
                                    label="Parking Spots"
                                    name='parking_count'
                                    >
                                        <InputNumber min={1} max={500} style={{width:'90%'}} />
                                    </FormItem>
                                </Col>
                                </Row>
                            : null}
                        </Card>
                    </Panel>
                </Collapse>
                <Collapse 
                bordered={false}
                style={{margin:'20px 0px'}}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                >
                    <Panel header="Add Features / Ammenities (optional)" key="2" forceRender={true}>
                        <Card
                        
                        title="Features / Ammenities"
                        bordered={false}
                        style={{ marginTop:'15px' }}
                        >
                            <FormItem
                            {...formItemLayout}
                            label="Laundry"
                            name='laundry'
                            >
                                <Select placeholder="Select property features">
                                    <Option value="In-unit">In-unit</Option>
                                    <Option value="Shared">Shared</Option>
                                    <Option value="None">None</Option>
                                </Select>
                            </FormItem>
                            <Row>
                                <Col span={12}>
                                    <FormItem
                                    {...formItemLayoutDuo}
                                    label="Storage"
                                    name='storage'
                                    >
                                        <Radio.Group defaultValue={false} buttonStyle="solid">
                                            <Radio.Button value={true}>Available</Radio.Button>
                                            <Radio.Button value={false}>Unavailable</Radio.Button>
                                        </Radio.Group>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                    {...formItemLayoutDuo}
                                    label="HOA"
                                    name='hoa'
                                    >
                                        <Switch checkedChildren='Yes' unCheckedChildren="No" />
                                    </FormItem>
                                </Col>
                            </Row>
                            <h3>Select Ammenities</h3>
                            <Row>
                                <Col span={6}>
                                    <FormItem
                                    name='pool'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Pool</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    name='gym'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Gym/Fitness Center</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    name='elevator'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Elevator</Checkbox>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='dog_park'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Dog Park</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    name='grill'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Grill</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    name='offstreet_parking'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Offstreet Parking</Checkbox>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='playground'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Playground</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    name='rooftop_patio'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Rooftop / Patio</Checkbox>
                                    </FormItem>
                                    <FormItem
                                    valuePropName="checked"
                                    name='tennis_court'
                                    >
                                        <Checkbox>Tennis Court</Checkbox>
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='wheelchair_access'
                                    valuePropName="checked"
                                    >
                                        <Checkbox>Wheelchair Access</Checkbox>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Card>
                    </Panel>
                    </Collapse>
                    <Collapse 
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    style={{margin:'20px 0px'}}
                    >
                        <Panel header="Add Policies (optional)" key="3"  forceRender={true}>
                            <Card
                            
                            title="Policies"
                            bordered={false}
                            style={{ marginTop:'15px' }}
                            >
                                <Row>
                                    <Col span={12}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Pets Policy"
                                        name='pets_policy'
                                        >
                                            <Select placeholder="Choose a pets policy">
                                            <Option value="No Pets Allowed">No Pets Allowed</Option>
                                            <Option value="Cats Only">Cats Only</Option>
                                            <Option value="Cats and Dogs Only">Cats and Dogs Only</Option>
                                            <Option value="Pets Allowed">Pets Allowed</Option>
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Smoking Policy"
                                        name='smoking_policy'
                                        >
                                            <Select placeholder="Choose a smoking policy">
                                            <Option value="No Smoking Allowed">No Smoking Allowed</Option>
                                            <Option value="Smoking Allowed">Smoking Allowed</Option>
                                            </Select>
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </Panel>
                        </Collapse>
                        <Collapse 
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    style={{margin:'20px 0px'}}
                    >
                        <Panel header="Add Purchase Info (optional)" key="4" forceRender={true}>
                            <Card
                            
                            title="Purchase Information"
                            bordered={false}
                            style={{ marginTop:'15px' }}
                            >
                                <Row type='flex'>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Purchase Price"
                                        name='purchase_price'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            min={0} 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Purchase Date"
                                        name='purchase_date'
                                        >
                                            <DatePicker style={{width:'100%'}} />
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Downpayment"
                                        name='purchase_down_payment'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            min={0} 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Ammortization (years)"
                                        name='ammortization_years'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            min={0} 
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col sm={4}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Interest Rate"
                                        name='purchase_interest_rate'
                                        >
                                            <InputNumber 
                                            style={{width:'100%'}}
                                            min={0} 
                                            max={100} 
                                            step={0.1}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        style={{margin:'20px 0px'}}
                        >
                            <Panel header="Add Loan Info (optional)" key="5" forceRender={true}>
                                <Card
                                
                                title="Loan"
                                bordered={false}
                                style={{ marginTop:'15px' }}
                                >
                                    <Row>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Loan Name"
                                        name='loan_name'
                                        >
                                            <Input
                                            style={{width:'100%'}}
                                            placeholder='Name'
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Loan Amount"
                                        name='loan_amount'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            min={0} 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Interest Rate"
                                        name='loan_interest_rate'
                                        >
                                            <InputNumber 
                                            style={{width:'100%'}}
                                            min={0} max={100} 
                                            step={0.1}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Downpayment"
                                        name='loan_down_payment'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}} 
                                            min={0} 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Period (years)"
                                        name='loan_period'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}} 
                                            min={0} 
                                            max={100}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Balance"
                                        name='loan_balance'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            min={0} 
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Start Date"
                                        name='loan_start_date'
                                        >
                                            <DatePicker style={{width:'100%'}} />
                                        </FormItem>
                                    </Col>
                                    
                                </Row>
                                <Divider>Bank Info</Divider>
                                <Row>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Bank Name"
                                        name='bank_name'
                                        >
                                            <Input
                                            placeholder='Bank name'
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Bank Contact"
                                        name='bank_contact'
                                        >
                                           <Input
                                           placeholder='Contact name'
                                           />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Email"
                                        name='bank_email'
                                        >
                                            <Input
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Phone"
                                        name='bank_phone'
                                        >
                                            <InputNumber
                                            style={{width:'100%'}}
                                            maxLength={17}
                                            formatter={value => formatPhoneNumber(value)}
                                            parser={value => formatPhoneNumber(value)}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                </Card>
                            </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        style={{margin:'20px 0px'}}
                        >
                            <Panel header="Add Insurance Info (optional)" key="6" forceRender={true}>
                                <Card
                                
                                title="Insurance"
                                bordered={false}
                                style={{ marginTop:'15px' }}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Insurance Provider"
                                            name='insurance_provider'
                                            >
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Policy Number"
                                            name='policy_number'
                                            >
                                                <Input />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Email"
                                            name='agent_email'
                                            >
                                                <Input />
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Phone"
                                            name='agent_phone'
                                            >
                                                <InputNumber
                                                style={{width:'100%'}}
                                                maxLength={17}
                                                formatter={value => formatPhoneNumber(value)}
                                                parser={value => formatPhoneNumber(value)}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Name"
                                            name='agent_name'
                                            >
                                                <Input />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Policy Cost"
                                            name='policy_cost'
                                            >
                                                <InputNumber
                                                style={{width:'100%'}}
                                                min={0} 
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Start Date"
                                            name='policy_start_date'
                                            >
                                                <DatePicker style={{width:'100%'}} />
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Expiration Date"
                                            name='policy_expiration_date'
                                            >
                                                <DatePicker style={{width:'100%'}} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Card>
                            </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        >
                            <Panel header="Add Utility Info (optional)" key="7" forceRender={true}>
                                {formItems}
                                <Row justify='center'>
                                    <Button type="dashed" onClick={() => add()} style={{width:'100%', marginTop:'15px'}}>
                                        <PlusOutlined /> Add Utility
                                    </Button>
                                </Row>
                            </Panel>
                        </Collapse>
                        </div>
                        {showMore ?
                        <Row type='flex' justify='center' style={{marginTop:'20px'}}>
                            <Button type='link' icon={<UpOutlined />} onClick={() => toggleShowMore(!showMore)}>Hide Additional Info</Button>
                        </Row>
                        :
                        <Row type='flex' justify='center' style={{marginTop:'20px'}}>
                            <Button type='link' icon={<DownOutlined />} onClick={() => toggleShowMore(!showMore)}>Add Additional Info</Button>
                        </Row>  
                        }
                        </div>
                </Col>
                <div style={{width:'100%'}}>
                    <FormItem style={{float:'right', marginTop:'15px'}}>
                        <Button ghost type='primary' style={{marginRight:'8px'}} size='large' onClick={() => props.history.goBack()}>Cancel</Button>
                        <Button 
                        type='primary' 
                        size='large' 
                        htmlType='submit' 
                        loading={loading} 
                        style={{border:'none', backgroundColor: '#43c463'}}
                        >
                            Submit
                        </Button>
                    </FormItem>
                </div>
                </Row>
            </Form>
        </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userID : state.user.authenticatedUser.sub,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CreateProperty);

