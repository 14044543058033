import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader'
import coreApiClient from '../../../store/apiService';
import {
    EnvironmentOutlined,
    HomeOutlined,
    LoadingOutlined,
    PictureOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { Row, List, Card, Empty, Col, Button, Badge, Input, Select, Tag } from 'antd';
import Storage from '@aws-amplify/storage';
import moment from 'moment';

const { Option } = Select;
const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

export default function PropertiesList() {
    const [propertiesList, setPropertiesList] = useState([]);
    const [loadingPropertiesList, setLoadingPropertiesList] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [searchText, setSearchText] = useState([]);
    
    useEffect(() => {
        setLoadingPropertiesList(true)
        coreApiClient.properties.fetchList(true)
        .then(res => {
            console.log(res.data)
            let propertyList = res.data.data;
            setLoadingPropertiesList(false)
            setPropertiesList(propertyList)
            // propertyList.forEach((property, idx) => {
            //     if(property.photo) {
            //         console.log(property.photo)
            //         Storage.get(property.photo, { level: 'private' })
            //         .then(res => {
            //             property['photo_url'] = res
            //             setPropertiesList(oldArray => [...oldArray, property])
            //         })
            //         .catch(error => {
            //             console.log(error)
            //             setPropertiesList(oldArray => [...oldArray, property])
            //         })
            //     } else {
            //         setPropertiesList(oldArray => [...oldArray, property])
            //     }
            // })
        })
        .catch(error => {
            console.log(error)
            setLoadingPropertiesList(false)
        })
    }, []);

    return (
        <div>
            <PageHeader title='Properties' breadCrumbs={false} />
            <div style={{marginBottom: '30px'}}>
                <Input.Search 
                style={{width: '300px', background:'#e9ecef'}} 
                size='large'
                placeholder={`Search Properties`}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
                <span style={{ marginLeft:'30px' }} >
                    Status
                </span>
                <Select 
                defaultValue="All" 
                style={{ width: 120, margin: '0 10px' }} 
                size='large'
                onChange={value => setStatusFilter(value)}
                >
                    <Option value="">All</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Listed">Listed</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="Sold">Sold</Option>
                </Select>
                <Link to='/properties/create/' style={{color:'#fff'}}>
                    <Button 
                    style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
                    type='primary' 
                    size='large'
                    icon={<PlusOutlined />}
                    >
                        Property
                    </Button>
                </Link>
            </div>
            <div>
                <List
                grid={{gutter: [32,32], md: 1, lg: 2, xl: 3, xxl: 4,}}
                locale={{ emptyText: 
                    <Row justify='center' style={{marginTop:'25px', width:'100%'}}>
                        <Col md={24}>
                            <Empty 
                            description={<div style={{width:'100%'}}>
                            <h2 style={{color:'#495057', marginTop:'15px'}}>No properties</h2>
                            <p>Looks like you havent added any properties yet.</p>
                            </div>} 
                            style={{height:'auto'}} 
                            image={<HomeOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} />
                        </Col>
                    </Row>
                    }}
                pagination={{
                    pageSize: 12,
                    hideOnSinglePage: true,
                    size: 'default',
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
                loading={{
                    size: 'large',
                    indicator: loadingIcon,
                    spinning: loadingPropertiesList,
                    style:{marginTop: '200px'}
                }}
                dataSource={searchText ? propertiesList.filter(item => item.property_status.includes(statusFilter)).filter(item => JSON.stringify(item).toLowerCase().includes(searchText)).sort((a,b) => moment(b.updated_on) - moment(a.updated_on)) : propertiesList.filter(item => item.property_status.includes(statusFilter)).sort((a,b) => moment(b.updated_on) - moment(a.updated_on))}
                renderItem={item => (
                    <List.Item style={{marginBottom:'0px'}}>
                        <Card
                        hoverable
                        style={{borderRadius:'4px'}}
                        bordered={false}
                        cover={<Link to={'/properties/' + item.id + '/'}>
                            {item.photo_url ? 
                            <div>
                                <img alt="Property" style={{width: '100%', height:'200px', objectFit:'cover'}} src={item.photo_url} /> 
                                <div style={{
                                    position:'absolute',
                                    height:'20px',
                                    width: 'auto',
                                    left: 0, 
                                    top: 15, 
                                    padding: '1px 8px', 
                                    color: item.units.filter(item => item.residents_count > 0).length > 0 ? '#fff' : '#fff', 
                                    background: item.units.filter(item => item.residents_count > 0).length > 0 ? '#52c41a' : '#f5222d',
                                    fontSize: '12px'
                                }}>
                                    <span>{item.units.filter(item => item.residents_count > 0).length > 0 ? 'Occupied' : 'Vacant'}</span>
                                </div>
                            </div>
                            :
                            <div style={{height:'200px', width:'inherit', backgroundColor:'#e9ecef', textAlign:'center', verticalAlign:'middle', paddingTop:'50px'}}>
                                <span style={{width:'100%', height:'100%'}}><PictureOutlined style={{color:'#999', fontSize:'100px'}} /></span>
                                <div style={{
                                    position:'absolute',
                                    height:'20px',
                                    width: 'auto',
                                    left: 0, 
                                    top: 15, 
                                    padding: '1px 8px', 
                                    color: item.units.filter(item => item.residents_count > 0).length > 0 ? '#fff' : '#fff', 
                                    background: item.units.filter(item => item.residents_count > 0).length > 0 ? '#52c41a' : '#f5222d',
                                    fontSize: '12px'
                                }}>
                                    <span>{item.units.filter(item => item.residents_count > 0).length > 0 ? 'Occupied' : 'Vacant'}</span>
                                </div>
                            </div>}
                        </Link>}
                        >
                            <Card.Meta
                                title= {<div>
                                    <Link style={{color:'inherit', fontWeight:400}} to={'/properties/' + item.id + '/'}>{item.property_name}</Link>
                                    {item.asset_type === 'Multi-Family' ?
                                    <Tag 
                                    style={{
                                    marginLeft:'15px', 
                                    borderRadius:'10px', 
                                    fontSize:'11px',
                                    backgroundColor:'#e6f7ff',
                                    color:'#1890ff',
                                    border:'#91d5ff',
                                    verticalAlign: 'middle' 
                                    }}
                                    >
                                        {item.units.length === 1 ? `${item.units.length} Unit` : `${item.units.length} Units`}
                                    </Tag>
                                    : null}
                                </div>}
                                description={[<div style={{display:'inline-flex', fontWeight:300, color:'#495057'}}><EnvironmentOutlined style={{marginTop:'12px', marginRight:'10px'}} /><div><p style={{marginBottom:'0px'}}>{item.address}</p>
                                <p>{ item.city + ', ' + item.state + ' ' + item.zip_code}</p></div></div>]} 
                            />
                            <p style={{marginBottom:'0px'}}>
                                <span style={{fontWeight:600}}>{item.asset_type}</span>
                                <span style={{ float:'right'}}>
                                <Badge 
                                status={
                                item.property_status === 'Active' ? 'success' :
                                item.property_status === 'Listed' ? 'processing' :
                                item.property_status === 'Pending' ? 'warning' :
                                'default'
                                } 
                                style={{paddingLeft:'5px'}}/>
                                <span style={{color:
                                    item.property_status === 'Active' ? '#43c463' :
                                    item.property_status === 'Listed' ? '#1890ff' :
                                    item.property_status === 'Pending' ? '#faad14' :
                                    '#d9d9d9'  
                                    }}>{item.property_status}</span>
                                </span>
                            </p>
                            <p className='ant-card-meta-description' style={{marginBottom:'0px', marginTop:'2px'}}>
                                {/* {item.property_description} */}
                            </p>
                        </Card>
                    </List.Item>
                )}
                />
            </div>
        </div>
    );
}
