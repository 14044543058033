import axios from 'axios';

const realtyStackCoreAPI = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_CORE_API_DOMAIN,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('idToken')}`
        }
    });
}

const realtyStackWorkflowAPI = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_WORKFLOW_API_DOMAIN,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('idToken')}`
        }
    });
}

const realtyStackPaymentsAPI = () => {
    return axios.create({
        baseURL: process.env.REACT_APP_PAYMENTS_API_DOMAIN,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('idToken')}`
        }
    });
}

export default {
    properties: {
        fetchList(units) {
            return realtyStackCoreAPI().get(`/properties${units ? '?units=True' : ''}`)
        },
        createDetail(payload) {
            return realtyStackCoreAPI().post('/workflows/properties', payload)
        },
        updateDetail(payload, propertyId) {
            return realtyStackCoreAPI().put(`/properties/${propertyId}`, payload)
        },
        fetchDetail(propertyId, units=false) {
            return realtyStackCoreAPI().get(`/properties/${propertyId}${units ? '?units=True' : ''}`)
        },
        deleteDetail(propertyId) {
            return realtyStackCoreAPI().delete(`/properties/${propertyId}`)
        },
        fetchUnits(propertyId, residents) {
            return realtyStackCoreAPI().get(`/properties/${propertyId}/units${residents ? '?residents=True' : ''}`)
        }
    },
    units: {
        fetchList(residents) {
            return realtyStackCoreAPI().get(`/properties/units${residents ? '?residents=True' : ''}`)
        },
        createDetail(payload, propertyId) {
            return realtyStackCoreAPI().post(`/properties/${propertyId}/units`, payload)
        },
        updateDetail(payload, propertyId, unitId) {
            return realtyStackCoreAPI().put(`/properties/${propertyId}/units/${unitId}`, payload)
        },
        fetchDetail(propertyId, unitId, residents) {
            return realtyStackCoreAPI().get(`/properties/${propertyId}/units/${unitId}${residents ? '?residents=True' : ''}`)
        },
        deleteDetail(propertyId, unitId) {
            return realtyStackCoreAPI().delete(`/properties/${propertyId}/units/${unitId}`)
        },
        fetchUnitResidents(propertyId, unitId) {
            return realtyStackCoreAPI().get(`/properties/${propertyId}/units/${unitId}/residents`)
        }
    },
    residents: {
        createDetail(payload, propertyId, unitId) {
            return realtyStackCoreAPI().post(`/properties/${propertyId}/units/${unitId}/residents`, payload)
        },
        updateDetail(payload, propertyId, unitId, residentId) {
            return realtyStackCoreAPI().put(`/properties/${propertyId}/units/${unitId}/residents/${residentId}`, payload)
        },
        fetchDetail(propertyId, unitId, residentId) {
            return realtyStackCoreAPI().get(`/properties/${propertyId}/units/${unitId}/residents/${residentId}`)
        },
        deleteDetail(propertyId, unitId, residentId) {
            return realtyStackCoreAPI().delete(`/properties/${propertyId}/units/${unitId}/residents/${residentId}`)
        },
    },
    leases: {
        fetchList() {
            return realtyStackCoreAPI().get('/leases')
        },
        createDetail(payload) {
            return realtyStackCoreAPI().post(`/leases`, payload)
        },
        updateDetail(payload, leaseId) {
            return realtyStackCoreAPI().put(`/leases/${leaseId}`, payload)
        },
        fetchDetail(leaseId) {
            return realtyStackCoreAPI().get(`/leases/${leaseId}`)
        },
        deleteDetail(leaseId) {
            return realtyStackCoreAPI().delete(`/leases/${leaseId}`)
        },
    },
    contacts: {
        fetchList() {
            return realtyStackCoreAPI().get('/contacts')
        },
        createDetail(payload) {
            return realtyStackCoreAPI().post(`/contacts`, payload)
        },
        updateDetail(payload, contactId) {
            return realtyStackCoreAPI().put(`/contacts/${contactId}`, payload)
        },
        deleteDetail(contactId) {
            return realtyStackCoreAPI().delete(`/contacts/${contactId}`)
        },
    },
    todos: {
        fetchList() {
            return realtyStackWorkflowAPI().get('/tasks/todos')
        },
        createDetail(payload) {
            return realtyStackWorkflowAPI().post(`/tasks/todos/`, payload)
        },
        updateDetail(payload, taskId) {
            return realtyStackWorkflowAPI().put(`/tasks/todos/${taskId}`, payload)
        },
        deleteDetail(taskId) {
            return realtyStackWorkflowAPI().delete(`/tasks/todos/${taskId}`)
        },
    },
    support: {
        fetchList() {
            return realtyStackWorkflowAPI().get('/tasks/support')
        },
        createDetail(payload) {
            return realtyStackWorkflowAPI().post(`/tasks/support`, payload)
        },
    },
    maintenance: {
        fetchList() {
            return realtyStackWorkflowAPI().get('/tasks/maintenance')
        },
        createDetail(payload) {
            return realtyStackWorkflowAPI().post(`/tasks/maintenance`, payload)
        },
        updateDetail(payload, taskId) {
            return realtyStackWorkflowAPI().put(`/tasks/maintenance/${taskId}`, payload)
        },
        deleteDetail(taskId) {
            return realtyStackWorkflowAPI().delete(`/tasks/maintenance/${taskId}`)
        },
    },
    profile: {
        createDetail(payload) {
            return realtyStackCoreAPI().post(`/profile`, payload)
        },
        updateDetail(payload) {
            return realtyStackCoreAPI().put(`/profile`, payload)
        },
        fetchDetail() {
            return realtyStackCoreAPI().get(`/profile`)
        },
    },
    account: {
        fetchLink(accountId, payload) {
            return realtyStackPaymentsAPI().post(`/accounts/${accountId}/link`, payload)
        },
        fetchList() {
            return realtyStackPaymentsAPI().get('/accounts')
        },
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/accounts`, payload)
        },
        fetchDetail(accountId) {
            return realtyStackPaymentsAPI().get(`/accounts/${accountId}`)
        },
        updateDetail(payload, accountId) {
            return realtyStackPaymentsAPI().put(`/accounts/${accountId}`, payload)
        },
        deleteDetail(accountId) {
            return realtyStackPaymentsAPI().delete(`/accounts/${accountId}`)
        },
    },
    customers: {
        fetchList() {
            return realtyStackPaymentsAPI().get('/customers')
        },
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/customers`, payload)
        },
        fetchDetail(customerId) {
            return realtyStackPaymentsAPI().get(`/customers/${customerId}`)
        },
        updateDetail(payload, customerId) {
            return realtyStackPaymentsAPI().put(`/customers/${customerId}`, payload)
        },
        deleteDetail(customerId) {
            return realtyStackPaymentsAPI().delete(`/customers/${customerId}`)
        },
        createSource(payload, customerId) {
            return realtyStackPaymentsAPI().post(`/customers/${customerId}/sources`, payload)
        },
    },
    externalAccount: {
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/external-accounts`, payload)
        },
        fetchDetail(externalAccountId) {
            return realtyStackPaymentsAPI().get(`/external-accounts/${externalAccountId}`)
        },
        fetchList() {
            return realtyStackPaymentsAPI().get('/external-accounts')
        },
        updateDetail(payload, externalAccountId) {
            return realtyStackPaymentsAPI().put(`/external-accounts/${externalAccountId}`, payload)
        },
        deleteDetail(externalAccountId) {
            return realtyStackPaymentsAPI().delete(`/external-accounts/${externalAccountId}`)
        },
    },
    invoice: {
        fetchUpcoming(customerId) {
            return realtyStackPaymentsAPI().get(`/invoices/${customerId}`)
        },
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/accounts`, payload)
        },
        fetchDetail(accountId) {
            return realtyStackPaymentsAPI().get(`/accounts/${accountId}`)
        },
        updateDetail(payload, accountId) {
            return realtyStackPaymentsAPI().put(`/accounts/${accountId}`, payload)
        },
        deleteDetail(accountId) {
            return realtyStackPaymentsAPI().delete(`/accounts/${accountId}`)
        },
    },
    paymentMethod: {
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/payment-methods`, payload)
        },
        fetchDetail(paymentMethodId) {
            return realtyStackPaymentsAPI().get(`/payment-methods/${paymentMethodId}`)
        },
        fetchList() {
            return realtyStackPaymentsAPI().get('/payment-methods')
        },
        updateDetail(payload, paymentMethodId) {
            return realtyStackPaymentsAPI().put(`/payment-methods/${paymentMethodId}`, payload)
        },
        deleteDetail(paymentMethodId) {
            return realtyStackPaymentsAPI().delete(`/payment-methods/${paymentMethodId}`)
        },
    },
    plan: {
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/plans`, payload)
        },
        fetchDetail(planId) {
            return realtyStackPaymentsAPI().get(`/plans/${planId}`)
        },
        updateDetail(payload, planId) {
            return realtyStackPaymentsAPI().put(`/plans/${planId}`, payload)
        },
        deleteDetail(planId) {
            return realtyStackPaymentsAPI().delete(`/plans/${planId}`)
        },
    },
    source: {
        createDetail(payload) {
            return realtyStackPaymentsAPI().post(`/sources`, payload)
        },
        fetchDetail(sourceId) {
            return realtyStackPaymentsAPI().get(`/sources/${sourceId}`)
        },
        updateDetail(payload, sourceId) {
            return realtyStackPaymentsAPI().put(`/sources/${sourceId}`, payload)
        },
        deleteDetail(sourceId) {
            return realtyStackPaymentsAPI().delete(`/sources/${sourceId}`)
        },
    },
    activity: {
        fetchList() {
            return realtyStackWorkflowAPI().get('/activity')
        },
        createDetail(payload) {
            return realtyStackWorkflowAPI().post(`/activity`, payload)
        },
        updateDetail(payload, activityId) {
            return realtyStackWorkflowAPI().put(`/activity/${activityId}`, payload)
        },
        deleteDetail(activityId) {
            return realtyStackWorkflowAPI().delete(`/activity/${activityId}`)
        },
    },
}