import React, { useState, useEffect } from 'react';
import coreApiClient from '../../../store/apiService';
import PageHeader from '../../../components/PageHeader';

import {
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    EnvironmentOutlined,
    LoadingOutlined,
    PictureOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import { Spin, Card, Col, Badge, Row, Button, Dropdown, Menu, Modal, Tag } from 'antd';
import DetailTabs from './components/DetailTabs';
import { Link } from 'react-router-dom';
import Storage from '@aws-amplify/storage';

const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
const confirm = Modal.confirm;

export default function PropertiesDetail(props) {
    const [propertyDetail, setPropertyDetail] = useState({});
    const [loadingProperty, setLoadingPropertyState] = useState(false);

    const [propertyUnits, setPropertyUnits] = useState([]);
    const [loadingUnits, setLoadingUnitsState] = useState(false);

    const propertyId = props.match.params.propertyId;

    useEffect(() => {
        setLoadingPropertyState(true)
        coreApiClient.properties.fetchDetail(propertyId, true)
            .then(res => {
                console.log(res.data)
                let property = res.data;
                setPropertyDetail(property)
                setLoadingPropertyState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingPropertyState(false)
            })

        setLoadingUnitsState(true)
        coreApiClient.properties.fetchUnits(propertyId, true)
            .then(res => {
                console.log(res.data)
                let propertyUnits = res.data.data;
                setPropertyUnits(propertyUnits)
                setLoadingUnitsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingUnitsState(false)
            })

    }, []);

    const fetchPropertyUnits = () => {
        setLoadingUnitsState(true)
        coreApiClient.properties.fetchUnits(propertyId, true)
            .then(res => {
                console.log(res.data)
                let propertyUnits = res.data.data;
                setPropertyUnits(propertyUnits)
                setLoadingUnitsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingUnitsState(false)
            })

    }

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure you want to delete this property?',
            content: 'This property can also be listed as inactive. If deleted all information will be removed.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                coreApiClient.properties.deleteDetail(propertyId)
                .then(res => {
                    if (res.status === 200) {
                        props.history.push('/properties/');
                    }
                })
                .catch(error => {
                    // handle error
                    console.log(error)
                });
            }, 
            onCancel() {
            console.log('Cancel');
            },
        });
    }

    const menu = (
        <Menu>
            <Menu.Item key="1">
                <Link to={`/properties/${props.match.params.propertyId}/units/create/`}>
                    <PlusOutlined style={{marginRight:'8px'}} />Unit
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to={`/properties/${props.match.params.propertyId}/edit/`}>
                    <EditOutlined style={{marginRight:'8px'}} />Edit
                </Link>
            </Menu.Item>
            <Menu.Item key="3">
                <a onClick={() => showDeleteConfirm()} style={{color:'#f5222d'}}>
                    <DeleteOutlined style={{marginRight:'8px'}} />
                    Delete
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            {loadingProperty ? 
            <div style={{padding:'200px', textAlign:'center'}}><Spin indicator={loadingIcon} /></div>
            :
            <div>
                <PageHeader 
                //title={propertyDetail.property_name}
                //subtitle={propertyDetail.address}
                breadCrumbs={[
                    {'title': 'Dashboard', 'url': '/'},
                    {'title': 'Properties', 'url': '/properties/'},
                    {'title': propertyDetail.property_name},
                ]} 
                />
                <Card
                bordered={false}
                style={{borderRadius:'8px'}}
                bodyStyle={{padding:'0px', height:'180px', display:'flex'}}
                >
                    {propertyDetail.photo ? 
                        <div>
                            <img alt="Property" 
                            style={{width: '300px', height:'180px', objectFit:'cover',borderRadius:'8px 0px 0px 8px'}} 
                            src={propertyDetail.photo_url} 
                            /> 
                            <div style={{
                                position:'absolute',
                                height:'20px',
                                width: 'auto',
                                left: 0, 
                                top: 15, 
                                padding: '1px 8px', 
                                color: propertyUnits.filter(item => item.residents_count > 0).length > 0 ? '#fff' : '#fff', 
                                background: propertyUnits.filter(item => item.residents_count > 0).length > 0 ? '#52c41a' : '#f5222d',
                                fontSize: '12px'
                            }}>
                                <span>{propertyUnits.filter(item => item.residents_count > 0).length > 0 ? 'Occupied' : 'Vacant'}</span>
                            </div>
                        </div>
                        :
                        <div>
                            <div 
                            style={{
                                height:'100%', 
                                width:'400px', 
                                backgroundColor:'#e9ecef', 
                                textAlign:'center', 
                                verticalAlign:'middle', 
                                paddingTop:'50px',
                                borderRadius:'8px 0px 0px 8px'
                            }}>
                                <span style={{width:'100%', height:'100%'}}>
                                    <PictureOutlined style={{color:'#999', fontSize:'100px'}} />
                                </span>
                            </div>
                            <div style={{
                                position:'absolute',
                                height:'20px',
                                width: 'auto',
                                left: 0, 
                                top: 15, 
                                padding: '1px 8px', 
                                color: propertyUnits.filter(item => item.residents_count > 0).length > 0 ? '#fff' : '#fff', 
                                background: propertyUnits.filter(item => item.residents_count > 0).length > 0 ? '#52c41a' : '#f5222d',
                                fontSize: '12px'
                            }}>
                                <span>{propertyUnits.filter(item => item.residents_count > 0).length > 0 ? 'Occupied' : 'Vacant'}</span>
                            </div>
                        </div>
                    }
                    <Row style={{padding:'15px 20px', height:'100%', display:'flex', width:'100%'}} justify='space-between'>
                        <Col style={{height:'100%'}} lg={20} xl={14}>
                            <div style={{marginTop:'12px', fontWeight:400, textTransform:'uppercase', fontSize:'1.25rem'}}>
                                {propertyDetail.property_name}
                                {propertyDetail.asset_type === 'Multi-Family' ?
                                <Tag 
                                style={{
                                marginLeft:'15px', 
                                borderRadius:'10px', 
                                fontSize:'11px',
                                backgroundColor:'#e6f7ff',
                                color:'#1890ff',
                                border:'#91d5ff',
                                verticalAlign: 'middle' 
                                }}
                                >
                                    {propertyUnits.length === 1 ? `${propertyUnits.length} Unit` : `${propertyUnits.length} Units`}
                                </Tag>
                                : null}
                            </div>
                            <div>
                                <div style={{display:'inline-flex', fontWeight:300, color:'#a3aeb7', fontSize:'17.5px', marginTop:'5px'}}>
                                    <EnvironmentOutlined style={{marginTop:'15px', marginRight:'10px'}} />
                                    <div>
                                        <p style={{marginBottom:'0px'}}>{propertyDetail.address}</p>
                                        <p>{ propertyDetail.city + ', ' + propertyDetail.state + ' ' + propertyDetail.zip_code}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop:'12px', fontWeight:400, fontSize:'14px', position:'absolute', bottom:0}}>
                                <span style={{ textTransform:'uppercase', paddingRight:'10px' }}>
                                    {propertyDetail.asset_type}
                                </span>
                                <Badge 
                                    status={
                                    propertyDetail.property_status === 'Active' ? 'success' :
                                    propertyDetail.property_status === 'Listed' ? 'processing' :
                                    propertyDetail.property_status === 'Pending' ? 'warning' :
                                    'default'
                                    } 
                                    style={{paddingLeft:'5px'}}
                                />
                                <span 
                                style={{color:
                                    propertyDetail.property_status === 'Active' ? '#43c463' :
                                    propertyDetail.property_status === 'Listed' ? '#1890ff' :
                                    propertyDetail.property_status === 'Pending' ? '#faad14' :
                                    '#d9d9d9', 
                                }}>
                                    {propertyDetail.property_status}
                                </span>
                            </div>
                        </Col>
                        <Col lg={4} xl={10}>
                            <Dropdown overlay={menu} placement='bottomRight'>
                                <Button size='default' style={{ background:'#e9ecef', float: 'right'}}>
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card>
                <DetailTabs
                propertyId={propertyId}
                propertyDetail={propertyDetail} 
                propertyUnits={propertyUnits}
                loadingUnits={loadingUnits} 
                fetchPropertyUnits={fetchPropertyUnits}
                {...props}
                />
            </div>
            }
        </div>
    );
}
