import React, { useState } from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Divider, Collapse, Button, Tag } from 'antd';
import {
    DownOutlined,
    MinusOutlined,
    PlusOutlined,
    UpOutlined,
} from '@ant-design/icons';
import moment from 'moment';

const FormItem = Form.Item;
const Panel = Collapse.Panel;

export default function PropertyDetails(props) {
    const [showMore, toggleShowMore] = useState(false)

    const propertyDetail = props.propertyDetail
    const propertyUnits = props.propertyUnits

    const formItemLayout = {
        wrapperCol: { span: 11, offset: 0 },
        };
    
    const formItemLayoutDuo = {
        wrapperCol: { span: 22, offset: 0 },
        };

    return (
        <div style={{marginTop:'30px'}}>
            <h3 style={{fontWeight:400, textTransform:'uppercase', color:'#3c484f', fontSize:'19px'}}>
                Details
            </h3>
            <Card
            bordered={false}
            style={{boxShadow:'rgba(115, 162, 208, 0.1) 0px 23px 20px -20px, rgba(115, 162, 208, 0.06) 0px 0px 15px 0px', lineHeight:'16px'}}
            >
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Asset Type"
                    >
                        <span className="ant-form-text">{propertyDetail.asset_type}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Property Manager"
                    >
                        <span className="ant-form-text">{propertyDetail.property_manager ? propertyDetail.property_manager : '-'}</span>
                    </FormItem>
                </Col>
            </Row>
            <Divider orientation="left">General Info</Divider>
            <Row>
                <Col md={12}>
                    <FormItem
                    label="Property Name"
                    >
                        <span className="ant-form-text">{propertyDetail.property_name}</span>
                    </FormItem>
                </Col>
                <Col md={12}>
                    <FormItem
                    label="Address"
                    >
                        <span className="ant-form-text">{propertyDetail.address}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="City"
                    >
                        <span className="ant-form-text">{propertyDetail.city}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="State"
                    >
                        <span className="ant-form-text">{propertyDetail.state}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Zip Code"
                    >
                        <span className="ant-form-text">{propertyDetail.zip_code}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={12} xl={12}>
                    <FormItem
                    label="Country"
                    >
                        <span className="ant-form-text">{propertyDetail.country}</span>
                    </FormItem>
                </Col>
            </Row>
            <Divider orientation="left">Detailed Info</Divider>
            {propertyDetail.asset_type === 'Single-Family' ?
            <Row>
                <Col md={12} lg={6} xl={4}>
                    <FormItem
                    label="Rent"
                    >
                        <span className="ant-form-text">${propertyDetail.units[0].rent}</span>
                    </FormItem>
                </Col>
                <Col md={12} lg={6} xl={4}>
                    <FormItem
                    label="Security Deposit"
                    >
                        <span className="ant-form-text">${propertyDetail.units[0].security_deposit ? propertyDetail.units[0].security_deposit : '-'}</span>
                    </FormItem>
                </Col>
                <Col md={12} lg={6} xl={4}>
                    <FormItem
                    label="Bed Count"
                    >
                        <span className="ant-form-text">{propertyDetail.units[0].bed_count}</span>
                    </FormItem>
                </Col>
                <Col md={12} lg={6} xl={4}>
                    <FormItem
                    label="Bath Count"
                    >
                        <span className="ant-form-text">{propertyDetail.units[0].bath_count}</span>
                    </FormItem>
                </Col>
                <Col md={12} lg={6} xl={4}>
                    <FormItem
                    label="Parking Count"
                    >
                        <span className="ant-form-text">{propertyDetail.units[0].parking_count ? propertyDetail.units[0].parking_count : '-'}</span>
                    </FormItem>
                </Col>
            </Row> :
            null}
            <Row>
                <Col md={24} lg={6}>
                    <FormItem
                    label="County"
                    >
                        <span className="ant-form-text">{propertyDetail.county ? propertyDetail.county : '-'}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={6}>
                    <FormItem
                    label="Year Built"
                    >
                        <span className="ant-form-text">{propertyDetail.year_built ? propertyDetail.year_built : '-'}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={6}>
                    <FormItem
                    label="Sq Footage"
                    >
                        <span className="ant-form-text">{propertyDetail.sq_footage ? propertyDetail.sq_footage : '-'}</span>
                    </FormItem>
                </Col>
                <Col md={24} lg={6}>
                    <FormItem
                    label="MLS#"
                    >
                        <span className="ant-form-text">{propertyDetail.mls_number ? propertyDetail.mls_number : '-'}</span>
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <FormItem
                    label="Description"
                    >
                        <span className="ant-form-text">{propertyDetail.property_description ? propertyDetail.property_description : '-'}</span>
                    </FormItem>
                </Col>
            </Row>
            </Card>
            {showMore ? 
            <div>
            <Collapse 
                bordered={false}
                style={{margin:'20px 0px'}}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                >
                    <Panel header="Features / Ammenities" key="2">
                        <Card
                        
                        title="Features / Ammenities"
                        bordered={false}
                        style={{ marginTop:'15px' }}
                        >
                            <FormItem
                            {...formItemLayout}
                            label="Laundry"
                            >
                                {propertyDetail.laundry ? propertyDetail.laundry : '-'}
                            </FormItem>
                            <Row>
                                <Col span={12}>
                                    <FormItem
                                    label="Storage"
                                    >
                                        {propertyDetail.storage ? 'Available' : '-'}
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                    label="HOA"
                                    >
                                        {propertyDetail.hoa ? 'Yes' : '-'}
                                    </FormItem>
                                </Col>
                            </Row>
                            <h3>Ammenities</h3>
                            <Row>
                                <Col span={6}>
                                    <FormItem
                                    name='pool'
                                    >
                                        {propertyDetail.pool ? <Tag color="green">pool</Tag> : <Tag color="red">stainless steel applicances</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='gym'
                                    >
                                        {propertyDetail.gym ? <Tag color="green">gym</Tag> : <Tag color="red">gym</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='elevator'
                                    >
                                        {propertyDetail.elevator ? <Tag color="green">elevator</Tag> : <Tag color="red">elevator</Tag>}
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='dog_park'
                                    >
                                        {propertyDetail.dog_park ? <Tag color="green">dog park</Tag> : <Tag color="red">dog park</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='grill'
                                    >
                                        {propertyDetail.grill ? <Tag color="green">grill</Tag> : <Tag color="red">grill</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='offstreet_parking'
                                    >
                                        {propertyDetail.offstreet_parking ? <Tag color="green">offstreet parking</Tag> : <Tag color="red">offstreet parking</Tag>}
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='playground'
                                    >
                                        {propertyDetail.playground ? <Tag color="green">playground</Tag> : <Tag color="red">playground</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='rooftop_patio'
                                    >
                                        {propertyDetail.rooftop_patio ? <Tag color="green">rooftop patio</Tag> : <Tag color="red">rooftop patio</Tag>}
                                    </FormItem>
                                    <FormItem
                                    name='tennis_court'
                                    >
                                        {propertyDetail.tennis_court ? <Tag color="green">tennis court</Tag> : <Tag color="red">tennis court</Tag>}
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem
                                    name='wheelchair_access'
                                    >
                                        {propertyDetail.wheelchair_access ? <Tag color="green">wheelchair access</Tag> : <Tag color="red">wheelchair access</Tag>}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Card>
                    </Panel>
                    </Collapse>
                    <Collapse 
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    style={{margin:'20px 0px'}}
                    >
                        <Panel header="Policies" key="3">
                            <Card
                            
                            title="Policies"
                            bordered={false}
                            style={{ marginTop:'15px' }}
                            >
                                <Row>
                                    <Col span={12}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Pets Policy"
                                        name='pets_policy'
                                        >
                                            {propertyDetail.pets_policy ? propertyDetail.pets_policy : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Smoking Policy"
                                        name='smoking_policy'
                                        >
                                            {propertyDetail.smoking_policy ? propertyDetail.smoking_policy : '-'}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </Panel>
                        </Collapse>
                        <Collapse 
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    style={{margin:'20px 0px'}}
                    >
                        <Panel header="Purchase Info" key="3">
                            <Card
                            title="Purchase Information"
                            bordered={false}
                            style={{ marginTop:'15px' }}
                            >
                                <Row type='flex'>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Purchase Price"
                                        name='purchase_price'
                                        >
                                            {propertyDetail.purchase_price ? `$${propertyDetail.purchase_price}` : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Purchase Date"
                                        name='purchase_date'
                                        >
                                            {propertyDetail.purchase_date ? moment(propertyDetail.purchase_date).format('MM-DD-YY') : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Downpayment"
                                        name='purchase_down_payment'
                                        >
                                            {propertyDetail.purchase_down_payment ? `$${propertyDetail.purchase_down_payment}` : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col sm={5}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Ammortization (years)"
                                        name='ammortization_years'
                                        >
                                            {propertyDetail.ammortization_years ? propertyDetail.ammortization_years : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col sm={4}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Interest Rate"
                                        name='purchase_interest_rate'
                                        >
                                            {propertyDetail.purchase_interest_rate ? `${propertyDetail.purchase_interest_rate}%` : '-'}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>
                        </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        style={{margin:'20px 0px'}}
                        >
                            <Panel header="Loan Info" key="3">
                                <Card
                                
                                title="Loan"
                                bordered={false}
                                style={{ marginTop:'15px' }}
                                >
                                    <Row>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Loan Name"
                                        name='loan_name'
                                        >
                                            {propertyDetail.loan_name ? propertyDetail.loan_name : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Loan Amount"
                                        name='loan_amount'
                                        >
                                            {propertyDetail.loan_amount ? `$${propertyDetail.loan_amount}` : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={8}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Interest Rate"
                                        name='loan_interest_rate'
                                        >
                                            {propertyDetail.loan_interest_rate ? `${propertyDetail.loan_interest_rate}%` : '-'}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Downpayment"
                                        name='loan_down_payment'
                                        >
                                            {propertyDetail.loan_down_payment ? `$${propertyDetail.loan_down_payment}` : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Period (years)"
                                        name='loan_period'
                                        >
                                            {propertyDetail.loan_period ? propertyDetail.loan_period : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Balance"
                                        name='loan_balance'
                                        >
                                            {propertyDetail.loan_balance ? `$${propertyDetail.loan_balance}` : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Start Date"
                                        name='loan_start_date'
                                        >
                                            {propertyDetail.loan_start_date ? moment(propertyDetail.loan_start_date).format('MM-DD-YY') : '-'}
                                        </FormItem>
                                    </Col>
                                    
                                </Row>
                                <Divider>Bank Info</Divider>
                                <Row>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Bank Name"
                                        name='bank_name'
                                        >
                                            {propertyDetail.bank_name ? propertyDetail.bank_name : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Bank Contact"
                                        name='bank_contact'
                                        >
                                            {propertyDetail.bank_contact ? propertyDetail.bank_contact : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Email"
                                        name='bank_email'
                                        >
                                            {propertyDetail.bank_email ? propertyDetail.bank_email : '-'}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem
                                        {...formItemLayoutDuo}
                                        label="Phone"
                                        name='bank_phone'
                                        >
                                            {propertyDetail.bank_phone ? propertyDetail.bank_phone : '-'}
                                        </FormItem>
                                    </Col>
                                </Row>
                                </Card>
                            </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        style={{margin:'20px 0px'}}
                        >
                            <Panel header="Insurance Info" key="3">
                                <Card
                                title="Insurance"
                                bordered={false}
                                style={{ marginTop:'15px' }}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Insurance Provider"
                                            name='insurance_provider'
                                            >
                                                {propertyDetail.insurance_provider ? propertyDetail.insurance_provider : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Policy Number"
                                            name='policy_number'
                                            >
                                                {propertyDetail.policy_number ? propertyDetail.policy_number : '-'}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Email"
                                            name='agent_email'
                                            >
                                                {propertyDetail.agent_email ? propertyDetail.agent_email : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Phone"
                                            name='agent_phone'
                                            >
                                                {propertyDetail.agent_phone ? propertyDetail.agent_phone : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Agent Name"
                                            name='agent_name'
                                            >
                                                {propertyDetail.agent_name ? propertyDetail.agent_name : '-'}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Policy Cost"
                                            name='policy_cost'
                                            >
                                                {propertyDetail.policy_cost ? `$${propertyDetail.policy_cost}` : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Start Date"
                                            name='policy_start_date'
                                            >
                                                {propertyDetail.policy_start_date ? moment(propertyDetail.policy_start_date).format('MM-DD-YY') : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Expiration Date"
                                            name='policy_expiration_date'
                                            >
                                                {propertyDetail.policy_expiration_date ? moment(propertyDetail.policy_expiration_date).format('MM-DD-YY') : '-'}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Card>
                            </Panel>
                        </Collapse>
                        <Collapse 
                        bordered={false}
                        expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                        >
                            <Panel header="Utility Info" key="3">
                                {propertyDetail.utility_info ? propertyDetail.utility_info.map(item => item ? (
                                    <Card inner style={{marginTop:'10px', marginBottom:'10px'}}>
                                        <Row>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Utility Type"
                                            name='utility_type'
                                            >
                                                {item.utility_type ? item.utility_type : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Utility Provider"
                                            name='utility_provider'
                                            >
                                                {item.utility_provider ? item.utility_provider : '-'}
                                            </FormItem>
                                        </Col>
                                        <Col span={8}>
                                            <FormItem
                                            {...formItemLayoutDuo}
                                            label="Monthly Estimate"
                                            name='monthly_estimate'
                                            >
                                                {item.monthly_estimate ? item.monthly_estimate : '-'}
                                            </FormItem> 
                                        </Col>
                                    </Row>
                                    </Card>
                                ) : null) : null}
                            </Panel>
                        </Collapse>
                        <Row type='flex' justify='center' style={{marginTop:'20px'}}>
                            <Button type='link' icon={<UpOutlined />} onClick={() => toggleShowMore(!showMore)}>Hide Additional Info</Button>
                        </Row>
                        </div>
                        : 
                        <Row type='flex' justify='center' style={{marginTop:'20px'}}>
                            <Button type='link' icon={<DownOutlined />} onClick={() => toggleShowMore(!showMore)}>Show Additional Info</Button>
                        </Row>
                        }
        </div>
    )
}
