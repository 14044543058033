import React from 'react'
import { Card } from 'antd'

export default function StatCard(props) {
    return (
        <Card bordered={false} bodyStyle={{paddingLeft:'12px', paddingTop:'12px'}}>
            <p style={{fontSize: '18px'}}>{props.title}</p>
        </Card>
    )
}
