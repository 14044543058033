import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Board from "react-trello/dist";
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Button, Tooltip, Spin, Input, Menu, message } from "antd";
import CustomFormCard from "./components/CustomFormCard";
import CustomTrelloCard from "./components/CustomTrelloCard";
import PageHeader from '../../../components/PageHeader';
import workflowApiClient from '../../../store/apiService';
import moment from 'moment';

const cardStyle = { minWidth: 350, margin: 'auto', marginBottom: 5, borderBottom: 'None' };
const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

const menu = (
    <Menu style={{width:'150px'}}>
    <Menu.Item>
        <Link to='/maintenance/'><span style={{fontSize:'18px'}}>Maintenance</span></Link>
    </Menu.Item>
    </Menu>
);

export default function Todos(props) {
    const [taskList, setTaskList] = useState([]);
    const [loadingTaskList, setTaskListLoadingState] = useState(false);
    const [searchText, setSearchText] = useState([]);
    
    useEffect(() => {
        setTaskListLoadingState(true)
        workflowApiClient.todos.fetchList()
        .then(res => {
            console.log(res.data)
            setTaskList(res.data.data)
            setTaskListLoadingState(false)
        })
        .catch(error => {
            console.log(error)
            setTaskListLoadingState(false)
        })
    }, []);

    const fetchTaskList = () => {
        workflowApiClient.todos.fetchList()
            .then(res => {
                console.log(res.data)
                setTaskList(res.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const addTask = (card, laneID) => {
        workflowApiClient.todos.createDetail({
            sort_key: moment(),
            title: card.title,
            description: card.description,
            due_date: card.due_date,
            status: laneID,
        })
        .then(res => {
            console.log(res.data)
            message.success('Task created')
            fetchTaskList()
        })
        .catch(error => {
            message.error('Task could not be created')
            console.log(error)
        })
    }
    
    const updateTask = (taskID, sourceLaneId, targetLaneId, position, cardDetails) => {
        if (targetLaneId !== sourceLaneId) {
            workflowApiClient.todos.updateDetail({
                sort_key: cardDetails.sort_key,
                title: cardDetails.title,
                description: cardDetails.description,
                due_date: cardDetails.due_date,
                status: targetLaneId,
            }, cardDetails.object_id)
            .then(res => {
                console.log(res.data)
                message.success('Task updated')
                //fetchTaskList()
            })
            .catch(error => {
                message.success('Task could not be updated')
                console.log(error)
            })
        } else {
            return false
        }
    }

    const deleteTask = (taskID) => {
        workflowApiClient.todos.deleteDetail(taskID)
        .then(res => {
            console.log(res.data)
            message.success('Task deleted')
            fetchTaskList()
        })
        .catch(error => {
            message.error('Task could not be deleted')
            console.log(error)
        })
    }

    const todosList = taskList ? (searchText ? taskList.filter(item => JSON.stringify(item).toLowerCase().includes(searchText)) : taskList) : []; 
    const dataSource = {
        lanes: [
            {   
            id: 'New Issues',
            title: <div>
                <p style={{fontSize:'13px', color:'#666'}}>
                {String(todosList.filter(item => item.status === 'New Issues').length)} Issues
                </p>
                <p style={{fontSize:'15px'}}>New Issues</p>
                </div>,
            label: <Tooltip title="New Issues that have not yet been addressed"><InfoCircleOutlined /></Tooltip>,
            cards: todosList.filter(item => item.status === 'New Issues').sort((a, b) => a.priority_rank - a.priority_rank).map(item => 
                ({
                    id: item.sort_key,
                    object_id: item.object_id,
                    sort_key: item.sort_key,
                    title: item.title, 
                    description: item.description, 
                    due_date: item.due_date, 
                    cardStyle: {...cardStyle},
                })
            ),
            style: {width:375, backgroundColor:'#eaeef1', height:600, border:'1px solid #d7e1ea'},
            },
            {   
            id: 'In Progress',
            title: <div>
                <p style={{fontSize:'13px', color:'#666'}}>
                {String(todosList.filter(item => item.status === 'In Progress').length)} Issues
                </p>
                <p style={{fontSize:'15px'}}>In Progress</p>
            </div>,
            label: <Tooltip title="Issues currently being worked on"><InfoCircleOutlined /></Tooltip>,
            cards: todosList.filter(item => item.status === 'In Progress').sort((a, b) => a.priority_rank - b.priority_rank).map(item => 
                ({
                    id: item.sort_key,
                    object_id: item.object_id,
                    sort_key: item.sort_key,
                    title: item.title, 
                    description: item.description, 
                    due_date: item.due_date, 
                    cardStyle: {...cardStyle},
                })
            ),
            style: {width:375, backgroundColor:'#eaeef1', height:600, border:'1px solid #d7e1ea'},
            },
            {   
            id: 'Completed',
            title: <div>
                <p style={{fontSize:'13px', color:'#666'}}>
                {String(todosList.filter(item => item.status === 'Completed').length)} Issues
                </p>
                <p style={{fontSize:'15px'}}>Completed</p>
            </div>,
            label: <Tooltip title="Issues that have been completed"><InfoCircleOutlined /></Tooltip>,
            cards: todosList.filter(item => item.status === 'Completed').sort((a, b) => a.priority_rank - b.priority_rank).map(item => 
                ({
                    id: item.sort_key,
                    object_id: item.object_id,
                    sort_key: item.sort_key,
                    title: item.title, 
                    description: item.description, 
                    due_date: item.due_date, 
                    cardStyle: {...cardStyle},
                })
            ),
            style: {width:375, backgroundColor:'#eaeef1', height:600, border:'1px solid #d7e1ea'},
            },
        ]
    };

    const AddCardLink = ({onClick, t}) => {
        return <Button block icon={<PlusOutlined />} onClick={onClick} style={{width:'350px', marginTop:'8px', border:'none'}} size='small'></Button>;
    }   

    const CustomCard = (props) => {
        return <CustomTrelloCard deleteTask={deleteTask} {...props}  />
    }   

    return (
      <div style={{ minHeight: 280}}>
          <PageHeader title='Todos' />
          <Row style={{ width:'100%', margin: '0px 0px 24px 0px'}}>
            {/* <Dropdown overlay={menu}>
                <div  style={{fontSize:'26px', display:'inline-flex'}}>
                Todo <Icon type="down" style={{ margin: '12px 20px 0px 8px' }} />
                </div>
            </Dropdown> */}
              <Input.Search 
              className='list-page-search'
              size='large'
              dataSource={todosList ? todosList.map(item => JSON.stringify(item)) : []}
              style={{ width: 300,}}
              placeholder="Search tasks"
              onChange={e => setSearchText(e.target.value.toLowerCase())}
              />
        </Row>
          <Row justify='center' type='flex'>
            { loadingTaskList ?
            <div style={{padding:'200px'}}><Spin indicator={loadingIcon} /></div>
            :
            <Board 
              data={dataSource} 
              draggable
              editable={true}
              laneDraggable={false}
              collapsibleLanes={false}
              hideCardDeleteIcon={true}
              components={{
                  AddCardLink: AddCardLink,
                  NewCardForm: CustomFormCard,
                  Card: CustomCard,
              }}
              cardStyle={{width:'inherit'}}
              laneStyle={{width:'375px'}}
              style={{background: 'inherit', padding:'10px', width:'inherit',}}
              onCardAdd={(card, laneId) => addTask(card, laneId)}
              handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) => updateTask(cardId, sourceLaneId, targetLaneId, position, cardDetails)}
              />}
          </Row>
        </div>
    )
}

