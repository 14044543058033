import React, { useState, useEffect } from 'react'
import PageHeader from '../../components/PageHeader';
import { FileProtectOutlined, LoadingOutlined, ArrowRightOutlined, PlusOutlined, EllipsisOutlined, StopOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Button, Select, Card, List, Row, Col, Empty, Divider, Menu, Statistic, Dropdown, Tag, Popconfirm, Modal, notification } from 'antd';
import { Link } from 'react-router-dom';
import coreApiClient from '../../store/apiService';
import moment from 'moment';
import { ordinalSuffixOf } from '../../store/utility';

const confirm = Modal.confirm;
const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;


export default function LeasesList() {
    const [leasesList, setLeasesList] = useState([]);
    const [loadingLeasesList, setLoadingLeasesList] = useState(false);
    const [statusFilter, setStatusFilter] = useState('');
    const [searchText, setSearchText] = useState([]);
    const [deletionIndicator, setDeletionIndicator] = useState('')

    useEffect(() => {
        setLoadingLeasesList(true)
        coreApiClient.leases.fetchList()
        .then(res => {
            console.log(res.data)
            let leases = res.data.data;
            setLoadingLeasesList(false)
            setLeasesList(leases)
        })
        .catch(error => {
            console.log(error)
            setLoadingLeasesList(false)
        })
    }, []);

    const showCancelConfirm = (id) => {
        confirm({
          title: 'Are you sure you want to cancel this lease?',
          content: <div><p>Once cancelled all payments for the lease will be cancelled</p><p>Please type <strong>Cancel</strong> into the text box to confirm cancellation of the lease</p><Input onChange={(e) => setDeletionIndicator(e.target.value)}></Input></div>,
          okText: 'Cancel Lease',
          okType: 'danger',
          icon: <ExclamationCircleOutlined style={{color:'red', fontSize:'24px'}} />,
          cancelText: 'No',
          onOk() {
            if (deletionIndicator === 'cancel' || deletionIndicator === 'Cancel') {
                setLoadingLeasesList(true)
                coreApiClient.leases.deleteDetail(id)
                .then(res => {
                    console.log(res.data)

                    coreApiClient.leases.fetchList()
                    .then(res => {
                        console.log(res.data)
                        let leases = res.data.data;
                        setLoadingLeasesList(false)
                        setLeasesList(leases)
                    })
                    .catch(error => {
                        console.log(error)
                        setLoadingLeasesList(false)
                    })
                })
                .catch(error => {
                    console.log(error)
                    setLoadingLeasesList(false)
                })
            } else {
              notification.error({
                message:'Lease Cancellation',
                description: 'Please type "Cancel" to cancel the lease. If you do not wish to procceed, click cancel',
              })
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

      const leaseMenu = (id) => (
        <Menu>
          <Menu.Item key="1">
            <EditOutlined />
            Edit
          </Menu.Item>
          <Menu.Item key="2">
            <div onClick={() => showCancelConfirm(id)}>
            <StopOutlined style={{color:'rgb(245, 34, 45)', marginRight:'6px'}} />
            Cancel
            </div>
          </Menu.Item>
        </Menu>
      );

    return (
        <div>
            <PageHeader title='Leases' />
            <div style={{marginBottom: '30px'}}>
                <Input.Search 
                style={{width: '300px', background:'#e9ecef'}} 
                size='large'
                placeholder={`Search Leases`}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
                {/* <span style={{ marginLeft:'30px' }} >
                    Status
                </span>
                <Select 
                defaultValue="All" 
                style={{ width: 120, margin: '0 10px' }} 
                size='large'
                onChange={value => setStatusFilter(value)}
                >
                    <Option value="">All</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Canceled">Canceled</Option>
                    <Option value="Archived">Archived</Option>
                </Select> */}
                <Link to='/leases/create/' style={{color:'#fff'}}>
                    <Button 
                    style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
                    type='primary' 
                    size='large'
                    icon={<PlusOutlined />}
                    >
                        Lease
                    </Button>
                </Link>
            </div>
            <div>
                <List
                //grid={{gutter: [32,32], md: 1, lg: 2, xl: 3, xxl: 4,}}
                locale={{ emptyText: 
                    <Row justify='center' style={{marginTop:'25px'}}>
                        <Col md={24}>
                            <Empty 
                            description={<div style={{width:'100%'}}>
                            <h2 style={{color:'#495057', marginTop:'15px'}}>No leases</h2>
                            <p>Looks like you havent added any leases yet.</p>
                            </div>} 
                            style={{height:'auto'}} 
                            image={<FileProtectOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} />
                        </Col>
                    </Row>
                    }}
                pagination={{
                    pageSize: 12,
                    hideOnSinglePage: true,
                    size: 'default',
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }}
                loading={{
                    size: 'large',
                    indicator: loadingIcon,
                    spinning: loadingLeasesList,
                    style:{marginTop: '200px'}
                }}
                // dataSource={leasesList.filter(item => JSON.stringify(item).toLowerCase().includes(searchText))}
                dataSource={searchText ? leasesList.filter(item => JSON.stringify(item).toLowerCase().includes(searchText)).sort((a,b) => moment(b.updated_on) - moment(a.updated_on)) : leasesList.sort((a,b) => moment(b.updated_on) - moment(a.updated_on))}
                renderItem={item => (
                    <Card
                    style={{marginTop:'15px', width:'100%'}}
                    bodyStyle={{padding:'0px'}}
                    type='inner'
                    hoverable
                    title={
                        <div>
                        <Link to={`/leases/${item.id}`} style={{fontWeight:600}}>
                        <span>{item.property ? item.property.property_name : '-'}</span>
                        <Divider type='vertical' />
                        <span>{item.unit ? item.unit.unit_name : '-'}</span>
                        </Link>
                        <Tag
                        style={{
                            color: item.unit ? 
                            item.unit.unit_status === 'Active' ? 'rgb(67, 196, 99)' :
                            item.unit.unit_status === 'Listed' ? '#1890ff' :
                            item.unit.unit_status === 'Pending' ? '#1890ff' : '#d9d9d9'
                            : '#d9d9d9',
                            backgroundColor:'rgb(246, 255, 237)',
                            marginLeft:'15px',
                            border:'none'
                        }}
                        >
                            {item.unit ? item.unit.unit_status : '-'}
                        </Tag>
                        </div>
                    }
                    extra={
                        <div style={{display:'inline-flex'}}>
                        <Col style={{padding:'0px 20px'}}>
                            <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                                {moment(item.start_date).format('MMM Do, YYYY')}
                            </div>
                        </Col>
                        <ArrowRightOutlined style={{marginTop:'3px'}} />
                        <Col style={{padding:'0px 20px'}}>
                            <div style={{ color: 'rgba(0, 0, 0, 0.85)'}}>
                            {item.end_date ? moment(item.end_date).format('MMM Do, YYYY') : item.lease_type.charAt(0).toUpperCase() + item.lease_type.substring(1)}
                            </div>
                        </Col>
                        <Col style={{paddingLeft:'20px'}}>
                            <Dropdown overlay={leaseMenu(item.id)}>
                                <Button><EllipsisOutlined /></Button>
                            </Dropdown>
                        </Col>
                        </div>
                    }
                    >
                        <List.Item style={{width:'100%', padding:'16px 20px'}}>
                        <Row style={{width:'100%', padding:'16px 20px'}}>
                            <Col md={8}>
                            <Statistic title="Balance Due" value={112893} prefix='$' />
                            <span>As of today</span>
                            </Col>
                            <Col  md={8}>
                            <Statistic title="Rent" value={item.amount} prefix='$' />
                            <span>Due on the {item.monthly_due_date ? ordinalSuffixOf(item.monthly_due_date) : ' - '}</span>
                            </Col>
                            <Col  md={8}>
                            <span style={{marginBottom: '4px', color: 'rgba(0, 0, 0, 0.45)',fontSize: '14px'}}>Residents</span>
                            {item.residents ? item.residents.map(item => <p>{item.first_name} {item.last_name}</p>) : ''}
                            </Col>
                        </Row>
                        </List.Item>
                    </Card>
                )}
                />
            </div>
        </div>
    );
}
