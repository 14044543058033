import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

class AccountLayout extends React.Component {

  render() {
    return (
        <div style={{ minHeight: '100vh', background: '#002140' }}>
            <Row type='flex' justify='center'>
                {this.props.children}
            </Row>
        </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(null, mapDispatchToProps)(AccountLayout));