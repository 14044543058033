import React from 'react'
import { Calendar, Card } from 'antd';

export default function CalendarCard() {
    return (
        <Card bordered={false} bodyStyle={{padding:'0px'}}>
            <div style={{paddingLeft:'12px', paddingTop:'12px', paddingBottom: '10px', fontSize: '18px', backgroundColor:'#ff7a59', color:'#fff'}}>
                Calendar
            </div>
            <Calendar 
            fullscreen={false} 
            />
        </Card>    
    )
}
