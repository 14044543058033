import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row } from 'antd';

export default function PageHeader(props) {

    const breadCrumbs = (props) => {
        return props.breadCrumbs ? props.breadCrumbs.map(item => (
            <Breadcrumb.Item>
                {item.url ? 
                <Link to={item.url}>{item.title}</Link>
                :
                <span>{item.title}</span>
                }
            </Breadcrumb.Item>
        )) : [];
    }

    return (
        <div>
            {props.breadCrumbs ?
            <Row>
                <Breadcrumb>
                    {breadCrumbs(props)}
                </Breadcrumb>
            </Row>
            :
            null}
            <div style={{margin: '0 0 calc(1rem + 6px)', display:'inline-flex', width:'100%'}}>
                <h1 style={{fontSize:'35px', fontWeight:'400', lineHeight:'1.2', marginBottom:'0px', color:'#495057', whiteSpace:'nowrap'}}>
                    {props.title} {props.extra ? <span style={{fontWeight:600}}>{props.extra}</span> : null}
                </h1>
                {props.subtitle ? 
                <h1 
                style={{
                    fontSize:'20px', 
                    fontWeight:'300', 
                    lineHeight:'1.2', 
                    marginBottom:'0px', 
                    color:'#495057', 
                    marginTop:'14px',
                    marginLeft: '15px',
                }}>
                    {props.subtitle}
                </h1>
                : null}
                {props.action ? 
                <div style={{width:'100%', float:'right'}}>
                    {props.action}
                </div>
                    :
                null
                }
            </div>
        </div>
    )
}
