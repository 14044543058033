import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined, LockOutlined, MailOutlined, FacebookOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox, Spin, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/auth';
import {Card} from 'antd';
import asset26 from '../../assets/Asset 26.svg';
import asset5 from '../../assets/Asset 5.svg';
import SEO from '../../components/SEO';
// import FacebookLogin from 'react-facebook-login';

const FormItem = Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const LoginForm = (props) => {
  const [social, setSocial] = useState(false)

  useEffect(() => {
    return () => {
      console.log('will unmount');
    }
  }, []);

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    if (social) {
      props.socialLogin(values.email, values.password);
    } else {
      props.onAuth(values.email, values.password);
    }
  }

  if (props.isAuthenticated) {
    if (props.userConfirmed) {
      if (props.accountType) {
        return <Redirect to='/' />;
      } else {
        return <Redirect to='/account/profile/create/' />;
      }
    } else if (!props.userConfirmed) {
      let email = form.getFieldValue('email')
      return <Redirect to={`/signup/confirm/${email ? `?email=${email}` : ''}`} />;
    }
  }

  let errorMessage =null;
  if (props.error) {
      if (props.error.code === 'UserNotConfirmedException') {
        let email = form.getFieldValue('email')
        return <Redirect to={`/signup/confirm/${email ? `?email=${email}` : ''}`} />;
      }
      errorMessage = (
          <p>
            {props.error.message ? 
            props.error.code === 'UserNotFoundException' ? 
            <span style={{color:'red'}}>Incorrect username or password.</span> : 
            <span style={{color:'red'}}>{props.error.message}</span> : 
            null}
          </p>
      );
  }
    
    return (
        <Col sm={22} md={12} lg={8}  style={{marginTop:'100px', textAlign:'center'}}>
          <SEO title={'Login'} />
          <div style={{marginBottom:'15px'}}>
              <a href={process.env.REACT_APP_BASE_DOMAIN} style={{margin:'0 12px'}}>
                  <img className="logo" alt="" src={asset5}
                  style={{ height:'48px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
                  />
                  <img className="logo" alt="RealtyStack" src={asset26}
                  style={{ marginLeft:'8px', paddingTop:'4px', height:'48px', marginTop:'15px', transition: 'all .2s'}} 
                  />
              </a>
          </div>
          <Card>
            {errorMessage}
            {
                props.loading ?

                <Spin indicator={antIcon} />

                :
            
                <Form onFinish={handleSubmit} className="login-form" form={form}>
                    <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Login</h1>
                    <FormItem
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                    >
                      <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    </FormItem>
                    <FormItem
                    name="password"
                    rules={[{ required: true, message: 'Please input your password' }]}
                    >
                      <Input.Password size='large' prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                    </FormItem>
                    <FormItem style={{marginBottom:'0px', marginTop:'8px'}}>
                        <a style={{float: 'right', marginBottom:'5px'}} className="login-form-forgot" href=""><Link to='/account/password/reset/'>Forgot password?</Link></a>
                        <Button size='large' block loading={props.loading} style={{background:'#43c463', border:'none', marginTop:'5px'}} type="primary" htmlType="submit" className="login-form-button">
                            Login
                        </Button>
                    </FormItem>
                    {/* <FormItem style={{marginBottom:'0px', marginTop:'8px'}}>
                      <Button 
                      size='large' 
                      block 
                      loading={props.loading} 
                      style={{backgroundColor: '#3b5998', color: '#fff'}}
                      htmlType="submit" 
                      onClick={() => setSocial(true)}
                      >
                        <FacebookOutlined />
                        Sign In with Facebook
                      </Button>
                    </FormItem> */}
                </Form>
            }
          </Card>
          <Row style={{padding:'20px'}} justify='center'>
            <span style={{color:'white'}}>Don't have an account?</span>
            <NavLink style={{marginLeft: '10px', color:'white', borderBottom:'1px solid white'}} to='/signup/'>Signup</NavLink>
          </Row>
          </Col>
    );
}

const mapStateToProps = (state) => {
    return{
        loading: state.user.authLoading,
        error: state.user.error,
        isAuthenticated : state.user.isAuthenticated,
        authenticatedUser : state.user.authenticatedUser,
        userConfirmed: state.user.userConfirmed,
        accountType: state.user.accountType,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password)),
        socialLogin: (email, password) => dispatch(actions.authSocialLogin(email, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);