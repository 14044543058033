import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader';
import { connect } from 'react-redux';
import { MinusOutlined, PictureOutlined, PlusOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import {formatPhoneNumber} from '../../../store/utility';
import coreApiClient from '../../../store/apiService';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';

import {
    Select,InputNumber,Button,Upload,Form,Collapse,
    Input,Card,Row,Col,message,Checkbox,Radio,Switch,
} from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const { TextArea } = Input;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

function beforeUpload(file) {
    const isJPG = file.type === 'image/jpeg';
    if (!isJPG) {
      message.error('You can only upload JPG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJPG && isLt2M;
}

const UnitCreate = (props) => {
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [unitFileObj, setUnitFileObj] = useState(null)
    const [unitFileName, setUnitFileName] = useState(null)
    const [propertyDetail, setPropertyDetail] = useState(undefined)
    const [unitDetail, setUnitDetail] = useState(undefined)

    const propertyId = props.match.params.propertyId;
    const unitId = props.match.params.unitId;

    useEffect(() => {
        if(props.location.pathname.includes('edit')) {
            coreApiClient.units.fetchDetail(propertyId, unitId)
            .then(res => {
                console.log(res.data)
                setUnitDetail(res.data)
                form.setFieldsValue(res.data)
            })
            .catch(error => {
                console.log(error)
            })
        } else {
            coreApiClient.properties.fetchDetail(propertyId)
            .then(res => {
                console.log(res.data)
                setPropertyDetail(res.data)
                form.setFieldsValue({'property_address': res.data.address})
                form.setFieldsValue({'property_name': res.data.name})
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [])

    const [form] = Form.useForm();

    const handleChange = (e) => {
        console.log(e);
        getBase64(e.file.originFileObj, imageUrl => {
            Auth.currentCredentials()
            .then(res => {
                console.log(res)
                const identityId = res.identityId;
                localStorage.setItem('identityId', identityId);

                form.setFieldsValue({
                    photo: `${identityId}/${props.userID}/properties/${propertyId}/units/${e.file.name}`
                })
    
                setImageUrl(imageUrl)
                setUnitFileObj(e.file.originFileObj)
                setUnitFileName(e.file.name)
            })
        });
    }

    const handleSubmit = (values) => {
        values['property_id'] = propertyId
        console.log('Form Values: ', values);
        setLoading(true)

        if (values.photo) {
            Storage.put(`${props.userID}/properties/${propertyId}/units/${unitFileName}`, unitFileObj, { level: 'private' })
            .then (result => console.log(result))
            .catch(err => console.log(err));
        }

        if(props.location.pathname.includes('edit')) {
            if (values.photo !== unitDetail.photo) {
                Storage.put(`${props.userID}/properties/${propertyId}/units/${unitFileName}`, unitFileObj, { level: 'private' })
                .then (result => console.log(result))
                .catch(err => console.log(err));
            }
            coreApiClient.units.updateDetail(values, propertyId, unitId)
            .then(res => {
                console.log(res.data)
                props.history.push(`/properties/${propertyId}/units/${unitId}`)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
        } else {
            coreApiClient.units.createDetail(values, propertyId)
            .then(res => {
                console.log(res.data)
                props.history.push(`/properties/${propertyId}/`)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
        }
      }

    const formItemLayout = {
        wrapperCol: {
            md: { span: 22, offset: 0 },
            lg: { span: 11, offset: 0 },
        }};
    
    const formItemLayoutDuo = {
        wrapperCol: { span: 22, offset: 0 },
        };

    const uploadButton = (
        <div style={{padding:'75px'}}>
            <PictureOutlined style={{fontSize:'40px'}} />
            <div className="ant-upload-text">Upload Photo</div>
        </div>
        );

      const routes = [
        {
          title: 'Properties',
          url: '/properties/',
        },
        {
            title: 'Create',
            url: `/properties/create/`,
        },
      ];

    return (
        <div>
        <PageHeader
        title={'Create'}
        breadCrumbs={routes}
        extra={'Unit'}
        action={<Button ghost type='primary' style={{float:'right'}} onClick={() => props.history.goBack()}>Cancel</Button>}
        />
            <Form 
            layout='vertical' 
            form={form}
            onFinish={handleSubmit}
            >
            <Row style={{padding:'10px 0px 30px 0px'}}>
            <Col span={6} style={{paddingRight:'20px'}}>
                <FormItem
                name='photo'
                getValueFromEvent={handleChange}
                >   
                    <Upload 
                    name="avatar" 
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    >
                    {imageUrl ? <img src={imageUrl} style={{maxWidth:'220px'}} alt="avatar" /> : uploadButton}
                    </Upload>
                </FormItem>
            </Col>
            <Col md={24} lg={18}>
                    <Card
                    title="General Info"
                    bordered={false}
                    >
                        <Form.Item 
                        label="Property"
                        name='property_address'
                        hasFeedback
                        rules={[{ required: true, message: 'Please choose a property' }]}
                        {...formItemLayout}
                        >
                            <Input size="large" placeholder="Select a property" disabled={true} />
                        </Form.Item>
                        <FormItem
                            label="Unit Name"
                            name='unit_name'
                            rules={[{ required: true, message: 'Provide a name' }]}
                            {...formItemLayout}
                        >
                            <Input size="large" placeholder="Select a name for the unit" />
                        </FormItem>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                {...formItemLayoutDuo}
                                label="Unit Type"
                                name='unit_type'
                                rules={[{ required: true, message: 'Please choose a type' }]}
                                >
                                    <Select size="large" placeholder="Please select the unit type">
                                        <Option value="Apartment">Apartment</Option>
                                        <Option value="Condo">Condo</Option>
                                        <Option value="Duplex">Duplex</Option>
                                        <Option value="Triplex">Triplex</Option>
                                        <Option value="Quadplex">Quadplex</Option>
                                        <Option value="Townhouse">Townhouse</Option>
                                        <Option value="Suite">Suite</Option>
                                        <Option value="Commercial">Commercial</Option>
                                    </Select>
                                </FormItem>                               
                            </Col>
                            <Col span={12}>
                                <FormItem
                                {...formItemLayoutDuo}
                                label="Unit Status"
                                name='unit_status'
                                rules={[{ required: true, message: 'Please choose a status' }]}
                                >
                                    <Select size="large" placeholder="Please select the operational status">
                                        <Option value="Active">Active</Option>
                                        <Option value="Listed">Listed</Option>
                                        <Option value="Pending">Pending</Option>
                                        <Option value="Sold">Sold</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>        
                        </Card>                   
                <Card
                title="Detailed Info"
                bordered={false}
                style={{ marginTop:'15px', marginBottom:'15px' }}
                >
                    <Row type='flex'>
                        <Col>
                            <FormItem
                            label="Bed Count"
                            {...formItemLayout}
                            name='bed_count'
                            rules={[{ required: true, message: 'Provide a count' }]}
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber style={{width:'200px'}} size="large" min={0} max={1000} />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                            label="Bath Count"
                            {...formItemLayout}
                            name='bath_count'
                            rules={[{ required: true, message: 'Provide a count' }]}
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber style={{width:'200px'}} size="large" min={0} max={1000} />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                            label="Rent"
                            name='rent'
                            rules={[{ required: true, message: 'Rent is required' }]}
                            {...formItemLayout}
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber 
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 style={{width:'200px'}} 
                                 size="large" 
                                 min={0} 
                                 max={1000000} 
                                 />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row type='flex'>
                        <Col>
                            <FormItem
                            label="Parking Count"
                            name='parking_count'
                            {...formItemLayout}
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber style={{width:'200px'}} size="large" min={0} max={100} />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                            label="Security Deposit"
                            {...formItemLayout}
                            name='security_deposit'
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                style={{width:'200px'}} 
                                size="large" 
                                min={0} 
                                max={1000000} />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                            label="Sq Footage"
                            {...formItemLayout}
                            name='sq_footage'
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber style={{width:'200px'}} size="large" min={1} max={9999999999} />
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem
                            label="MLS #"
                            {...formItemLayout}
                            name='mls_number'
                            style={{paddingRight:'15px'}}
                            >
                                <InputNumber style={{width:'200px'}} size="large" min={1} max={9999999999} />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        label='Description'
                        name='unit_description'
                        {...formItemLayout}
                        >
                            <TextArea 
                            placeholder="Enter a unit description" 
                            autosize={{ minRows: 2, maxRows: 6 }} 
                            />
                    </FormItem>
                </Card>
                <Collapse 
                bordered={false}
                style={{margin:'20px 0px'}}
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                >
                    <Panel header="Add Features / Ammenities (optional)" key="1" forceRender={true}>
                    <Card
                    title="Features / Ammenities"
                    bordered={false}
                    style={{ marginTop:'15px' }}
                    >
                        <FormItem
                        {...formItemLayout}
                        label="Laundry"
                        name='laundry'
                        >
                            <Select size="large" placeholder="Select availabilty">
                                <Option value="In-unit">In-unit</Option>
                                <Option value="Shared">Shared</Option>
                                <Option value="None">None</Option>
                            </Select>
                        </FormItem>
                        <FormItem
                        {...formItemLayout}
                        label="Storage"
                        name='storage'
                        >
                            <Radio.Group defaultValue={false} buttonStyle="solid">
                                <Radio.Button value={true}>Available</Radio.Button>
                                <Radio.Button value={false}>Unavailable</Radio.Button>
                            </Radio.Group>
                        </FormItem>
                        <Row>
                            <Col span={8}>
                                <FormItem
                                {...formItemLayout}
                                label="Utilities Included"
                                name='utilities_included'
                                valuePropName="checked"
                                >
                                    <Switch checkedChildren='Yes' unCheckedChildren="No" />
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem
                                {...formItemLayout}
                                label="Furnished"
                                name='furnished'
                                valuePropName="checked"
                                >
                                    <Switch checkedChildren='Yes' unCheckedChildren="No" />
                                </FormItem>
                            </Col>
                        </Row>
                        <h3>Select Features</h3>
                        <Row>
                            <Col span={6}>
                                <FormItem
                                name='dishwasher'
                                valuePropName="checked"
                                >
                                    <Checkbox>Dishwasher</Checkbox>
                                </FormItem>
                                <FormItem
                                name='balcony'
                                valuePropName="checked"
                                >
                                    <Checkbox>Balcony</Checkbox>
                                </FormItem>
                                <FormItem
                                name='cable_ready'
                                valuePropName="checked"
                                >
                                    <Checkbox>Cable Ready</Checkbox>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                name='carpet'
                                valuePropName="checked"
                                >
                                    <Checkbox>Carpet</Checkbox>
                                </FormItem>
                                <FormItem
                                name='central_heating'
                                valuePropName="checked"
                                >
                                    <Checkbox>Central Heating</Checkbox>
                                </FormItem>
                                <FormItem
                                name='fireplace'
                                valuePropName="checked"
                                >
                                    <Checkbox>Fireplace</Checkbox>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                name='hardwood_floors'
                                valuePropName="checked"
                                >
                                    <Checkbox>Hardwood Floors</Checkbox>
                                </FormItem>
                                <FormItem
                                name='microwave'
                                valuePropName="checked"
                                >
                                    <Checkbox>Microwave</Checkbox>
                                </FormItem>
                                <FormItem
                                name='stainless_steel'
                                valuePropName="checked"
                                >
                                    <Checkbox>Stainless Steel Appliances</Checkbox>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem
                                name='handicap_accessable'
                                valuePropName="checked"
                                >
                                    <Checkbox>Handicap Accessable</Checkbox>
                                </FormItem>
                            </Col>
                        </Row>
                        <h3>Policies / Rules</h3>
                        <Row>
                            <Col span={12}>
                                <FormItem
                                {...formItemLayoutDuo}
                                label="Pets Policy"
                                name='pets_policy'
                                >
                                    <Select size="large" placeholder="Choose a pets policy">
                                    <Option value="No Pets Allowed">No Pets Allowed</Option>
                                    <Option value="Cats Only">Cats Only</Option>
                                    <Option value="Cats and Dogs Only">Cats and Dogs Only</Option>
                                    <Option value="Pets Allowed">Pets Allowed</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                {...formItemLayoutDuo}
                                label="Smoking Policy"
                                name='smoking_policy'
                                >
                                    <Select size="large" placeholder="Choose a smoking policy">
                                    <Option value="No Smoking Allowed">No Smoking Allowed</Option>
                                    <Option value="Smoking Allowed">Smoking Allowed</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                    </Card>
                    </Panel>
                </Collapse>
                <FormItem style={{float:'right', marginTop:'15px'}}>
                    <Button ghost type='primary' style={{marginRight:'8px'}} size='large' onClick={() => props.history.goBack()}>Cancel</Button>
                    <Button 
                    type='primary' 
                    size='large' 
                    htmlType='submit'
                    loading={loading}
                    style={{border:'none', backgroundColor: '#43c463'}}
                    >
                        Submit
                    </Button>
                </FormItem>
                </Col>
                </Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userID : state.user.authenticatedUser.sub,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(UnitCreate);