import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CreditCardOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Col, Button, Empty, Tag, Row, Popconfirm, message, Divider, Collapse } from 'antd';
import CardHeader from '../../../../components/CardHeader';

function confirm(e) {
  console.log(e);
  message.success('Click on Yes');
}

class BillingSettings extends React.Component {
  state = {
    stripeAccount: null,
    externalAccounts: null,
    loading: false,
  }

  componentDidMount() {
    this.setState({loading: true })
    axios.defaults.headers = {
      Authorization: 'Bearer ' + localStorage.token
    }
    axios.get(process.env.REACT_APP_CORE_API_DOMAIN + '/accounts/stripe/account/')
        .then(res => {
            const stripeAccount = res.data;
            const externalAccounts = res.data.external_accounts.data;
            console.log(stripeAccount);
            this.setState({
              stripeAccount: stripeAccount,
              externalAccounts: externalAccounts,
              loading: false,
            })
        })
        .catch(error => {
            // handle error
            console.log(error);
            this.setState({
              loading: false,
            })
        });
  }

    render () {

      const paymentCards = this.state.externalAccounts ? this.state.externalAccounts.filter(item => item.object === 'card') : [];

        return (
          <Col md={18} lg={20}
          style={{paddingLeft:'40px'}}
          >
            <CardHeader title='Billing' subtitle='Settings' description='Manage your billing statements' />
            <div style={{padding:'12px 0px'}}>
              <div>
                <span style={{fontSize:'16px', marginBottom:'4px', color:'rgba(0, 0, 0, 0.65)', fontWeight:'500'}}>
                Payment Methods
                </span>
                <Link to='/account/payment/card/create/' style={{float:'right'}}>
                  <Button type='primary' size='small' icon={<PlusOutlined />} style={{background:'#43c463', border:'none'}}>
                  Add Card
                  </Button>
                </Link>
              </div>
              <List
                size="small"
                style={{marginTop:'10px'}}
                loading={this.state.loading}                    
                bordered
                split={false}
                locale={{emptyText:<Empty image={<CreditCardOutlined style={{fontSize:'75px'}} />} description='You currently have no linked cards'/>}}
                dataSource={paymentCards}
                renderItem={item => (
                  <Collapse bordered={false}>
                  <Collapse.Panel header={
                    <List.Item actions={[
                    <Popconfirm title="Are you sure you want to remove this card?" placement='left' onConfirm={() => confirm(item.id)} okText="Yes" cancelText="No">
                    <a>Remove</a>
                    </Popconfirm>]}>
                    <List.Item.Meta
                    title={
                      <div>
                        <span style={{fontWeight:600}}>
                        {item.brand}
                        </span>
                        <Divider type='vertical'/>
                        <span style={{fontWeight:'400'}}>{item.funding.charAt(0).toUpperCase() + item.funding.slice(1)}</span>
                      </div>}
                    description={<div><span>Last 4 digits: &bull;&bull;&bull;&bull;</span><span>{item.last4}</span></div>}                    
                    />
                    </List.Item>}
                    >
                    <Col style={{paddingLeft:'40px'}}>
                      <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Currency: </span> {item.currency.toUpperCase()}</p>
                      <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Expires: </span> {item.exp_month}/{item.exp_year}</p>
                      <p><span style={{color:'rgba(0, 0, 0, 0.45)'}}>Country: </span> {item.country}</p>
                    </Col>
                  </Collapse.Panel>
                </Collapse>
                )}
              />
            </div>
          </Col>
        );
    }
}

export default BillingSettings;
