import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    accessToken: null,
    username: null,
    userSub: null,
    idToken: null,
    refreshToken: null,
    error : null,
    authenticatedUser : null,
    resetConfirm: null,
    passwordChange : null,
    userConfirmed : false,
    authLoading : false,
    isAuthenticated : false,
    profileFetching : false,
    accountType: null
}

const authStart = (state, action) => {
    return updateObject(state, {
        error : null,
        authLoading  : true
    });
}

const authRegistered = (state, action) => {
    return updateObject(state, {
        userSub : action.userSub,
        userConfirmed : action.userConfirmed,
        username: action.username,
        isAuthenticated : true,
        error : null,
        authLoading : false,
    });
}

const authConfirm = (state, action) => {
    return updateObject(state, {
        userConfirmed : true
    });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        userConfirmed : true,
        isAuthenticated : true,
        error : null,
        authLoading : false,
        authenticatedUser : action.authenticatedUser,
        accessToken: action.accessToken,
        idToken: action.idToken,
        refreshToken: action.refreshToken,
        accountType: action.accountType
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error : action.error,
        authLoading : false
    });
}

const authLogout = (state=initialState, action) => {
    return updateObject(state, {
        accessToken: null,
        idToken: null,
        refreshToken: null,
        username: null,
        userSub: null,
        error : null,
        authenticatedUser : null,
        resetConfirm: null,
        passwordChange : null,
        userConfirmed : false,
        authLoading : false,
        isAuthenticated : false,
        profileFetching : false,
    });
}

// Auth Password reset 
const authPasswordResetSuccess = (state, action) => {
    return updateObject(state, {
        resetConfirm : action.emailConfirm,
    });
}

// Auth Password reset 
const authPasswordChangeSuccess = (state, action) => {
    return updateObject(state, {
        passwordChange : true,
    });
}

// UPDATE User Profile
const updateUserProfileStart = (state, action) => {
    return updateObject(state, {
        error : null,
        updatingProfile: true,
    });
}

const updateUserProfileSuccess = (state, action) => {
    return updateObject(state, {
        authenticatedUser : action.userProfile,
        error: null,
        updatingProfile: false,
    });
}

const updateUserProfileFail = (state, action) => {
    return updateObject(state, {
        error : action.error,
        updatingProfile: false,
    });
}


const updateNotificationPreferencesStart = (state, action) => {
    return updateObject(state, {
        error : null,
        updatingProfile: true,
    });
}

const updateNotificationPreferencesSuccess = (state, action) => {
    return updateObject(state, {
        notificationPreferences : action.notificationPreferences,
        error: null,
        updatingProfile: false,
    });
}

const updateNotificationPreferencesFail = (state, action) => {
    return updateObject(state, {
        error : action.error,
        updatingProfile: false,
    });
}

// GET User Profile
const getUserProfileStart = (state, action) => {
    return updateObject(state, {
        error : null,
        profileFetching : true,
    });
}

const getUserProfileSuccess = (state, action) => {
    return updateObject(state, {
        authenticatedUser : action.userProfile,
        error: null,
        profileFetching : false,
    });
}

const getUserProfileFail = (state, action) => {
    return updateObject(state, {
        error : action.error,
        profileFetching : false,
    });
}

// GET User Profile
const getStripeTransfersStart = (state, action) => {
    return updateObject(state, {
        error : null,
        stripeTransfersFetching : true,
    });
}

const getStripeTransfersSuccess = (state, action) => {
    return updateObject(state, {
        stripeTransfers : action.stripeTransfers,
        error: null,
        stripeTransfersFetching : false,
    });
}

const getStripeTransfersFail = (state, action) => {
    return updateObject(state, {
        error : action.error,
        stripeTransfersFetching : false,
    });
}

export default function auth(state=initialState, action) {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_REGISTERED: return authRegistered(state, action);
        case actionTypes.AUTH_CONFIRM: return authConfirm(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);

        case actionTypes.AUTH_PASSWORD_RESET_SUCCESS: return authPasswordResetSuccess(state, action);
        case actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS: return authPasswordChangeSuccess(state, action);

        case actionTypes.UPDATE_USER_PROFILE_START: return updateUserProfileStart(state, action);
        case actionTypes.UPDATE_USER_PROFILE_SUCCESS: return updateUserProfileSuccess(state, action);
        case actionTypes.UPDATE_USER_PROFILE_FAIL: return updateUserProfileFail(state, action);

        case actionTypes.UPDATE_NOTIFICATION_PREFERENCES_START: return updateNotificationPreferencesStart(state, action);
        case actionTypes.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS: return updateNotificationPreferencesSuccess(state, action);
        case actionTypes.UPDATE_NOTIFICATION_PREFERENCES_FAIL: return updateNotificationPreferencesFail(state, action);

        case actionTypes.GET_USER_PROFILE_START: return getUserProfileStart(state, action);
        case actionTypes.GET_USER_PROFILE_SUCCESS: return getUserProfileSuccess(state, action);
        case actionTypes.GET_USER_PROFILE_FAIL: return getUserProfileFail(state, action);

        case actionTypes.GET_STRIPE_TRANSFERS_START: return getStripeTransfersStart(state, action);
        case actionTypes.GET_STRIPE_TRANSFERS_SUCCESS: return getStripeTransfersSuccess(state, action);
        case actionTypes.GET_STRIPE_TRANSFERS_FAIL: return getStripeTransfersFail(state, action);
        default:
            return state;
    }
}
