import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

import {
  CustomerServiceOutlined,
  LoadingOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Layout, Menu, Button, Avatar, Dropdown, Spin, Drawer } from 'antd';
import asset26 from '../assets/Asset 26.svg';
import asset5 from '../assets/Asset 5.svg';
import HelpMenu from '../components/HelpMenu';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

const investorNavBar = [
    // {
    //     'title': 'Dashboard',
    //     'icon': 'dashboard',
    //     'url': '/',
    //     'class': 'custom-navbar'
    // },
    {
        'title': 'Properties',
        'icon': 'home',
        'url': '/properties/',
        'class': 'custom-navbar'
    },
    {
      'title': 'Payments',
      'icon': 'dollar',
      'url': '/payments/',
      'class': 'custom-navbar'
    },
    {
      'title': 'Leases',
      'icon': 'file-protect',
      'url': '/leases/',
      'class': 'custom-navbar'
    },
    {   
        'type': 'submenu',
        'title': 'Tasks',
        'icon': 'carry-out',
        'class': 'custom-navbar',
        'items': [
            {'title': 'Maintenance',
            'url': '/tasks/maintenance/'},
            {'title': 'Todos',
            'url': '/tasks/todos/'},
        ]
    },
    {
        'title': 'Contacts',
        'icon': 'contacts',
        'url': '/contacts/',
        'class': 'custom-navbar',
    },
]

const residentNavBar = [
  {
      'title': 'Dashboard',
      'icon': 'dashboard',
      'url': '/',
      'class': 'custom-navbar'
  },
  {
    'title': 'Payments',
    'icon': 'dollar',
    'url': '/payments/',
    'class': 'custom-navbar'
  },
  {
    'title': 'Maintenance',
    'icon': 'file-protect',
    'url': '/tasks/maintenance/',
    'class': 'custom-navbar'
  },
  
]

class DashboardLayout extends React.Component {
  state = {
    collapsed: false,
    mobileLayout: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed: !collapsed });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let currentHideNav = (window.innerWidth <= 760);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({mobileLayout: currentHideNav});
    }
  }

  render() {
    const authenticatedUser = this.props.authenticatedUser;
    const page = this.props.location.pathname ? this.props.location.pathname : '/';

    const navBar = (items) => {
      return items.map((element, idx) => (
          element.type === 'submenu' ?
          <SubMenu
              key={idx}
              className={element.class}
              title={
              <span>
                <LegacyIcon type={element.icon}  style={{marginLeft: this.state.collapsed ? '0px' : '6px'}}/>
                <span style={{marginLeft:'10px'}}>{element.title}</span>
              </span>
              }
          >
              {element.items.map((item, index) => (
                  <Menu.Item key={item.url}>
                    <span>{item.title}</span>
                    <Link to={item.url}></Link>
                  </Menu.Item>
              ))}
          </SubMenu>
          :
          <Menu.Item key={element.url} className={element.class}>
            {page === element.url ?
            <span>
              <span 
              style={{width:'28px', height:'28px', display:'inline', backgroundColor:'#43c463', borderRadius:'50%', 
              position:'absolute', top:'6px', textAlign:'center', left:'25px' }}
              >
                <LegacyIcon type={element.icon} style={{marginRight:'0px',marginTop:'6px', verticalAlign:'top', lineHeight: '0px'}} />
              </span>
              <span style={{marginLeft:'40px', opacity: this.state.collapsed ? 0 : 1, transition: 'color 0.3s'}}>{element.title}</span>
            </span>
            :
            <span>
              <LegacyIcon type={element.icon}  style={{marginLeft: this.state.collapsed ? '0px' : '7px'}}/>
              <span style={{marginLeft:'9px'}}>{element.title}</span>
            </span>
            }
              <Link to={element.url}></Link>
          </Menu.Item>
      ));
    }

    const userMenu = (
        <Menu selectedKeys={[]}>
          <Menu.Item key="userCenter" style={{ padding:'0px 12px'}}>
            <Link to='/account/profile/' style={{ color:'inherit', display:'flex'}}>
                <Avatar 
                icon={<UserOutlined />} 
                //src={authenticatedUser.profile_picture ? authenticatedUser.profile_picture : undefined}
                size={50}
                />
                <div style={{padding:'0px 15px'}}>
                    <div style={{fontSize:'16px', fontWeight:'500'}}>{authenticatedUser.given_name} {authenticatedUser.family_name}</div>
                    <div style={{fontSize:'12px', color:'#7c98b6'}}>{authenticatedUser.email}</div>
                    <a style={{fontSize:'14px'}}>View Profile</a>
                </div>
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="userinfo">
            <Link to='/account/settings/profile/' style={{ color:'inherit'}}>
            <SettingOutlined style={{marginRight:'8px'}} />
            <span>Settings</span>
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="helpCenter">
            <a href='https://realtystack.io/support/start/' target='_blank' style={{ color:'inherit'}}>
            <ReadOutlined style={{marginRight:'8px'}} />
            <span>Help</span>
            </a>
          </Menu.Item>
          <Menu.Item key="FAQ">
            <a href='https://realtystack.io/support/faq/' target='_blank'  style={{ color:'inherit'}}>
            <QuestionCircleOutlined style={{marginRight:'8px'}} />
            <span>FAQs</span>
            </a>
          </Menu.Item>
          <Menu.Item key="supportRequest">
            <Link to='/account/support/request/' style={{ color:'inherit'}}>
            <CustomerServiceOutlined style={{marginRight:'8px'}} />
            <span>Support Request</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={this.props.logout} >
          <Link to='/login/' style={{color:'#43c463'}}> 
            <Button style={{ width: '100%', borderColor: '#43c463', backgroundColor:'#fff', textDecoration:'none' }}>
                <LogoutOutlined />
                <span style={{ marginLeft:'5px'}}>Logout</span>
            </Button>
          </Link>
          </Menu.Item>
        </Menu>
      );

    return this.props.authLoading ?
      <Spin indicator={loadingIcon} />
    :
    <Layout style={{ minHeight: '100vh'}} >
      {this.state.mobileLayout ?
      <div style={{position:'absolute', top:'20px'}}>
      <Button type="primary" onClick={() => this.setState({visible: true})}>
        <MenuOutlined />
      </Button>
      <Drawer
        title={
          <div
          style={{backgroundColor: '#002140'}}
          >
              <Link to='/' style={{margin:'0 12px'}}>
                  <img className="logo" alt="" src={asset5}
                  style={{ height:'32px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
                  />
                  <img className="logo" alt="RealtyStack" src={asset26}
                  style={{ 
                    marginLeft:'6px', 
                    paddingTop:'4px', 
                    height: '34px', 
                    marginTop:'15px', 
                    transition: 'all .2s ease .1s',
                    width: 'auto', 
                    opacity: 100
                  }} 
                  />
              </Link>
          </div>
        }
        placement="left"
        closable={true}
        maskClosable={true}
        onClose={() => this.setState({visible: false})}
        visible={this.state.visible}
        bodyStyle={{padding:'0px', backgroundColor:'#002140'}}
        headerStyle={{borderBottom:'none', color:'#fff'}}
      >
        <Menu theme="dark" defaultSelectedKeys={['0']} selectedKeys={[page]} mode="inline" style={{paddingTop:'30px', backgroundColor:'#002140'}}>
          {navBar(authenticatedUser.account_type !== 'Resident' ? investorNavBar : residentNavBar)}
        </Menu>
      </Drawer>
    </div>
      :
      <Sider 
      collapsible 
      collapsed={this.state.collapsed} 
      onCollapse={() => this.onCollapse(this.state.collapsed)}
      width={220}
      style={{
          flex: '0 0 220px',
          maxWidth: '220px',
          minWidth: '220px',
          width: '220px',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          backgroundColor: '#002140',
      }}
      >
        <div>
              <Link to='/' style={{margin:'0 12px'}}>
                  <img className="logo" alt="" src={asset5}
                  style={{ height:'32px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
                  />
                  <img className="logo" alt="RealtyStack" src={asset26}
                  style={{ 
                    marginLeft:'6px', 
                    paddingTop:'4px', 
                    height: this.state.collapsed ? '0px' : '34px', 
                    marginTop:'15px', 
                    transition: 'all .2s ease .1s',
                    width: this.state.collapsed ? '0px' : 'auto', 
                    opacity: this.state.collapsed ? 0 : 100
                  }} 
                  />
              </Link>
          </div>
        <Menu theme="dark" defaultSelectedKeys={['0']} selectedKeys={[page]} mode="inline" style={{paddingTop:'30px', backgroundColor:'#002140'}}>
          {navBar(authenticatedUser.account_type !== 'Resident' ? investorNavBar : residentNavBar)}
        </Menu>
      </Sider>
      }
      <Layout 
      style={{
        backgroundColor: '#f9fbfd', 
        marginLeft: this.state.mobileLayout ? 0 : (this.state.collapsed ? 80 : 220),
        transition: 'all .2s',
      }}
      >
          <Header style={{ background: '#fff', padding: 0, boxShadow:'rgba(115, 162, 208, 0.1) 0px 15px 20px -20px, rgba(115, 162, 208, 0.06) 0px 0px 15px 0px' }}>
          <LegacyIcon
            className="trigger"
            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={() => this.onCollapse(this.state.collapsed)}
            style={{ cursor: 'pointer', fontSize: '20px', padding:'0 24px' }}
          />
          <div key="4" style={{ float: 'right', padding:'0px', borderBottom:'none', color:'rgba(0, 0, 0, 0.65)' }}>
              <Dropdown 
              overlay={userMenu}
              style={{ verticalAlign: 'middle' }}
              placement="bottomRight"
              >
                  <div style={{ height: '100%', width: '100%', padding:'0 15px 0 5px' }}>
                  <Avatar style={{ backgroundColor: '#ff7a59', margin: '0px 10px' }}>
                      {String(authenticatedUser.given_name).charAt(0)}{String(authenticatedUser.family_name).charAt(0)}
                  </Avatar>
                  <span>{authenticatedUser.given_name} <strong>{authenticatedUser.family_name}</strong></span>
                  </div>
              </Dropdown>
          </div>
        </Header>
        <HelpMenu />
        <Content style={{ margin: '40px' }}>
          {this.props.children}
        </Content>
        <Footer style={{ textAlign: 'center', backgroundColor:'inherit' }}>RealtyStack ©2020</Footer>
      </Layout>
    </Layout>;
  }
}

const mapStateToProps = state => {
  return {
      authenticatedUser : state.user.authenticatedUser,
      authLoading: state.user.authLoading,
      isAuthenticated : state.user.isAuthenticated,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      logout: () => dispatch(actions.logout()),
      checkAuthToken: () => dispatch(actions.authCheckState())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));