import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader'
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';
import {
    BankOutlined,
    BellOutlined,
    CreditCardOutlined,
    LockOutlined,
    MailOutlined,
    SafetyCertificateOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { Card, Col, Menu, Row } from 'antd';
import { Link } from 'react-router-dom'
import ProfileSettings from './components/ProfileSettings';
import AccountSettings from './components/AccountSettings';
import EmailSettings from './components/EmailSettings';
import NotificationSettings from './components/NotificationSettings';
import BillingSettings from './components/BillingSettings';
import PaymentSettings from './components/PaymentSettings';
import ResidentPaymentSettings from './components/ResidentPaymentSettings';
import SubscriptionSettings from './components/SubscriptionSettings';
import SubscriptionView from './components/SubscriptionView';
import PasswordChange from './components/PasswordChange';
import CardHeader from '../../../components/CardHeader';
import BankPaymentCreate from './components/BankPaymentCreate';
import CardPaymentCreate from './components/CardPaymentCreate';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import coreApiClient from '../../../store/apiService';

// Create the Stripe object yourself...
const stripePromise = loadStripe('pk_test_xHNufqE5StZc6xlYIQKhxPtc');

const Settings= (props) => {
    const userProfile = props.authenticatedUser;
    const loadingUserProfile = props.profileFetching;
    const fetchUserProfile = props.fetchUserProfile;

    let page = props.location.pathname
    const subPage = () => {
        console.log(page)
        if(page === '/account/settings/profile/') {
            return <ProfileSettings {...props} userProfile={userProfile} loadingUserProfile={loadingUserProfile} fetchUserProfile={fetchUserProfile}  />
        } else if (page === '/account/settings/account/') {
            return <AccountSettings {...props} />
        } else if (page === '/account/settings/emails/') {
            return <EmailSettings {...props} userProfile={userProfile} loadingUserProfile={loadingUserProfile} fetchUserProfile={fetchUserProfile} />
        } else if (page === '/account/settings/notifications/') {
            return <NotificationSettings {...props} userProfile={userProfile} fetchUserProfile={fetchUserProfile} />
        } else if (page === '/account/settings/payments/') {
            if (userProfile.account_type === 'Resident') {
                return <ResidentPaymentSettings {...props} />
            } else {
                return <PaymentSettings {...props} />
            }
        } else if (page === '/account/settings/payments/bank/create/') {
            return <BankPaymentCreate {...props} />
        } else if (page === '/account/settings/payments/card/create/') {
            return <Elements stripe={stripePromise} ><CardPaymentCreate {...props} /></Elements>
        } else if (page === '/account/settings/billing/') {
            return <BillingSettings {...props} />
        } else if (page === '/account/settings/subscription/') {
            return <SubscriptionSettings {...props} />
        } else if (page === '/account/settings/subscription/change/') {
            return <SubscriptionView {...props} />
        } else if (page === '/account/settings/password/change/') {
            return <PasswordChange {...props} />
        } else {
            return <div style={{textAlign:'center', verticalAlign:'middle'}}>
                <CardHeader title='Error' subtitle='404' description='Looks like you got lost. Page not found.' />
                </div>
        }
    }

    return (
        <div>
            <PageHeader title='Settings' />
            <Card
            bordered={false}
            style={{ height:'100%'}}
            bodyStyle={{display:'inline-flex', width:'100%'}}
            >
                {userProfile.account_type !== 'Resident' ?
                <Menu
                defaultSelectedKeys={['1']}
                selectedKeys={[page]}
                mode='inline'
                theme='light'
                style={{ marginLeft:'-25px', height:'100%', minWidth:'180px'}}
                >
                    <Menu.Item key="/account/settings/profile/">
                        <UserOutlined />
                        <span>Profile</span>
                        <Link to='/account/settings/profile/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/account/">
                        <LockOutlined />
                        <span>Account</span>
                        <Link to='/account/settings/account/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/emails/">
                        <MailOutlined />
                        <span>Emails</span>
                        <Link to='/account/settings/emails/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/notifications/">
                        <BellOutlined />
                        <span>Notifications</span>
                        <Link to='/account/settings/notifications/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/payments/">
                        <BankOutlined />
                        <span>Payments</span>
                        <Link to='/account/settings/payments/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/billing/">
                        <CreditCardOutlined />
                        <span>Billing</span>
                        <Link to='/account/settings/billing/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/subscription/">
                        <SafetyCertificateOutlined />
                        <span>Subscription</span>
                        <Link to='/account/settings/subscription/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                </Menu>
                :
                <Menu
                defaultSelectedKeys={['1']}
                selectedKeys={[page]}
                mode='inline'
                theme='light'
                style={{ marginLeft:'-25px', height:'100%', minWidth:'180px'}}
                >
                    <Menu.Item key="/account/settings/profile/">
                        <UserOutlined />
                        <span>Profile</span>
                        <Link to='/account/settings/profile/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/account/">
                        <LockOutlined />
                        <span>Account</span>
                        <Link to='/account/settings/account/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/emails/">
                        <MailOutlined />
                        <span>Emails</span>
                        <Link to='/account/settings/emails/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/notifications/">
                        <BellOutlined />
                        <span>Notifications</span>
                        <Link to='/account/settings/notifications/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                    <Menu.Item key="/account/settings/payments/">
                        <BankOutlined />
                        <span>Payments</span>
                        <Link to='/account/settings/payments/' style={{color:'inherit'}}></Link>
                    </Menu.Item>
                </Menu>
                }
              {subPage()}
            </Card>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        authenticatedUser: state.user.authenticatedUser,
        profileFetching: state.user.profileFetching,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserProfile: () => dispatch(actions.getUserProfile()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);