import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/auth'
import { Form, Col, Input, Button, message } from 'antd';
import CardHeader from '../../../../components/CardHeader';
import { LockOutlined, MailOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const PasswordChange = (props) => {
      const [numberValidator, setNumberValidator] = useState(false)
      const [upperCaseValidator, setUpperCaseValidator] = useState(false)
      const [lowerCaseValidator, setLowerCaseValidator] = useState(false)
      const [lengthValidator, setLengthValidator] = useState(false)

      const [form] = Form.useForm();

      const handleSubmit = (values) => {
            console.log('Received values of form: ', values);
            props.passwordChange(values);
            if (props.passwordChange) {
              message.success('Password has been successfully changed')
              props.history.push('/account/settings/account/')
            }
      };

      const passwordValidate = (password) => {
        const lowerCase = RegExp(/(?=.*[a-z])/g);
        lowerCase.test(password) ? setLowerCaseValidator(true) : setLowerCaseValidator(false)
        const upperCase = RegExp(/(?=.*[A-Z])/g);
        upperCase.test(password) ? setUpperCaseValidator(true) : setUpperCaseValidator(false)
        const number = RegExp(/(?=.*\d)/g); 
        number.test(password) ? setNumberValidator(true) : setNumberValidator(false)
        password.length >= 8 ? setLengthValidator(true) : setLengthValidator(false)
      }

    return (
      <Col md={18} lg={20}
      style={{paddingLeft:'40px'}}
      >
          <CardHeader title='Password' subtitle='Change' description='Change the password for this account' />
          <Form onFinish={handleSubmit} layout='horizontal' form={form}>
            <div>
                <FormItem
                style={{maxWidth:'500px'}}
                label="Current Password"
                name="oldPassword"
                rules={[
                  { required: true, message: 'Please input your old password' },
                ]}
                >
                  <Input.Password  
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  type="password" 
                  placeholder="Password"
                  onChange={(e) => passwordValidate(e.target.value)} 
                  />
                </FormItem>
                <FormItem
                style={{maxWidth:'500px'}}
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: 'Please provide an password' },
                  { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, message: ' '}
                ]}
                hasFeedback
                >
                  <Input.Password  
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  type="password" 
                  placeholder="Password"
                  onChange={(e) => passwordValidate(e.target.value)} 
                  />
                </FormItem>
                {form.isFieldTouched('newPassword') && [lengthValidator, numberValidator, upperCaseValidator, lowerCaseValidator].indexOf(false) !== -1 ?
                <span style={{textAlign:'left', marginBottom:'5px'}}>
                    <p style={{color: lowerCaseValidator ? 'green' : 'red', marginBottom:'5px'}}>
                      {lowerCaseValidator ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
                      <span style={{marginLeft:'8px'}}>One lowercase letter</span>
                    </p> 
                    <p style={{color: upperCaseValidator ? 'green' : 'red', marginBottom:'5px'}}>
                      {upperCaseValidator ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
                      <span style={{marginLeft:'8px'}}>One uppercase letter</span>
                    </p> 
                    <p style={{color: numberValidator ? 'green' : 'red', marginBottom:'5px'}}>
                      {numberValidator ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
                      <span style={{marginLeft:'8px'}}>One number</span>
                    </p> 
                    <p style={{color: lengthValidator ? 'green' : 'red', marginBottom:'5px'}}>
                      {lengthValidator ? <CheckCircleOutlined/> : <CloseCircleOutlined/>}
                      <span style={{marginLeft:'8px'}}>Minimum 8 characters</span>
                    </p> 
                </span> 
                : null}
                <FormItem
                style={{maxWidth:'500px'}}
                label='Repeat new password'
                name="confirm"
                dependencies={['newPassword']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match');
                    },
                  }),
                ]}
                >
                  <Input.Password 
                  style={{maxWidth:'320px'}}
                  prefix={<LockOutlined 
                  style={{ color: 'rgba(0,0,0,.25)' }} />} 
                  placeholder='Repeat Password' 
                  type="password" 
                  />
                </FormItem>
            </div>
            <div style={{display:'inline-flex'}}>
                <FormItem style={{marginBottom:'0px', marginTop:'10px'}}>
                <Link to={'/account/settings/account/'}
                >
                <Button style={{marginRight:'15px'}}>
                Cancel
                </Button>
                </Link>
                </FormItem>
                <FormItem style={{marginBottom:'0px', marginTop:'10px'}}>
                <Button style={{background:'#43c463', border:'none'}} type="primary" htmlType="submit">
                Change Password
                </Button>
                </FormItem>
            </div>
          </Form>
      </Col>
    )
}


const mapStateToProps = state => {
  return {
      passwordChange : state.user.passwordChange,        
      authenticatedUser : state.user.authenticatedUser,        
  }
}

const mapDispatchToProps = dispatch => {
  return {
    passwordChange: (values) => dispatch(actions.authPasswordChange(values))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
