import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EllipsisOutlined,FileProtectOutlined ,TeamOutlined,DeleteOutlined } from '@ant-design/icons';
import { List, Avatar, Card, Empty, Tag, Input, Button, Skeleton, Dropdown, Menu, Modal, Col } from 'antd';
import { Link } from 'react-router-dom';
import CreateResidentModal from '../../createResident/CreateResidentModal';
import coreApiClient from '../../../../store/apiService';
import { formatPhoneNumber } from '../../../../store/utility';

const confirm = Modal.confirm;

export default function ResidentsList(props) {
  const [searchText, setSearchText] = useState(null);

  const residentsList = props.residentsList;
  const unitDetail = props.unitDetail;
  const loadingResidents = props.loadingResidents;
  const propertyId = props.match.params.propertyId;
  const unitId = unitDetail.id;

  const showDeleteConfirm = (residentId) => {
      confirm({
          title: 'Are you sure you want to delete this resident?',
          content: 'If deleted all information will permanent be removed.',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
              coreApiClient.residents.deleteDetail(propertyId, unitId, residentId)
              .then(res => {
                  if (res.status === 200) {
                      props.handleResidentEdit()
                  }
              })
              .catch(error => {
                  // handle error
                  console.log(error)
              });
          }, 
          onCancel() {
          console.log('Cancel');
          },
      });
  }

  const menu = (residentDetail) => (
    <Menu>
        <Menu.Item key="1">
            <CreateResidentModal 
            unitDetail={unitDetail} 
            {...props} 
            handleResidentEdit={props.handleResidentEdit} 
            buttonType={'edit'} 
            editResident={true}
            residentDetail={residentDetail}  
            />
        </Menu.Item>
        {residentDetail.lease ? 
        <Menu.Item key="3">
            <Link to={`/leases/${residentDetail.lease_id}/`}>
            <FileProtectOutlined style={{marginRight:'8px'}} />
                View Lease
            </Link>
        </Menu.Item> : null}
        <Menu.Item key="2">
            <a onClick={() => showDeleteConfirm(residentDetail.id)} style={{color:'#f5222d'}}>
                <DeleteOutlined style={{marginRight:'8px'}} />
                Delete
            </a>
        </Menu.Item>
    </Menu>
);

    return (
        <div>
            <div style={{display:'inline-flex', width:'100%'}}>
              <h2 style={{fontWeight:400, color: '#3c484f', whiteSpace: 'nowrap'}}>{`Residents (${residentsList.length})`}</h2>
              <div style={{marginLeft: '10px', paddingBottom:'10px', width:'100%', display: 'inline-flex'}}>
                  <Input.Search 
                  style={{width: '300px', background:'#e9ecef'}} 
                  size='default'
                  placeholder={`Search residents`}
                  onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                  />
                  <CreateResidentModal unitDetail={unitDetail} {...props} handleResidentEdit={props.handleResidentEdit}  />
              </div>
            </div>
            <Card
            bordered={false}
            hoverable
            >
                <List
                    itemLayout="horizontal"
                    dataSource={searchText ? residentsList.filter(resident => JSON.stringify(resident).toLowerCase().includes(searchText)) : residentsList}
                    locale={{ emptyText: <Empty description='No Residents' image={<TeamOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} /> }}
                    renderItem={item => (
                    <List.Item
                    >
                      <Skeleton loading={loadingResidents} active avatar={{'shape': 'circle'}} paragraph={{'rows': 1}}>
                        <List.Item.Meta
                        avatar={<Avatar>{item.first_name ? item.first_name.charAt(0) : ''}{item.last_name ? item.last_name.charAt(0) : ''}</Avatar>}
                        title={
                          <div style={{display: 'inline-flex'}}>
                            <CreateResidentModal 
                            unitDetail={unitDetail} 
                            {...props} 
                            handleResidentEdit={props.handleResidentEdit} 
                            buttonType={'link'} 
                            editResident={true}
                            residentDetail={item}  
                            />
                            <Tag
                            style={{
                              marginLeft:'15px', 
                              borderRadius:'10px', 
                              fontSize:'11px',
                              color:
                                  item.lease ? '#52c41a' : '#f5222d', //ffa39e
                              backgroundColor:
                                  item.lease ? '#f6ffed' : '#fff1f0', //ffa39e
                              border: 'none'
                              }}
                            >
                              <LegacyIcon style={{color:item.lease ? '#52c41a' : '#f5222d'}} type={item.lease ? 'check' : 'close'}/> Lease
                            </Tag>
                          </div>
                        }
                        description={<a href={`mailto:${item.email}`}>{item.email}</a>}
                        />
                        <div>
                            <Col style={{textAlign:'center', padding:'0px 70px'}}>
                                <span style={{fontSize:'18px', fontWeight:400}}>{item.phone ? formatPhoneNumber(item.phone) : '-'}</span>
                                <p style={{fontSize:'12.5px', fontWeight:300}}>PHONE</p>
                            </Col>
                        </div>
                      </Skeleton>
                      <Dropdown overlay={menu(item)} placement='bottomRight'>
                          <Button size='small' style={{ background:'#e9ecef', float: 'right'}}>
                              <EllipsisOutlined />
                          </Button>
                      </Dropdown>
                    </List.Item>
                    )}
                />
            </Card>
        </div>
    );
}
