import React from 'react'
import { Row } from 'antd'

export default function CardHeader(props) {
    return (
        <div>
            <Row style={{ display:'inline-flex', verticalAlign:'top'}}>
                <h1 style={{fontSize:'20px', fontWeight:'600', marginBottom:'0px', color:'#495057' }}>
                    {props.title}
                    {props.subtitle ? 
                        <span style={{ fontWeight:'300', marginLeft:'5px' }}>{props.subtitle}</span>
                        : null}
                </h1>
            </Row>
            <p style={{color:'#798892 !important', fontSize: '14px', fontWeight: 300}}>{props.description}</p>
        </div>
    )
}
