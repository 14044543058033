import React, {Component} from 'react';
import { Button, Card, Col, Form, message } from 'antd';
import {PlaidLink} from 'react-plaid-link'
import CardHeader from '../../../../components/CardHeader';
import paymentsApiClient from '../../../../store/apiService';

class BankPaymentCreate extends Component {
  state = {
    complete: false,
    accountToken: false,
    loading: false,
  }

  handleOnExit() {
    // handle the case when your user exits Link
    console.log('exit')
  }

  render() {
    const { cardData, token } = this.state;
    if (this.state.complete) return <h1>Purchase Complete</h1>;

    const handleOnSuccess = (token, metadata) => {
      // send token to client server
      console.log(token, metadata)
      this.setState({
        cardData: metadata,
        token: token, 
        accountToken: true,
      })
    }

    const {authenticatedUser} = this.props;

    const handleSubmit = (token, cardData) => {
      this.setState({loading: true})
      if (authenticatedUser.account_type === 'Resident') {
        let customerId = authenticatedUser['custom:stripe_customer_id'];
        paymentsApiClient.customers.createSource({
          public_token: token,
          account_id: cardData.account_id,
        }, customerId)
          .then(res => {
              const stripeSource = res.data;
              console.log(stripeSource);
              message.success('Successfully added account')
              this.setState({
                loading: false,
              })
              this.props.history.push('/account/settings/payments/')
          })
          .catch(error => {
              // handle error
              console.log(error);
              this.setState({
                loading: false,
              })
          });
      } else {
        paymentsApiClient.externalAccount.createDetail({
          public_token: token,
          account_id: cardData.account_id,
          bank_account_id: cardData.accounts[0].id
        })
          .then(res => {
              const stripeAccount = res.data;
              console.log(stripeAccount);
              message.success('Successfully added account')
              this.setState({
                loading: false,
              })
              this.props.history.push('/account/settings/payments/')
          })
          .catch(error => {
              // handle error
              console.log(error);
              this.setState({
                loading: false,
              })
          });
      }
    }

    return (
      <Col md={18} lg={20}
      style={{paddingLeft:'40px'}}
      >
        <CardHeader title='Add Bank' subtitle='Payments' description='Securely link a bank account for recieving funds' />
        <div className="checkout" style={{margin:'20px'}}>
          <div>
              {this.state.accountToken ? 
              <Card>
                <h2>New Account Review</h2>
                <p>RealtyStack uses plaid to securely verify your bank credentials</p>
                <Form.Item
                label='Institution'
                >
                  {cardData.institution.name}
                </Form.Item>
                <Form.Item
                label='Account'
                >
                  {cardData.account.type}
                </Form.Item>
                <Form.Item
                label='Account Type'
                >
                  {cardData.account.subtype}
                </Form.Item>
                <Form.Item
                label='Name'
                >
                  {cardData.account.name}
                </Form.Item>
                <Button type='primary' loading={this.state.loading} onClick={() => handleSubmit(token, cardData)}>Submit</Button>
              </Card>
              :
              <Card style={{marginTop:'15px'}}>
                  <h2>Connect your bank account to accept payments</h2>
                  <p>RealtyStack uses plaid to securely verify your bank credentials</p>
                  <PlaidLink
                  clientName="realtystack.io"
                  env="sandbox"
                  product={["auth", "transactions"]}
                  publicKey="635b49f2f2038f7ded3ab53bd9a319"
                  onExit={this.handleOnExit}
                  onSuccess={handleOnSuccess}
                  >
                      <Button size='large' style={{margin:'-8px'}} type='primary' loading={this.state.loading}>Connect your Bank</Button>
                  </PlaidLink>
              </Card>
              }
          </div>
        </div>
      </Col>
    );
  }
}

export default BankPaymentCreate;