import React, { useEffect, useState } from 'react'
import { Card, List, Checkbox, Empty, Tag, Skeleton, message } from 'antd'
import workflowApiClient from '../store/apiService';
import moment from 'moment';

export default function TodoCard(props) {
    const [taskList, setTaskList] = useState([]);
    const [loadingTaskList, setTaskListLoadingState] = useState(false);
    const [checkedTask, setCheckedTask] = useState(null);

    useEffect(() => {
        setTaskListLoadingState(true)
        workflowApiClient.todos.fetchList()
        .then(res => {
            console.log(res.data)
            setTaskList(res.data.data)
            setTaskListLoadingState(false)
        })
        .catch(error => {
            console.log(error)
            setTaskListLoadingState(false)
        })
    }, []);

    const fetchTaskList = () => {
        workflowApiClient.todos.fetchList()
        .then(res => {
            console.log(res.data)
            setTaskList(res.data.data)
        })
        .catch(error => {
            console.log(error)
        })
    }

    const updateTask = (cardDetails) => {
        workflowApiClient.todos.updateDetail({
            sort_key: cardDetails.sort_key,
            title: cardDetails.title,
            description: cardDetails.description,
            due_date: cardDetails.due_date,
            status: 'Completed',
        }, cardDetails.object_id)
        .then(res => {
            console.log(res.data)
            message.success('Task updated')
            setCheckedTask(cardDetails.sort_key)
            let newTaskList = taskList.filter(item => item.sort_key !== cardDetails.sort_key)
            setTaskList(newTaskList)
        })
        .catch(error => {
            message.success('Task could not be updated')
            console.log(error)
        })
    }

    console.log(taskList)

    return (
        <Card bordered={false} bodyStyle={{paddingLeft:'12px', paddingTop:'12px'}}>
             <Skeleton loading={loadingTaskList} active paragraph={{rows: 4 }} size='large'>
            <div>
                <span style={{fontSize: '18px'}}>Todo</span>
                <span style={{float: 'right'}}><Tag style={{borderRadius:'15px', backgroundColor:'#002140', color:'#fff', border: 'none'}}>{taskList.filter(item => item.status !== 'Completed').length}</Tag></span>
            </div>
            <List
            itemLayout="horizontal"
            dataSource={taskList.filter(item => item.status !== 'Completed').sort((a,b) => moment(b.due_date) - moment(a.due_date))}
            split={false}
            locale={{emptyText: <Empty description='No todos' />}}
            pagination={{
                pageSize:4,
                hideOnSinglePage: true,
                simple: true
            }}
            renderItem={item => (
                <List.Item style={{transition:'all 1s ease'}}>
                    <Checkbox 
                    value={item.sort_key} 
                    style={{marginRight: '12px'}} 
                    onChange={() => updateTask(item)} 
                    checked={item.sort_key === checkedTask}
                    />
                    <List.Item.Meta
                    title={<a>{item.title}</a>}
                    description={
                        <div>
                            <p style={{marginBottom:'5px'}}>{item.status}</p>
                            <span>{item.due_date ? `Due: ${item.due_date}` : null }</span>
                        </div>
                    }
                    />
                </List.Item>
            )}
            />
            </Skeleton>
        </Card>
    )
}
