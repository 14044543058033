import React, { useEffect } from 'react'
import PageHeader from '../components/PageHeader'
import { Row, Card, Col } from 'antd'
import StatCard from '../components/StatCard'
import CalendarCard from '../components/CalendarCard'
import TodoCard from '../components/TodoCard'
import MaintenanceCard from '../components/MaintenanceCard';
import OccupancyCard from '../components/OccupancyCard';

export default function Dashboard(props) {
    useEffect(() => {
        props.history.push('properties/')
    }, [])

    return (
        <div>
            <PageHeader title='Dashboard' breadCrumbs={false} />
            <Row style={{margin: '-20px -20px 0px -20px'}}>
                <Col span={12} md={6} style={{padding:'20px'}}>
                    <OccupancyCard />
                </Col>
                <Col span={12} md={6} style={{padding:'20px'}}>
                    <StatCard title={'Outstanding'} />
                </Col>
                <Col span={12} md={6} style={{padding:'20px'}}>
                    <StatCard title={'Applications'} />
                </Col>
                <Col span={12} md={6} style={{padding:'20px'}}>
                    <StatCard title={'Quick Links'} />
                </Col>
            </Row>
            <Row style={{margin: '-20px -20px 0px -20px'}}>
                <Col span={12} style={{padding:'20px'}}>
                    <StatCard title={'Recent Payments'} />
                </Col>
                <Col span={12} style={{padding:'20px'}}>
                    <StatCard title={'Listings'} />
                </Col>
            </Row>
            <Row style={{margin: '-20px -20px 0px -20px'}}>
                <Col span={8} style={{padding:'20px'}}>
                    <CalendarCard />
                </Col>
                <Col span={8} style={{padding:'20px'}}>
                    <TodoCard />
                </Col>
                <Col span={8} style={{padding:'20px'}}>
                    <StatCard title={'Leases'} />
                </Col>
            </Row>
        </div>
    )
}
