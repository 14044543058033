import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { List, Col, Modal, Button, Input, notification } from 'antd';
import CardHeader from '../../../../components/CardHeader';

const confirm = Modal.confirm;

class AccountSettings extends React.Component {
  state = {
    deletionIndicator : '',
  }

    render () {

    const deleteAccount = () => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            user.deleteUser(error => {
                delete axios.defaults.headers["Authorization"];
                localStorage.removeItem('user');
                localStorage.removeItem('accountType');
                localStorage.removeItem('expirationDate');
                localStorage.removeItem('token');
                this.props.history.push('/login/')
            });
            this.props.history.push('/login/')
        })
        .catch(this.onError);
    }

      const data = [
        {
          title: 'Account Type',
          description: this.props.authenticatedUser['custom:account_type'],
          action: '',
        },
        {
          title: 'Account Password',
          description: 'Change your current account password',
          action: <span><Link to={'/account/settings/password/change/'}>Change Password</Link></span>,
        },
        {
          title: <span style={{color:'red'}}>Delete Account</span>,
          description: 'Remove your account, this process is not reversible.',
          action: <span>
            <Button type='danger' onClick={() => showDeleteConfirm()}>
              Delete Account
            </Button>
            </span>,
        },
      ];

      const showDeleteConfirm = () => {
        var self = this;
        confirm({
          title: 'Are you sure you want to delete this account?',
          content: <div><p>This cannot be undone! This is your last chance before all of your data is removed.</p><p>Please type <strong>Delete</strong> into the text box to confirm deletion of the account</p><Input onChange={(e) => this.setState({deletionIndicator: e.target.value})}></Input></div>,
          okText: 'Delete Account',
          okType: 'danger',
          icon: <ExclamationCircleOutlined style={{color:'red', fontSize:'24px'}} />,
          cancelText: 'Cancel',
          onOk() {
            if (self.state.deletionIndicator === 'delete' || self.state.deletionIndicator === 'Delete') {
              deleteAccount()
            } else {
              notification.error({
                message:'Account Deletion',
                description: 'Please type "Delete" to delete the account. If you do not wish to procceed, click cancel',
              })
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

        return (
              <Col md={18} lg={20}
              style={{paddingLeft:'40px'}}
              >
                <CardHeader title='Account' subtitle='Settings' description='Manage your RealtyStack account' />
                <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    <List.Item actions={[item.action]}>
                        <List.Item.Meta
                        title={<span>{item.title}</span>}
                        description={item.description}
                        />
                    </List.Item>
                    )}
                />
              </Col>
        )
    }
}

const mapStateToProps = state => {
  return {
    authenticatedUser : state.user.authenticatedUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(actions.getUserProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
