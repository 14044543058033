import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

import rootReducer from './reducers/index';

const  reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ['user', 'configuration']
 };
 
const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, reduxDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);