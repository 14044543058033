import React, { useState, useEffect } from 'react';
import coreApiClient from '../../../store/apiService';
import PageHeader from '../../../components/PageHeader';
import { Link } from 'react-router-dom';
import {
    EditOutlined, EllipsisOutlined, EnvironmentOutlined, LoadingOutlined,
    PictureOutlined, PlusOutlined, DeleteOutlined
} from '@ant-design/icons';

import { Spin, Badge, Col, Row, Card, Button, Menu, Modal, Dropdown } from 'antd';
import ResidentsList from './components/ResidentsList';
import UnitDetails from './components/UnitDetails';
import Storage from '@aws-amplify/storage';

const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;
const confirm = Modal.confirm;

export default function UnitDetail(props) {

    const [unitDetail, setUnitDetail] = useState({});
    const [loadingUnits, setLoadingUnitsState] = useState(false);

    const [propertyDetail, setPropertyDetail] = useState({});
    const [loadingProperty, setLoadingPropertyState] = useState(false);

    const [residentsList, setResidentsList] = useState([]);
    const [loadingResidents, setLoadingResidentsState] = useState(false);

    const propertyId = props.match.params.propertyId;
    const unitId = props.match.params.unitId;

    useEffect(() => {
        setLoadingUnitsState(true)
        coreApiClient.units.fetchDetail(propertyId, unitId)
            .then(res => {
                console.log(res.data)
                let unit = res.data
                setUnitDetail(unit)
                setLoadingUnitsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingUnitsState(false)
            })
        
        setLoadingPropertyState(true)
        coreApiClient.properties.fetchDetail(propertyId)
            .then(res => {
                console.log(res.data)
                setPropertyDetail(res.data)
                setLoadingPropertyState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingPropertyState(false)
            })

        setLoadingResidentsState(true)
        coreApiClient.units.fetchUnitResidents(propertyId, unitId)
            .then(res => {
                console.log(res.data)
                setResidentsList(res.data.data)
                setLoadingResidentsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingResidentsState(false)
            })
    }, []);

    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure you want to delete this unit?',
            content: 'This unit can also be listed as inactive. If deleted all information will be removed.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                coreApiClient.units.deleteDetail(propertyId, unitId)
                .then(res => {
                    if (res.status === 200) {
                        props.history.push(`/properties/${propertyId}/`);
                    }
                })
                .catch(error => {
                    // handle error
                    console.log(error)
                });
            }, 
            onCancel() {
            console.log('Cancel');
            },
        });
    }

    const handleResidentEdit = () => {
        setLoadingResidentsState(true)
        coreApiClient.units.fetchUnitResidents(propertyId, unitId)
            .then(res => {
                console.log(res.data)
                setResidentsList(res.data.data)
                setLoadingResidentsState(false)
            })
            .catch(error => {
                console.log(error)
                setLoadingResidentsState(false)
            })
    }

    const menu = (
        <Menu>
            <Menu.Item key="2">
                <Link to={`/properties/${props.match.params.propertyId}/units/${props.match.params.unitId}/edit/`}>
                    <EditOutlined style={{marginRight:'8px'}} />Edit
                </Link>
            </Menu.Item>
            <Menu.Item key="3">
                <a onClick={() => showDeleteConfirm()} style={{color:'#f5222d'}}>
                    <DeleteOutlined style={{marginRight:'8px'}} />
                    Delete
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div>
            {loadingUnits ?
            <div style={{padding:'200px', textAlign:'center'}}><Spin indicator={loadingIcon} /></div> 
            :
            <div>
                <PageHeader 
                title={unitDetail.unit_name}
                subtitle={propertyDetail.property_name}
                breadCrumbs={[
                    {'title': 'Dashboard', 'url': '/'},
                    {'title': 'Properties', 'url': `/properties/`},
                    {'title': propertyDetail.property_name, 'url': `/properties/${propertyId}/`},
                    {'title': unitDetail.unit_name},
                ]}  
                />
                <Card
                hoverable
                bordered={false}
                style={{borderRadius:'8px'}}
                bodyStyle={{padding:'0px', height:'120px', display:'flex'}}
                >
                    {unitDetail.photo ? 
                        <div>
                            <img alt="Property Photo" 
                            style={{width: '200px', height:'120px', objectFit:'cover',borderRadius:'8px 0px 0px 8px'}} 
                            src={unitDetail.photo_url} 
                            /> 
                            <div style={{
                                position:'absolute',
                                height:'20px',
                                width: 'auto',
                                left: 0, 
                                top: 15, 
                                padding: '1px 8px', 
                                color: residentsList.length > 0 ? '#fff' : '#fff', 
                                background: residentsList.length > 0 ? '#52c41a' : '#f5222d',
                                fontSize: '12px'
                            }}>
                                <span>{residentsList.length > 0 ? 'Occupied' : 'Vacant'}</span>
                            </div>
                        </div>
                        :
                        <div>
                            <div 
                            style={{
                                height:'120px', 
                                width:'200px', 
                                backgroundColor:'#e9ecef', 
                                textAlign:'center', 
                                verticalAlign:'middle', 
                                paddingTop:'35px',
                                borderRadius:'8px 0px 0px 8px'
                            }}>
                                <span style={{width:'100%', height:'100%'}}>
                                    <PictureOutlined style={{color:'#999', fontSize:'50px'}} />
                                </span>
                            </div>
                            <div style={{
                                position:'absolute',
                                height:'20px',
                                width: 'auto',
                                left: 0, 
                                top: 15, 
                                padding: '1px 8px', 
                                color: residentsList.length > 0 ? '#fff' : '#fff', 
                                background: residentsList.length > 0 ? '#52c41a' : '#f5222d',
                                fontSize: '12px'
                            }}>
                                <span>{residentsList.length > 0 ? 'Occupied' : 'Vacant'}</span>
                            </div>
                        </div>
                    }
                    <Row style={{padding:'15px 20px', height:'100%', display:'flex', width:'100%'}} justify='space-between'>
                        <Col style={{height:'100%'}} md={20} lg={16} xl={14}>
                            <div>
                                <span style={{marginRight:'10px', fontWeight:400, textTransform:'uppercase', fontSize:'14px'}}>
                                    {unitDetail.unit_type}
                                </span>
                                <Badge 
                                    status={
                                        unitDetail.unit_status === 'Active' ? 'success' :
                                        unitDetail.unit_status === 'Listed' ? 'processing' :
                                        unitDetail.unit_status === 'Pending' ? 'warning' :
                                    'default'
                                    } 
                                    style={{paddingLeft:'5px'}}
                                />
                                <span 
                                style={{color:
                                    unitDetail.unit_status === 'Active' ? '#43c463' :
                                    unitDetail.unit_status === 'Listed' ? '#1890ff' :
                                    unitDetail.unit_status === 'Pending' ? '#faad14' :
                                    '#d9d9d9', 
                                }}>
                                    {unitDetail.unit_status}
                                </span>
                            </div>
                            <div>
                                <div style={{display:'inline-flex', fontWeight:300, color:'#a3aeb7', fontSize:'17.5px', marginTop:'10px'}}>
                                    <EnvironmentOutlined style={{marginTop:'12px', marginRight:'10px'}} />
                                    <div>
                                        <p style={{marginBottom:'0px'}}>{propertyDetail.address}</p>
                                        <p>{ propertyDetail.city + ', ' + propertyDetail.state + ' ' + propertyDetail.zip_code}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} xl={10}>
                            <Dropdown overlay={menu} placement='bottomRight'>
                                <Button size='default' style={{ background:'#e9ecef', float: 'right'}}>
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card>
                <Row style={{margin: '10px -15px 10px -15px'}}>
                    <Col md={24} lg={12} xl={10} style={{padding:'15px'}}>
                        <UnitDetails unitDetail={unitDetail} />
                    </Col>
                    <Col md={24} lg={12} xl={14} style={{padding:'15px'}} >
                        <ResidentsList 
                        {...props} 
                        residentsList={residentsList.sort((a, b) => a.first_name.localeCompare(b.first_name))} 
                        loadingResidents={loadingResidents} 
                        unitDetail={unitDetail}
                        handleResidentEdit={handleResidentEdit} 
                        />
                    </Col>
                </Row>
            </div>
            }
        </div>
    );
}
