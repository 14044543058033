import React, { useEffect, useState } from 'react';
import { LoadingOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Form, Spin, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/auth';
import {Card} from 'antd';
import queryString from 'query-string';
import asset26 from '../../assets/Asset 26.svg';
import asset5 from '../../assets/Asset 5.svg';
import Auth from '@aws-amplify/auth';

const FormItem = Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AccountConfirm = (props) => {
  const [confirmEmail, setConfirmEmail] = useState(undefined)
  const [initialConfirmEmail, setInitialConfirmEmail] = useState(undefined)

  useEffect(() => {
    let paramsString = props.location.search
    const parsed = queryString.parse(paramsString)
    const email = parsed['email'];
    setConfirmEmail(email)
    setInitialConfirmEmail(email)
  }, [])

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
      console.log('Received values of form: ', values);
      props.onConfirm(confirmEmail, values.code, values.password);
  }

  const resendConfirmationCode = () => {
    if (confirmEmail) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let valid = re.test(String(confirmEmail).toLowerCase());
      console.log(valid)
      if (valid) {
        Auth.resendSignUp(confirmEmail).then(() => {
          message.success('Code Resent Successfully', 10)
        }).catch(e => {
            console.log(e);
        });
      } else {
        setConfirmEmail(undefined)
        form.validateFields(['email'])
        message.error('Please provide account email to send code', 10)
      }
    }
  }

  if (props.isAuthenticated) {
    if (props.userConfirmed) {
      if (props.authenticatedUser.account_type) {
        return <Redirect to='/' />;
      } else {
        return <Redirect to='/account/profile/create/' />;
      }
    }
  }

  let errorMessage =null;
  if (props.error) {
    if (props.error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
        return <Redirect to='/login/' />;
    }
      errorMessage = (
          <p>{props.error.message}</p>
      );
  }

    return (
        <Col sm={20} md={12} lg={8}  style={{marginTop:'100px', textAlign:'center'}}>
          <div style={{marginBottom:'15px'}}>
              <a href='https://realtystack.io' style={{margin:'0 12px'}}>
                  <img className="logo" alt="" src={asset5}
                  style={{ height:'48px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
                  />
                  <img className="logo" alt="RealtyStack" src={asset26}
                  style={{ marginLeft:'8px', paddingTop:'4px', height:'48px', marginTop:'15px', transition: 'all .2s'}} 
                  />
              </a>
          </div>
          <Card>
              <span style={{color:'red'}}>{errorMessage}</span>
              {
                props.authLoading ?
                <Spin indicator={antIcon} />
                :
                <Form 
                onFinish={handleSubmit} 
                form={form}
                >
                    <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Confirm Account</h1>
                    <p>Please provide the code that was sent to your email</p>
                    <FormItem
                    name='code'
                    rules={[
                      { required: true, message: 'Please provide the code' },
                    ]}
                    >
                        <Input size='large' prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Code' />
                    </FormItem>
                    {
                      initialConfirmEmail
                      ? null :
                      <FormItem
                      name="email"
                      rules={[
                        { required: true, message: 'Please provide an email' },
                        { type: "email", message: 'Not a valid email address' }
                      ]}
                      >
                          <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" onChange={(e) => setConfirmEmail(e.target.value)} />
                      </FormItem>
                    }
                    <FormItem
                    name="password"
                    rules={[
                      { required: true, message: 'Please provide your password' },
                    ]}
                    >
                        <Input.Password size='large' prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder='Password' />
                    </FormItem>
                    <FormItem >
                        <Button size='large' ghost block type="primary" onClick={() => resendConfirmationCode()} style={{marginBottom:'8px'}}>
                            Resend Code
                        </Button>
                        <Button 
                        size='large' 
                        block 
                        style={{background:'#43c463', border:'none'}} 
                        type="primary" 
                        htmlType="submit" 
                        className="login-form-button"
                        //loading={props.authLoading}
                        >
                            Confirm
                        </Button>
                    </FormItem>
                </Form>
            }
          </Card>
          <Row justify='center' style={{padding:'20px'}}>
            <NavLink style={{marginRight: '10px', color:'white', borderBottom:'1px solid white'}} to='/login/'>Login</NavLink>
          </Row>
          </Col>
    );
}


const mapStateToProps = (state) => {
    return{
        authLoading: state.user.authLoading,
        error: state.user.error,
        userConfirmed: state.user.userConfirmed,
        userSub: state.user.userSub,
        isAuthenticated: state.user.isAuthenticated,
        authenticatedUser: state.user.authenticatedUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onConfirm: (username, code, password) => dispatch(actions.authConfirmRegister(username, code, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirm);