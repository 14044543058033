import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Input, Col, Select, Checkbox, Button, Form, Card, Row } from 'antd';
import { connect } from 'react-redux';
import { NavLink, Redirect, Link } from 'react-router-dom';
import * as actions from '../../store/actions/auth';
import asset26 from '../../assets/Asset 26.svg';
import asset5 from '../../assets/Asset 5.svg';

const FormItem = Form.Item;

const AccountPasswordResetConfirm = (props) => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    props.resetConfirm(values);
    setLoading(true)
  }

  if (props.isAuthenticated) {
    if (props.userConfirmed) {
      if (props.accountType) {
        return <Redirect to='/' />;
      } else {
        return <Redirect to='/account/profile/create/' />;
      }
    } else if (!props.userConfirmed) {
      let email = form.getFieldValue('email')
      return <Redirect to={`/signup/confirm/${email ? `?email=${email}` : ''}`} />;
    }
  }

  let errorMessage = null
  if (props.error) {
      if (props.error.code === 'UserNotConfirmedException') {
        return <Redirect to='/signup/confirm/' />;
      }
      errorMessage = (
          <p>
            {props.error.message ? 
            props.error.code === 'UserNotFoundException' ? 
            <span style={{color:'red'}}>Incorrect username or password.</span> : 
            <span style={{color:'red'}}>{props.error.message}</span> : 
            null}
          </p>
      );
  }

    return (
      <Col sm={20} md={12} lg={8}  style={{marginTop:'80px', textAlign:'center'}}>
        <Link to='/'>
        <div style={{marginBottom:'15px'}}>
          <a href='https://realtystack.io' style={{margin:'0 12px'}}>
              <img className="logo" alt="" src={asset5}
              style={{ height:'48px', width:'25px', marginTop:'13px', marginBottom:'6px', paddingTop:'4px', verticalAlign:'top', marginLeft:'15px'}} 
              />
              <img className="logo" alt="RealtyStack" src={asset26}
              style={{ marginLeft:'8px', paddingTop:'4px', height:'48px', marginTop:'15px', transition: 'all .2s'}} 
              />
          </a>
      </div>
        </Link>
        <Card>
          {errorMessage}
          <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Reset Password</h1>
          <Form onFinish={handleSubmit} layout='horizontal' form={form}>
              <FormItem
              name="email"
              rules={[
                { required: true, message: 'Please provide an email' },
                { type: "email", message: 'Not a valid email address' }
              ]}
              >
                  <Input size='large' prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
              </FormItem>
              <FormItem
              name="token"
              rules={[
                { required: true, message: 'Please provide the token from the email' },
              ]}
              >
                  <Input size='large' placeholder="Token" />
              </FormItem>
            <FormItem
            name="password"
            rules={[
              { required: true, message: 'Please provide an password' },
              { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, message: 'Password must meet the following: Minimum eight characters, at least one uppercase letter, one lowercase letter and one number' }
            ]}
            hasFeedback
            >
              <Input.Password size='large' prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
            </FormItem>
            <FormItem
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
            >
              <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Repeat Password' type="password" size='large' />
            </FormItem>
            <Button block size='large' loading={props.loading} style={{background:'#43c463', border:'none'}} type="primary" htmlType="submit">
                Reset Password
            </Button>
          </Form>
        </Card>
        <Row style={{padding:'20px'}} justify='center'>
          <span style={{color:'white'}}>Already have an account? </span>
          <NavLink style={{marginLeft: '10px', color:'white', borderBottom:'1px solid white'}} to='/login/'>Login</NavLink>
        </Row>
      </Col>
    );
}


const mapStateToProps = (state) => {
    return{
        loading: state.user.authLoading,
        error: state.user.error,
        authenticatedUser: state.user.authenticatedUser,
        isAuthenticated: state.user.isAuthenticated,
        userConfirmed: state.user.userConfirmed
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetConfirm: (values) => dispatch(actions.authPasswordResetConfirm(values))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountPasswordResetConfirm);
