import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button, Col, Row, Collapse } from 'antd';

const Panel = Collapse.Panel;

class ContactDetail extends React.Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {

    const contactDetail = this.props.contactDetail;

    return (
      <div style={{width:'100%'}}>
        <span style={{color:'#1890ff', cursor:'pointer'}} onClick={() => this.showDrawer()}>{contactDetail.name}</span>
        <Drawer
          title={<Row style={{background:'#ff7a59', padding:'18px'}}>
          <h3 style={{margin:'0px', color:'white'}}>Contact</h3>
          </Row>}
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name">
                  {contactDetail.name}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Type">
                  {contactDetail.type ? contactDetail.type : '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Email">
                {contactDetail.email ? contactDetail.email : '-'}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone">
                {contactDetail.phone ? contactDetail.phone : '-'}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Notes">
                {contactDetail.notes ? contactDetail.notes : '-'}
                </Form.Item>
              </Col>
            </Row>
            <Collapse 
            bordered={false}
            style={{backgroundColor:'#f9fbfd'}}
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined rotate={isActive ? 90 : 0} />}               
            >
              <Panel 
              header="Additional Info" 
              key="1" 
              style={{borderBottom:'none'}}>
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="Address">
                      {contactDetail.address ? contactDetail.address : '-'}
                      </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item label="City">
                      {contactDetail.city ? contactDetail.city : '-'}
                      </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="State">
                      {contactDetail.state ? contactDetail.state : '-'}
                      </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item label="Zip Code">
                      {contactDetail.zip_code ? contactDetail.zip_code : '-'}
                      </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="Url">
                      {contactDetail.url ? contactDetail.url : '-'}
                      </Form.Item>
                      </Col>
                  </Row>
              </Panel>
            </Collapse>
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button 
            onClick={this.onClose} 
            >
              Close
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}


export default ContactDetail;