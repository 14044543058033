import React from 'react'
import { CustomerServiceOutlined } from '@ant-design/icons';
import { Button, Card, List, Row, Popover } from 'antd';
import { Link } from 'react-router-dom'

const data = [
    {id: 1, title: 'Encountered a problem?', link: '/account/support/request/'},
    {id: 2,title: 'Have a suggestion?', link: '/account/support/request/'},
    {id: 3,title: 'Want to send a support request?', link: '/account/support/request/'},
    {id: 4,title: 'Need a guide?', link: '/support/help/', external: true},
    // {id: 5,title: 'RealtyStack Demo', link: '/dashboard/help/ticket/'},
];

export default function HelpMenu() {
    return (
        <div>
            <div style={{position:'fixed', bottom:'30px', right:'30px', zIndex:'1000', borderRadius:'20px', boxShadow:'0px 0px 28px -8px rgba(0,0,0,0.85)'}}>
                <Popover 
                placement="topRight" 
                trigger="click"
                title={
                    <Row style={{background:'linear-gradient(-303deg,#00a4bd,#00afb2 56%,#00bda5)', padding:'18px', margin:'-5px -16px -4px'}}>
                        <h3 style={{margin:'0px', color:'white'}}>Help</h3>
                    </Row>
                } 
                arrowPointAtCenter={true}
                style={{boxShadow:'0px 0px 28px -8px rgba(0,0,0,0.85)'}}
                content={
                    <div>
                        <h4>What can we help you with?</h4>
                        <List
                            grid={{
                            gutter: 3,
                            xs: 1,
                            }}
                            style={{width:'300px'}}
                            dataSource={data}
                            renderItem={item => (
                            <List.Item>
                                {item.external ?
                                <a href={`https://realtystack.io/${item.link}`} target='_blank' >
                                    <Card
                                    bodyStyle={{padding:'15px'}}
                                    className='help-card-list'
                                    >
                                    {item.title}
                                    </Card>
                                </a>
                                :
                                <Link to={item.link} >
                                    <Card
                                    bodyStyle={{padding:'15px'}}
                                    className='help-card-list'
                                    >
                                    {item.title}
                                    </Card>
                                </Link>
                                }
                            </List.Item>
                            )}
                        />
                    </div>
                }
                >
                    <Button type='primary' id='help-menu' style={{ borderRadius:'20px', borderColor:'#1890ff', backgroundColor:'#1890ff'}}>
                        <CustomerServiceOutlined style={{marginRight:'7px'}} />Help
                    </Button>
                </Popover>
            </div>
        </div>
    );
}
