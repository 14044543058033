import React, { useState } from 'react';
import { HomeOutlined, TeamOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Col, Select, Checkbox, Button, Radio, Card, Row, Form, Spin } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../../store/actions/auth';
import coreApiClient from '../../../store/apiService';
import {formatPhoneNumber} from '../../../store/utility';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ProfileForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [businessAccount, setBusinessAccount] = useState(false)

  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Received values of form: ', values);
    if(!values.phone.includes('+1')) {
        values['phone'] = '+1' + values.phone
    }
    setLoading(true)
    coreApiClient.profile.createDetail({
        "name": `${values.first_name} ${values.last_name}`,
        "phone": values.phone.replace(/[`~!@#$%^&*()_|\-=?;:'",.\s]/gi, ''),
        "account_type": values.account_type,
        "business_type": values.business_type ? 'company' : 'individual',
        "company": '',
        "address": {
            "city": values.city,
            "line1": values.address,
            "line2": values.address_line_2,
            "state": values.state,
            "postal_code": values.zip_code,
            "country": values.country,
        },
    })
    .then(res => {
        console.log(res)
        props.getProfile()
        setLoading(false)
    })
    .catch(error => {
        console.log(error);
        setLoading(false)
    })
  }

    if (props.isAuthenticated) {
        if (props.userConfirmed) {
          if (props.authenticatedUser['custom:account_type']) {
            return <Redirect to='/' />;
          } 
        }
    } else {
        return <Redirect to='/login/' />;
    }
    

    return (
    <Col sm={20} md={18} lg={16} xl={12} style={{margin:'20px 0px', textAlign:'center'}}>
        <Card>
        {loading ?
            <div>
                <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Create Profile</h1>
                <Spin indicator={antIcon} />
            </div>
        :
            <Form onFinish={handleSubmit} layout='horizontal' form={form}>
            <h1 style={{color: 'rgba(0, 0, 0, 0.45)'}}>Create Profile</h1>
              <FormItem
              name='account_type'
              rules={[
                { required: true, message: 'Please choose an account type' },
              ]}
              >
                <RadioGroup size='large'>
                    <Row>
                    <Col span={8}>
                        <Card 
                        hoverable 
                        style={{background: form.getFieldValue('account_type') === 'Investor' ? '#2196f34a' : '#fff',
                        border: form.getFieldValue('account_type') === 'Investor' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                        onClick={() => form.setFieldsValue({account_type: 'Investor'})}
                        >
                            <div><HomeOutlined style={{fontSize:'25px'}} /></div>
                            <p>Create and Manage your portfolio, team, and residents</p>
                            <Radio value='Investor'>Investor</Radio>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card 
                        hoverable 
                        style={{background: form.getFieldValue('account_type') === 'Landlord' ? '#2196f34a' : '#fff',
                        border: form.getFieldValue('account_type') === 'Landlord' ? '1px solid #1890ff' : '1px solid #e8e8e8',
                        height:'100%'}} 
                        onClick={() => form.setFieldsValue({account_type: 'Landlord'})}
                        >
                            <div><TeamOutlined style={{fontSize:'25px'}} /></div>
                            <p>Manage your a portfolio, team, and residents</p>
                            <Radio value='Landlord'>Landlord</Radio>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card 
                        hoverable 
                        style={{background: form.getFieldValue('account_type') === 'Resident' ? '#2196f34a' : '#fff',
                        border: form.getFieldValue('account_type') === 'Resident' ? '1px solid #1890ff' : '1px solid #e8e8e8'}} 
                        onClick={() => form.setFieldsValue({account_type: 'Resident'})}
                        >
                            <div><UserOutlined style={{fontSize:'25px'}} /></div>
                            <p>Pay rent online and submit maintenance requests</p>
                            <Radio value='Resident'>Resident</Radio>
                        </Card>
                    </Col>
                    </Row>
                </RadioGroup>
              </FormItem>
              <Row>
                  <Col span={12} style={{paddingRight:'15px'}}>
                      <FormItem
                      name='first_name'
                      rules={[{ required: true, message: 'Please input your First Name' },]}
                      >
                          <Input size='large' placeholder='First Name'/>
                      </FormItem>
                  </Col>
                  <Col span={12}>
                      <FormItem
                      name='last_name'
                      rules={[{ required: true, message: 'Please input your Last Name' },]}
                      >
                          <Input size='large' placeholder='Last Name' />
                      </FormItem>
                  </Col>
              </Row>
              <Row>
                  <Col span={16} style={{paddingRight:'15px', display:'inline-flex'}}>
                      <FormItem
                      name='business_type'
                      valuePropName='checked'
                      >
                          <Checkbox size='large' defaultValue={false} checked={businessAccount} onChange={() => setBusinessAccount(!businessAccount)} style={{color: 'rgba(0, 0, 0, 0.45)'}}>Business Account?</Checkbox>
                      </FormItem>
                      <FormItem
                      style={{width:'inherit'}}
                      name='company'
                      >
                          <Input size='large' placeholder='Company Name' disabled={form.getFieldValue('business_type') === false || form.getFieldValue('business_type') === undefined} />
                      </FormItem>
                  </Col>
                  <Col span={8}>
                      <FormItem
                      name='phone'
                      rules={[
                          { required: true, message: 'Please input your phone number'},
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (formatPhoneNumber(value)) {
                                form.setFieldsValue({'phone': formatPhoneNumber(value)})
                                return Promise.resolve();
                              }
                              return Promise.reject('Invalid phone number');
                            },
                          }),]}
                      >
                          <Input size='large' placeholder='Phone Number' />
                      </FormItem>
                  </Col>
              </Row>
              <Row>
                <Col span={12} style={{paddingRight:'15px'}}>
                    <FormItem
                    name='address'
                    rules={[{ required: true, message: 'Please input your address' },]}
                    >
                        <Input size='large' placeholder='Address Line One' />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                    name='address_line_2'
                    >
                        <Input size='large' placeholder='Address Line Two' />
                    </FormItem>
                </Col>
              </Row>
              <Row>
                  <Col span={12} style={{paddingRight:'15px'}}>
                      <FormItem
                      name='city'
                      rules={[{ required: true, message: 'Please input your city' },]}
                      >
                          <Input size='large' placeholder='City' />
                      </FormItem>
                      <FormItem
                      name='zip_code'
                      rules={[{ required: true, message: 'Please input your zip code' },]}
                      >
                          <Input size='large' placeholder='Zip Code' />
                      </FormItem>
                  </Col>
                  <Col span={12}>
                      <FormItem
                      name='state'
                      rules={[{ required: true, message: 'Please input your state' },]}
                      >
                          <Input size='large' placeholder='State' />
                      </FormItem>
                      <FormItem
                      name='country'
                      rules={[{ required: true, message: 'Please input your country' },]}
                      >
                        <Input size='large' placeholder='Country' />
                      </FormItem>
                  </Col>
              </Row>
              <FormItem style={{marginBottom:'0px'}}>
                <Button block loading={props.updatingProfile} size='large' style={{background:'#43c463', border:'none'}} type="primary" htmlType="submit">
                Create
                </Button>
              </FormItem>
            </Form>}
          </Card>
        </Col>
    );
}

const mapStateToProps = (state) => {
    return{
        authenticatedUser: state.user.authenticatedUser,
        userConfirmed: state.user.userConfirmed,
        error: state.error,
        isAuthenticated: state.user.isAuthenticated,
        updatingProfile: state.user.updatingProfile,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfile: () => dispatch(actions.getUserProfile())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
