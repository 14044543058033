import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import BaseRouter from './routes/routes';
import Amplify from '@aws-amplify/core';
import './App.css';
import 'antd/dist/antd.css';

// window.LOG_LEVEL = 'DEBUG'

Amplify.configure({
    Analytics: {
        disabled: true,
    },
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:8c9ac91b-1360-478a-9e2a-99620462ad5d',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_lOPysB0Ks',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '261g2gpfprtan5u6mvo92auomh',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH'
    },
    Storage : {
      AWSS3 : {
        bucket: 'realtystack-bucket',
        region: 'us-east-1',
      }
    }
});

const App = (props) => {
  useEffect(() => {
    props.checkAuthToken();
  })

  return (
    <div className="App">
      <Router>
        <BaseRouter />
      </Router>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token != null,
    authenticatedUser: state.user.authenticatedUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkAuthToken: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);