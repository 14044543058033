import axios from 'axios';
import * as actionTypes from './actionTypes';
import Auth from '@aws-amplify/auth';
import coreApiClient from '../apiService';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (accessToken, idToken, refreshToken, user, accountType) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        accessToken: accessToken,
        idToken: idToken,
        refreshToken: refreshToken,
        authenticatedUser: user,
        accountType: accountType
    }
}

export const authRegistered = (userSub, username, userConfirmed) => {
    return {
        type: actionTypes.AUTH_REGISTERED,
        userSub: userSub,
        username: username, 
        userConfirmed: userConfirmed
    }
}

export const authConfirm = () => {
    return {
        type: actionTypes.AUTH_CONFIRM,
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const authLogin = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        Auth.signIn(email.toLowerCase(), password)
        .then(res => {
            console.log(res)
            const user = res.attributes;

            user['phone'] = user['phone_number']
            user['plan'] = user['custom:plan']
            user['account_type'] = user['custom:account_type']
            user['account_subscription'] = user['custom:account_subscription']
            user['first_name'] = user['given_name']
            user['last_name'] =  user['family_name']
            user['business_type'] = user['custom:business_type']

            user['company'] = user['custom:company']
            user['city'] = user['custom:city']
            user['state'] = user['custom:state']
            user['zip_code'] = user['custom:zip_code']
            user['country'] = user['custom:country']
            user['resident_messages'] = user['custom:resident_messages']
            user['maintenance_requests'] = user['custom:maintenance_requests']
            user['new_applications'] = user['custom:new_applications']
            user['lease_reminder'] = user['custom:lease_reminder']
            user['realtystack_updates'] = user['custom:realtystack_updates']

            const accessToken = res.signInUserSession.accessToken.jwtToken;
            const idToken = res.signInUserSession.idToken.jwtToken;
            const refreshToken = res.signInUserSession.refreshToken.token;
            const sessionExpirationDate = new Date(new Date().getTime() + 86400 * 1000); //3600sec in a min, 86400sec in a day, time is in milliseconds
            const expirationDate = new Date(res.signInUserSession.idToken.payload.exp*1000)
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('username', res.username);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('sessionExpirationDate', sessionExpirationDate);
            const accountType = user['custom:account_type']
            if (accountType) { dispatch(refreshTokens()) };
            dispatch(authSuccess(accessToken, idToken, refreshToken, user, accountType));
        })
        .catch(error => {
            console.log(error)
            if (error.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (error.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (error.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
            } else if (error.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(error);
            }
            dispatch(authFail(error));
        });
    }
}

export const authRegister = (values) => {
    return dispatch => {
        dispatch(authStart());
        Auth.signUp({
          username: values.email.toLowerCase(),
          password: values.password,
          attributes: {
            email: values.email,
          },
        })
        .then(res => {
            console.log(res)
            const userSub = res.userSub
            const username = res.username
            const userConfirmed = res.userConfirmed
            dispatch(authRegistered(userSub, username, userConfirmed));
        })
        .catch(error => { 
            console.log(error)
            dispatch(authFail(error));
        });
    }
}

export const authConfirmRegister = (email, code, password) => {
    return dispatch => {
        // After retrieveing the confirmation code from the user
        dispatch(authStart());
        Auth.confirmSignUp(email, code)
        .then(res => {
            console.log(res)
            dispatch(authLogin(email, password, true));
        })
        .catch(error => { 
            console.log(error)
            dispatch(authFail(error));
        });
    }
}

export const logout = () => {
    Auth.signOut()
    .then(res => {
        delete axios.defaults.headers["Authorization"];
        localStorage.removeItem('user');
        localStorage.removeItem('accountType');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('username');

    })
    .catch(err => {
        console.log(err)
        delete axios.defaults.headers["Authorization"];
        localStorage.removeItem('user');
        localStorage.removeItem('accountType');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('username');
    });
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const refreshTokens = () => {
    return dispatch => {
        Auth.currentCredentials()
        .then(res => {
            console.log(res)
            const identityId = res.identityId;
            localStorage.setItem('identityId', identityId);
        })
        .catch(err => {
            console.log(err)
            dispatch(logout());
        });
        Auth.currentSession()
        .then(res => {
            console.log(res)
            const accessToken = res.accessToken.jwtToken;
            const idToken = res.idToken.jwtToken;
            const expirationDate = new Date(res.idToken.payload.exp*1000)
            const refreshToken = res.refreshToken.token;

            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('refreshToken', refreshToken);
            console.log((expirationDate.getTime() - new Date().getTime()) * 1000)
            let iteration = setTimeout(() => dispatch(refreshTokens()), 3600 * 1000)
            console.log('iteration', iteration)
        })
        .catch(err => {
            console.log(err)
            dispatch(logout());
        });
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('idToken');

        if (token === undefined) {
            dispatch(logout());
            console.log('undefined')
        } else {
            const sessionExpirationDate = new Date(localStorage.getItem('sessionExpirationDate'))
            if ( sessionExpirationDate <= new Date() ) {
                dispatch(logout());
                console.log('expired')
            } else {
                if (new Date(localStorage.getItem('expirationDate')) <= new Date() ) {
                    dispatch(refreshTokens());
                    console.log('refresh')
                }
                dispatch(checkAuthTimeout( (sessionExpirationDate.getTime() - new Date().getTime()) / 1000))
                console.log('success')
            }
        }
    }
}

export const authSocialLogin = (email, password) => {
    return dispatch => {
        Auth.federatedSignIn({provider: 'Facebook'})
    }
}


export const updateUserProfileStart = () => {
    return {
        type: actionTypes.UPDATE_USER_PROFILE_START
    }
}

export const updateUserProfileSuccess = (userProfile) => {
    return {
        type: actionTypes.UPDATE_USER_PROFILE_SUCCESS,
        userProfile: userProfile
    }
}

export const updateUserProfileFail = error => {
    return {
        type: actionTypes.UPDATE_USER_PROFILE_FAIL,
        error: error
    }
}

export const updateUserProfile = (values) => {
    return dispatch => {
        dispatch(updateUserProfileStart());
        Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.updateUserAttributes(user, {
                'custom:account_type': values.account_type,
                'custom:account_subscription': 'Free',
                'picture': values.photo ? (values.photo.includes('https://') ? values.photo : 'None') : 'None',
                'given_name': values.first_name,
                'family_name': values.last_name,
                'name': `${values.first_name} ${values.last_name}`,
                'custom:business_type': values.business_type ? 'Business' : 'Individual',
                'custom:company': values.company !== undefined ? values.company : 'None',
                'phone_number': '+' + values.phone.replace(/[.*+?^${}()|[\]\\]/g, '').replace(/[.*+?^${}()|[\]\\]/g, '').replace(/ /g, '').replace(/-/g,'').replace(/<.-+?>/, ''),
                'address': values.address,
                'custom:city': values.city,
                'custom:state': values.state,
                'custom:zip_code': values.zip_code,
                'custom:country': values.country,
                'custom:stripe_customer_id': values.stripe_customer_id,
                'custom:stripe_created_on': String(values.stripe_created_on)
            })
            .then(data => {
                console.log(data)
                dispatch(updateUserProfileSuccess());

                // coreApiClient.customers.createDetail({
                //     "email": ,
                //     "first_name": ,
                //     "last_name": ,
                // })

                Auth.currentAuthenticatedUser({
                    bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
                })
                .then(res => {
                    console.log(res)
                    const user = res.attributes;

                    user['phone'] = user['phone_number']
                    user['plan'] = user['custom:plan']
                    user['account_type'] = user['custom:account_type']
                    user['account_subscription'] = user['custom:account_subscription']
                    user['first_name'] = user['given_name']
                    user['last_name'] =  user['family_name']
                    user['business_type'] = user['custom:business_type']

                    user['company'] = user['custom:company']
                    user['city'] = user['custom:city']
                    user['state'] = user['custom:state']
                    user['zip_code'] = user['custom:zip_code']
                    user['country'] = user['custom:country']
                    user['resident_messages'] = user['custom:resident_messages']
                    user['maintenance_requests'] = user['custom:maintenance_requests']
                    user['new_applications'] = user['custom:new_applications']
                    user['lease_reminder'] = user['custom:lease_reminder']
                    user['realtystack_updates'] = user['custom:realtystack_updates']

                    const accessToken = res.signInUserSession.accessToken.jwtToken;
                    const idToken = res.signInUserSession.idToken.jwtToken;
                    const refreshToken = res.signInUserSession.refreshToken.token;
                    const expirationDate = new Date(new Date().getTime() + 86400 * 1000); //3600sec in a min, 86400sec in a day, time is in milliseconds
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('idToken', idToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('username', res.username);
                    localStorage.setItem('expirationDate', expirationDate);
                    const accountType = user['custom:account_type']

                    dispatch(authSuccess(accessToken, idToken, refreshToken, user, accountType));
                })
                .catch(err => {
                    console.log(err)
                    dispatch(updateUserProfileFail());
                });
            })
            .catch(err => {
                console.log(err)
                dispatch(updateUserProfileFail());
            });
        })
        .catch(err => {
            console.log(err)
            dispatch(updateUserProfileFail());
        });
    }
}


export const getUserProfileStart = () => {
    return {
        type: actionTypes.GET_USER_PROFILE_START
    }
}

export const getUserProfileSuccess = (userProfile) => {
    return {
        type: actionTypes.GET_USER_PROFILE_SUCCESS,
        userProfile: userProfile
    }
}

export const getUserProfileFail = error => {
    return {
        type: actionTypes.GET_USER_PROFILE_FAIL,
        error: error
    }
}

export const getUserProfile = () => {
    return dispatch => {
        dispatch(getUserProfileStart());
        coreApiClient.profile.fetchDetail()
        .then(res => {
            console.log(res.data)
            dispatch(getUserProfileSuccess(res.data));
        })
        .catch(error => {
            console.log(error)
            dispatch(getUserProfileFail(error))
        })
    }
}


// Auth Password reset
export const authPasswordResetSuccess = (emailConfirm) => {
    return {
        type: actionTypes.AUTH_PASSWORD_RESET_SUCCESS,
        emailConfirm: emailConfirm
    }
}

export const authPasswordReset = (email) => {
    return dispatch => {
        Auth.forgotPassword(email)
        .then(res => {
            // handle success
            console.log(res);
            const emailConfirm = res.data;
            dispatch(authPasswordResetSuccess(emailConfirm));
        })
        .catch(error => {
            // handle error
            dispatch(authFail(error))
        })
    }
}

export const authPasswordResetConfirm = (values) => {
    return dispatch => {
        Auth.forgotPasswordSubmit(values.email, values.token, values.password)
        .then(res => {
            // handle success
            console.log(res);
            dispatch(authPasswordResetSuccess())
        })
        .catch(error => {
            // handle error
            dispatch(authFail(error))
        })
    }
}

// Auth Password Change
export const authPasswordChangeSuccess = (passwordChange) => {
    return {
        type: actionTypes.AUTH_PASSWORD_CHANGE_SUCCESS,
        passwordChange: true,
    }
}

export const authPasswordChange = (values) => {
    return dispatch => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.changePassword(user, values.oldPassword, values.newPassword)
            .then(res => {
                // handle success
                console.log(res.data);
                const passwordChange = res.data;
                dispatch(authPasswordChangeSuccess(passwordChange));
            })
            .catch(error => {
                // handle error
                dispatch(authFail(error))
            })
        })
    }
}

export const updateNotificationPreferencesStart = () => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_PREFERENCES_START
    }
}

export const updateNotificationPreferencesSuccess = (notificationPreferences) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
        notificationPreferences: notificationPreferences
    }
}

export const updateNotificationPreferencesFail = error => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_PREFERENCES_FAIL,
        error: error
    }
}

export const updateNotificationPreferences = (values) => {
    return dispatch => {
        dispatch(updateNotificationPreferencesStart());
        Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.updateUserAttributes(user, {
                'custom:resident_messages': values.resident_messages,
                'custom:maintenance_requests': values.maintenance_requests,
                'custom:new_applications': values.new_applications,
                'custom:lease_reminder': values.lease_reminder,
                'custom:realtystack_updates': values.realtystack_updates,
            })
            .then(res => {
                console.log(res)
                const notificationPreferences = {
                    'custom:resident_messages': values.resident_messages,
                    'custom:maintenance_requests': values.maintenance_requests,
                    'custom:new_applications': values.new_applications,
                    'custom:lease_reminder': values.lease_reminder,
                    'custom:realtystack_updates': values.realtystack_updates,
                };
                dispatch(updateNotificationPreferencesSuccess(notificationPreferences));
            })
            .catch(err => {
                console.log(err)
                dispatch(updateNotificationPreferencesFail());
            });
        })
        .catch(err => console.log(err));
    }
}