import React, { useState, useEffect } from 'react';
import { Steps, Button, message, Row, Col, Form, Spin, Empty } from 'antd';
import LeaseCreateStepOne from './components/LeaseCreateStepOne';
import LeaseCreateStepTwo from './components/LeaseCreateStepTwo';
import LeaseCreateStepThree from './components/LeaseCreateStepThree';
// import {Elements} from 'react-stripe-elements';
import LeaseCreateReview from './components/LeaseCreateReview';
import coreApiClient from '../../../store/apiService';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

const Step = Steps.Step;

const CreateLease = (props) => { 
  const [current, setCurrent] = useState(0)   
  const [propertiesList, setPropertiesList] = useState([]);
  const [fields, setFields] = useState([]);
  const [loadingPropertiesList, setLoadingPropertiesList] = useState(false);
  const [propertyUnits, setPropertyUnits] = useState([]);
  const [loadingUnits, setLoadingUnitsState] = useState(false);

  const [stripeAccount, setStripeAccount] = useState(false);
  const [loadingStripeAccount, setLoadingStripeAccount] = useState(false);

  const [residentsList, setResidentsList] = useState([]);
  const [loadingResidents, setLoadingResidentsState] = useState(false);
  const [creatingLease, setCreatingLease] = useState(false);
  const [stepOneFields, setStepOneFields] = useState([
    {name: ['property'], value: ''},
    {name: ['unit'], value: ''},
    {name: ['residents'], value: []},
  ]);
  const [stepTwoFields, setStepTwoFields] = useState([
    {name: ['start_date'], value: ''},
    {name: ['end_date'], value: ''},
    {name: ['lease_type'], value: ''},
    {name: ['amount'], value: ''},
    {name: ['monthly_due_date'], value: ''},
  ]);
  const [stepThreeFields, setStepThreeFields] = useState([
    {name: ['destination_account'], value: ''},
  ]);

  console.log(stepOneFields)
  console.log(stepTwoFields)
  console.log(stepThreeFields)

  useEffect(() => {
    setLoadingStripeAccount(true)
    coreApiClient.account.fetchDetail(props.authenticatedUser['custom:stripe_account_id'])
    .then(res => {
        const stripeAccount = res.data;
        const enabledAccount = stripeAccount.capabilities.card_payments ? true : stripeAccount.capabilities.transfers;
        console.log(stripeAccount);
        setStripeAccount(enabledAccount)
        setLoadingStripeAccount(false)
    })
    .catch(error => {
        // handle error
        console.log(error);
        setLoadingStripeAccount(false)
    });

    setLoadingPropertiesList(true)
    coreApiClient.properties.fetchList()
    .then(res => {
        let propertyList = res.data.data;
        setLoadingPropertiesList(false)
        setPropertiesList(propertyList)
    })
    .catch(error => {
        console.log(error)
        setLoadingPropertiesList(false)
    })
  }, []);

  const fetchUnits = (propertyId) => {
    setLoadingUnitsState(true)
    coreApiClient.properties.fetchUnits(propertyId)
        .then(res => {
            let propertyUnits = res.data.data;
            setPropertyUnits(propertyUnits)
            setLoadingUnitsState(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingUnitsState(false)
        })
  }

  const fetchResidents = (unitId, propertyId) => {
    setLoadingResidentsState(true)
    coreApiClient.units.fetchUnitResidents(propertyId, unitId)
        .then(res => {
            setResidentsList(res.data.data)
            setLoadingResidentsState(false)
        })
        .catch(error => {
            console.log(error)
            setLoadingResidentsState(false)
        })
  }

  const next = () => {
    if (current === 0) {
      console.log(stepOneFields.find(item => item.name.find(field => field === 'residents')).value)
      if(stepOneFields.find(item => item.name.find(field => field === 'residents')).value.length > 0) {
        setCurrent(current + 1)
      } else {
        message.warning('Complete the empty fields before continuing')
      }
    }
    if (current === 1) {
      if(stepTwoFields.find(item => item.name.find(field => field === 'lease_type')).value && 
        stepTwoFields.find(item => item.name.find(field => field === 'start_date')).value && 
        stepTwoFields.find(item => item.name.find(field => field === 'amount')).value && 
        stepTwoFields.find(item => item.name.find(field => field === 'monthly_due_date')).value) {
          setCurrent(current + 1)
      } else {
        message.warning('Complete the empty fields before continuing')
      }
    }
    if (current === 2) {
      if(stepThreeFields.find(item => item.name.find(field => field === 'destination_account')).value) {
        setCurrent(current + 1)
      } else {
        message.warning('Provide payment destination before continuing')
      }
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleFormChangeOne = (allFields) => {
    let propertyId = allFields.find(item => item.name.find(field => field === 'property')).value
    let property = propertiesList.filter(item => item.id === propertyId)
    console.log(property)
    let unitId = allFields.find(item => item.name.find(field => field === 'unit')).value
    if (unitId){
      let unit = propertyUnits.find(item => item.id === unitId)
      allFields.push({name: ['stripe_product_id'], value: unit.stripe_product_id})
      console.log(unit)
    }
    // let residentId = allFields.find(item => item.name.find(field => field === 'resident')).value
    // if (residentId){
    //   let resident = residentsList.filter(item => item.id === residentId)
    //   console.log(resident)
    // }
    setStepOneFields(allFields);
  }

  const handleFormChangeTwo = (allFields) => {
    setStepTwoFields(allFields);
  }

  const handleFormChangeThree = (allFields) => {
    setStepThreeFields(allFields);
    let values = [...stepOneFields, ...stepTwoFields, ...stepThreeFields]
    let newFields = {}
    values.forEach(item => {
      newFields[item.name[0]] =item.value
    })
    setFields(newFields)
  }

  const handleSubmit = () => {
    console.log()
    setCreatingLease(true)
    let payload = fields;
    payload['residents'] = payload['residents'].map(resident_id => {
      if (typeof resident_id === 'string') {
        let residentDetail = residentsList.find(item => item.id === resident_id)
        return {
          'resident_id': residentDetail.id,
          'stripe_customer_id': residentDetail.stripe_customer_id,
        }
      } else {
        return resident_id
      }
    })
    console.log(payload)
    coreApiClient.leases.createDetail(payload)
    .then(res => {
        console.log(res.data)
        setCreatingLease(false)
        props.history.push('/leases/')
    })
    .catch(error => {
        console.log(error)
        setCreatingLease(false)
    })
  }

  const steps = [{
    title: 'Create Lease',
    description: "Property, Unit, and Resident",
    content: <LeaseCreateStepOne 
    fields={stepOneFields} 
    onChange={handleFormChangeOne} 
    propertiesList={propertiesList} 
    loadingPropertiesList={loadingPropertiesList} 
    fetchUnits={fetchUnits}
    loadingUnits={loadingUnits} 
    propertyUnits={propertyUnits}
    fetchResidents={fetchResidents}
    loadingResidents={loadingResidents} 
    residentsList={residentsList}
    />,
    }, {
    title: 'Lease Details',
    description: "Rent, Deposits, Dates",
    content: <LeaseCreateStepTwo fields={stepTwoFields} onChange={handleFormChangeTwo} propertyUnits={propertyUnits} stepOneFields={stepOneFields}  />,
    }, {
    title: 'Accounts',
    description: "Choose Account to Recieve Funds",
    content: <LeaseCreateStepThree fields={stepThreeFields} onChange={handleFormChangeThree} />,
    }, {
    title: 'Review',
    description: "Review and Confirm Lease Details",
    content: <LeaseCreateReview fields={fields} propertiesList={propertiesList} propertyUnits={propertyUnits} residentsList={residentsList} />,
  }];

    return (
      <div>
          {loadingStripeAccount ? <div style={{ textAlign:'center'}}><LoadingOutlined style={{ fontSize: 45, padding:'100px', color:'#1890ff' }} spin /></div> : 
          stripeAccount ? 
          <Row type='flex' justify='center'>
            <Col xs={24} sm={24} md={22} lg={18} xl={16}>
                <Steps current={current} style={{ padding: '30px 0px'}}>
                    {steps.map(item => <Step key={item.title} title={item.title} description={item.description} />)}
                </Steps>
            </Col>
            <Col xs={24} sm={24} md={22} lg={18} xl={18}>
                <div className="steps-content">
                    <Form 
                    layout='vertical' 
                    >
                        {steps[current].content}
                    </Form>
                </div>
                <Button size='large' style={{ marginTop: 15 }} onClick={() => props.history.goBack()}>
                    Cancel
                </Button>
                <div className="steps-action" style={{ float:'right', marginTop: 15 }}>
                    {
                    current > 0
                    && (
                    <Button size='large' style={{ marginLeft: 8 }} onClick={() => prev()}>
                        Previous
                    </Button>
                    )
                    }
                    {
                    current < steps.length - 1
                    && <Button size='large' style={{ marginLeft: 8 }} type="primary" onClick={() => next()}>Next</Button>
                    }
                    {
                    current === steps.length - 1
                    && <Button loading={creatingLease} size='large' style={{ marginLeft: 8, background:'#43c463', borderColor:'#43c463'}} type="primary" htmlType='submit' onClick={() => handleSubmit()}>Submit</Button>
                    }
                </div>
            </Col>
        </Row>
        :
        <div>
          <Empty description={
            <div><p>Setup payments with our payments provider Stripe before continuing</p><p><Button type='primary' ghost><Link to='/account/settings/payments/'>Setup Payments</Link></Button></p></div>
            }/>
        </div>
        }
      </div>
    );
}

const mapStateToProps = state => {
  return {
      authenticatedUser : state.user.authenticatedUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLease);