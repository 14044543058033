import React from 'react'
import PageHeader from '../../components/PageHeader';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Card, Col, Button, Statistic, Tooltip, Row, Table, Divider, Tag } from 'antd';

const columns = [{
    title: 'Amount',
    dataIndex: 'amount',
    align: 'right',
    //render: amount => accounting.formatMoney(amount/100),
  }, {
    title: 'Currency',
    dataIndex: 'currency',
    render: currency => String(currency).toUpperCase(),
  }, {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color='geekblue'>{status}</Tag>,
  }, {
    title: 'Description',
    dataIndex: 'description',
  }, {
    title: 'Fee',
    dataIndex: 'fee',
    render: (text, record) => <span>{record.fee_details.length > 0 ? record.fee_details[0].amount + ', ' + record.fee_details[0].description : ''}</span>,
  }, {
    title: 'Available On',
    dataIndex: 'available_on',
    //render: text => <Moment format='MMM Do, YYYY' unix>{text}</Moment>,
  }, {
    title: 'Created Date',
    dataIndex: 'created',
    //render: text => <Moment format='MMM Do, YYYY h:MM a' unix>{text}</Moment>,
  }, {
    title: 'Type',
    dataIndex: 'type',
  }];


export default function Payments(props) {

    const paginationProps = {
        size: 'middle',
        pageSize: 10,
        hideOnSinglePage: true,
        style: {marginRight:'30px'},
      }

    const balanceList = props.balanceList ? props.balanceList.data : [];

    return (
        <div>
            <PageHeader title='Payments' />
            <Card>
                <h4>Portfolio Summary</h4>
                <Divider />
                <Row type="flex" justify="space-between">
                    <Col span={4}>
                    <Statistic title={<span>Portfolio Value<Tooltip title={<div><p>Appraised Value - Mortgages</p></div>}><QuestionCircleOutlined style={{marginLeft:'7px'}} /></Tooltip></span>} value={''} />
                    </Col>
                    <Col span={4}>
                    <Statistic title={<span>Cashflow<Tooltip title={<div><p>Appraised Value - Mortgages</p></div>}><QuestionCircleOutlined style={{marginLeft:'7px'}} /></Tooltip></span>} value={''} />
                    </Col>
                    <Col span={4}>
                    <Statistic title={<span>NOI<Tooltip title={<div><p>Appraised Value - Mortgages</p></div>}><QuestionCircleOutlined style={{marginLeft:'7px'}} /></Tooltip></span>} value={''} />
                    </Col>
                    <Col span={4}>
                    <Statistic title={<span>Expenses<Tooltip title={<div><p>Appraised Value - Mortgages</p></div>}><QuestionCircleOutlined style={{marginLeft:'7px'}} /></Tooltip></span>} value={''} />
                    </Col>
                </Row>
                <Row>
                <Col md={24} lg={18} style={{padding:'12px'}}>
                    {/* <StackedAreaChart /> */}
                </Col>
                </Row>
            </Card>
            <Row>
                <Card
                title="Payment Activity"    
                // extra={<Button type='primary' style={{background:'#43c463', border:'none'}}><PlusOutlined></PlusOutlined>Expense</Button>}
                style={{marginTop:'20px'}}
                bodyStyle={{padding:'0px'}}
                >
                    <Table 
                    columns={columns} 
                    dataSource={balanceList}
                    pagination={paginationProps}
                    tableLayout={'fixed'}
                    />
                </Card>
            </Row>
            {/* <Card style={{marginTop:'21px'}}>
                <h4>Portfolio Details</h4>
                <Divider />
                <Col md={24} lg={12} style={{padding:'12px'}}>
                    <strong>Cashflow</strong>
                    <div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Total Income</span>
                            <span style={{float:'right'}}>$12503</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Total Expenses</span>
                            <span style={{float:'right'}}>$9870</span>
                            <Divider dashed={true} style={{margin:'10px 0px'}} />
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Cashflow</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                    </div>
                    <Divider style={{margin:'15px 0px'}} />
                    <strong>Income</strong>
                    <div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Total Rents</span>
                            <span style={{float:'right'}}>$12503</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <p>Other Income:</p>
                            <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                                <span>Laundry</span>
                                <span style={{float:'right'}}>$9870</span>
                            </div>
                            <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                                <span>Parking</span>
                                <span style={{float:'right'}}>$12503</span>
                            </div>
                            <Divider dashed={true} style={{margin:'10px 0px'}}/>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Total Revenue</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                    </div>
                    <Divider style={{margin:'15px 0px'}} />
                    <strong>Expenses</strong>
                    <div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Fixed Costs</span>
                            <span style={{float:'right'}}>$12503</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Cap Ex</span>
                            <span style={{float:'right'}}>$9870</span>
                            <Divider dashed={true} style={{margin:'10px 0px'}} />
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Expenses</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                    </div>
                </Col>
                <Col md={24} lg={12} style={{padding:'12px'}}>
                    <strong>Equity</strong>
                    <div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Appraised Value</span>
                            <span style={{float:'right'}}>$12503</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Total Expenses</span>
                            <span style={{float:'right'}}>$9870</span>
                            <Divider dashed={true} style={{margin:'10px 0px'}} />
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Cashflow</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                    </div>
                    <Divider style={{margin:'15px 0px'}} />
                    <strong>Balances</strong>
                    <div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Free Cash</span>
                            <span style={{float:'right'}}>$12503</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Mortgage Liabilities</span>
                            <span style={{float:'right'}}>$9870</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Security Deposits</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Contractor Invoices</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                        <div style={{paddingLeft:'20px', marginBottom:'8px'}}>
                            <span>Home Equity Line of Credit (HELOC)</span>
                            <span style={{float:'right'}}>$3260</span>
                        </div>
                    </div>
                </Col>
            </Card> */}
        </div>
    );
}
