import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, TeamOutlined, HomeOutlined } from '@ant-design/icons';
import { Form, Card, Select, Tooltip, Spin, Empty } from 'antd';

const Option = Select.Option;

function propertyOptions(propertiesList){
  const properties = [];
  for (let i = 0; i < propertiesList.length; i++) {
    properties.push(
      <Option value={propertiesList[i].id}>{propertiesList[i].address}</Option>
    );
  }
  return properties;
}

function residentsOptions(residentsList){
  const residents = [];
  for (let i = 0; i < residentsList.length; i++) {
    residents.push(
      <Option value={residentsList[i].id}>{residentsList[i].first_name + ' ' + residentsList[i].last_name}</Option>
    );
  }
  return residents;
}

const LeaseCreateStepOne = (props) => {
  const [selectedProperty, setSelectedProperty] = useState(undefined)
  const [selectedUnit, setSelectedUnit] = useState(undefined)
  const [selectedResident, setSelectedResident] = useState(undefined)

  const fields = props.fields;

  const unitsOptions = (unitsList) => {
    const units = [];
    for (let i = 0; i < unitsList.length; i++) {
      units.push(
        <Option value={unitsList[i].id}>{unitsList[i].unit_name}</Option>
      );
    }
    return units;
  }

    return (
      <Card>
          <Form 
          layout="vertical"
          name="global_state"
          fields={fields}
          onFieldsChange={(changedFields, allFields) => {
            props.onChange(allFields);
          }}
          >
          <h3>Please select the property you would like to setup a lease for.</h3>
          <Form.Item 
          label="Property"
          name='property'
          rules={[{ required: true, message: 'Select a property',}]}
          >
              <Select 
              size='large' 
              placeholder="Select a property"
              style={{width:'400px'}}
              notFoundContent={props.loadingPropertiesList ? <div style={{textAlign:'center'}}><Spin size="small" /></div> : <Empty description='No properties found' image={<HomeOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} />}
              onSelect={value => {
                setSelectedProperty(value)
                props.fetchUnits(value)
              }} 
              loading={props.loadingPropertiesList}
              >
                {propertyOptions(props.propertiesList ? props.propertiesList : [])}
              </Select>
          </Form.Item>
          <h3>Select the unit with the resident you would like to be on the lease.</h3>
          <Form.Item 
          label="Unit"
          name='unit'
          rules={[{ required: true, message: 'Select a unit',}]}
          >
              <Select 
              size='large' 
              placeholder='Select a unit'
              disabled={selectedProperty ? false : true} 
              style={{width:'400px'}}
              notFoundContent={props.loadingUnits ? <div style={{textAlign:'center'}}><Spin size="small" /></div> : <Empty description='No units found' />}
              onSelect={value => {
                setSelectedUnit(value)
                props.fetchResidents(value)
              }} 
              loading={props.loadingUnits}
              >
                {selectedProperty ? unitsOptions(props.propertyUnits, selectedProperty) : []}
              </Select>
          </Form.Item>
          <h3>Add all of residents that will be included on the lease.<Tooltip title='The rent can be split amongst all of the residents of the unit'><InfoCircleOutlined style={{marginLeft:'10px'}} /></Tooltip></h3>
          <p>Each included resident will be sent an email from RealtyStack to setup payments. <a>Learn more</a></p>
          <Form.Item 
          label="Residents"
          name='residents'
          rules={[{ required: true, message: 'Select a resident',}]}
          >
              <Select 
              disabled={selectedUnit ? false : true} 
              style={{width:'400px'}}
              size='large'
              mode='multiple' 
              placeholder='Select a resident'
              notFoundContent={props.loadingResidents ? <div style={{textAlign:'center'}}><Spin size="small" /></div> : <Empty description='No residents found' image={<TeamOutlined style={{ fontSize: 100, color:'#dce0e6' }} />} />}
              onSelect={value => {
                setSelectedResident(value)
              }} 
              loading={props.loadingResidents}
              >
                {selectedUnit ? residentsOptions(props.residentsList) : []}
              </Select>
          </Form.Item>
          </Form>
      </Card>
    );
}

export default LeaseCreateStepOne;