import React, { useState, useEffect } from 'react';
import PageHeader from '../../../components/PageHeader'
import { EllipsisOutlined, LoadingOutlined, SettingOutlined, EnvironmentOutlined } from '@ant-design/icons';
import {
    Input,
    Select,
    Divider,
    Tag,
    Popconfirm,
    Table,
    Dropdown,
    Button,
    Collapse,
    Card,
    Empty,
    Menu,
    List,
    Skeleton,
    Avatar,
    Row,
    Col,
} from 'antd';
import { Link } from 'react-router-dom';
import CreateMaintenanceDrawer from './CreateMaintenanceDrawer';
import workflowApiClient from '../../../store/apiService';
import moment from 'moment';

const { Option } = Select;
const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;

const paginationProps = {
    size: 'small',
    pageSize: 5,
    hideOnSinglePage: true,
    searchTerm: undefined,
  }

const Panel = Collapse.Panel;

const panelMenu = (
    <Menu style={{width:'50px'}}>
      <Menu.Item>
        <Link to='/dashboard/todo/'><SettingOutlined /></Link>
      </Menu.Item>
    </Menu>
);

export default function Maintenance(props) {
    const [categoryFilter, setCategoryFilter] = useState('');
    const [taskList, setTaskList] = useState([]);
    const [loadingTaskList, setTaskListLoadingState] = useState(false);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        setTaskListLoadingState(true)
        workflowApiClient.maintenance.fetchList()
        .then(res => {
            console.log(res.data)
            setTaskList(res.data.data)
            setTaskListLoadingState(false)
        })
        .catch(error => {
            console.log(error)
            setTaskListLoadingState(false)
        })
    }, []);

    const fetchTasksList = () => {
        workflowApiClient.maintenance.fetchList()
            .then(res => {
                console.log(res.data)
                setTaskList(res.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const maintenanceCards = (tasks) => (
      <List
          itemLayout="vertical"
          dataSource={loadingTaskList ? [{},{},{}] : tasks}
          locale={{emptyText: 'No Tasks'}}
          renderItem={item => (
          <Card 
          bordered={false} 
          hoverable 
          style={{marginBottom:'10px'}} 
          bodyStyle={{padding:'3px 12px'}}
          >
          <List.Item
          >
              <Skeleton avatar title={false} active loading={loadingTaskList}>
              <List.Item.Meta
                  title={<div>
                  <a>
                    {item.maintenance_category}
                    {item.task_priority ? 
                    <Tag 
                    color={item.task_priority === 'Low' ? 'geekblue' : 
                    item.task_priority === 'Moderate' ? 'gold' :
                    item.task_priority === 'High' ? 'volcano' :
                    item.task_priority === 'Critical' ? 'red' : 'default'
                    }
                    style={{marginLeft:'10px'}}
                    >
                      {item.task_priority}
                    </Tag> 
                    : null}
                  </a>
                  <span style={{float:'right', fontSize:12}}>{item.due_date ? <p>Due: {moment(item.due_date).format('MM-DD-YY')}</p> : null}</span>
                  </div>}
                  description={item.issue}
              />
              <p style={{marginBottom:'0px'}}><EnvironmentOutlined style={{marginRight:'8px'}} />{item.address}{item.unit_id ? `- ${item.unit_id}` : ''}</p>
              </Skeleton>
          </List.Item>
          </Card>
          )}
      />
    )
    
    const filteredTaskList = searchText ? taskList.filter(item => item.maintenance_category.includes(categoryFilter)).filter(item => JSON.stringify(item).toLowerCase().includes(searchText)).sort((a,b) => moment(b.due_date) - moment(a.due_date)) : taskList.filter(item => item.maintenance_category.includes(categoryFilter)).sort((a,b) => moment(b.due_date) - moment(a.due_date))
    const newIssues = filteredTaskList.filter(item => item.task_status === 'New')
    const inProgress = filteredTaskList.filter(item => item.task_status === 'In-progress')
    const completed = filteredTaskList.filter(item => item.task_status === 'Completed')

    return (
        <div>
            <PageHeader title='Maintenance' />
            <div style={{marginBottom: '30px', display:'inline-flex', width:'100%'}}>
                <Input.Search 
                style={{width: '300px', background:'#e9ecef'}} 
                size='large'
                placeholder={`Search tasks`}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                />
                <span style={{ marginLeft:'30px' }} >
                    Category
                </span>
                <Select 
                defaultValue="All" 
                style={{ width: 230, margin: '0 10px' }} 
                size='large'
                onChange={value => setCategoryFilter(value)}
                >
                    <Option value=''>All</Option>
                    <Option value='Appliances'>Appliances</Option>
                    <Option value='Plumbing'>Plumbing</Option>
                    <Option value='Landscaping'>Landscaping</Option>
                    <Option value='Outdoor'>Outdoor</Option>
                    <Option value='Electrical'>Electrical</Option>
                    <Option value='General Household'>General Household</Option>
                    <Option value='Other'>Other</Option>
                </Select>
                <CreateMaintenanceDrawer fetchTasksList={fetchTasksList} />
            </div>
            <Row>
                <Col span={24} style={{paddingRight:'15px'}}>
                    <Collapse bordered={false} defaultActiveKey={['1']}>
                        <Panel 
                        header={<h3 style={{margin:'10px', display:'inline-flex'}}>New Issues ({newIssues.length})</h3>} 
                        key="1" 
                        style={{backgroundColor: '#fff !important',border:'none'}}
                        extra={<div>
                        <h3 style={{margin:'10px', display:'inline-flex'}}>
                            <Dropdown overlay={panelMenu}>
                            <Button icon={<EllipsisOutlined />}></Button>
                            </Dropdown>
                        </h3>
                        </div>}
                        >
                            {maintenanceCards(newIssues)}
                        </Panel>
                    </Collapse>
                </Col>
            <Col span={24} style={{paddingRight:'15px'}}>
                <Collapse bordered={false} defaultActiveKey={['2']}>
                    <Panel 
                    header={<h3 style={{margin:'10px', display:'inline-flex'}}>In Progress ({inProgress.length})</h3>} 
                    key="2"
                    style={{backgroundColor: '#fff !important',border:'none'}}
                    extra={<div>
                    <h3 style={{margin:'10px', display:'inline-flex'}}>
                        <Dropdown overlay={panelMenu}>
                        <Button icon={<EllipsisOutlined />}></Button>
                        </Dropdown>
                    </h3>
                    </div>}
                    >
                      {maintenanceCards(inProgress)}
                    </Panel>
                    </Collapse>
            </Col>
            <Col span={24} style={{paddingRight:'15px'}}>
                <Collapse bordered={false} defaultActiveKey={['3']}>
                    <Panel 
                    header={<h3 style={{margin:'10px', display:'inline-flex'}}>Completed ({completed.length})</h3>} 
                    key="3"
                    style={{backgroundColor: '#fff !important',border:'none'}}
                    extra={<div>
                    <h3 style={{margin:'10px', display:'inline-flex'}}>
                        <Dropdown overlay={panelMenu}>
                        <Button icon={<EllipsisOutlined />}></Button>
                        </Dropdown>
                    </h3>
                    </div>}
                    >
                    {maintenanceCards(completed)}
                    </Panel>
                    </Collapse>
                </Col>
            </Row>
        </div>
    );
}
