import React, { useState } from 'react';
import { LoadingOutlined, PictureOutlined, PlusOutlined } from '@ant-design/icons';
import { List, Spin, Card, Col, Button, Tag, Empty, Input, Divider } from 'antd';
import { Link } from 'react-router-dom';
import coreApiClient from '../../../../store/apiService';
import CreateResidentModal from '../../createResident/CreateResidentModal';

const loadingIcon = <LoadingOutlined style={{ fontSize: 45 }} spin />;


export default function UnitsTabs(props) {
    const [searchText, setSearchText] = useState(null);

    const propertyId = props.propertyId;
    const propertyUnits = props.propertyUnits ? props.propertyUnits.sort((a, b) => a.unit_name.localeCompare(b.unit_name)) : [];

    return (
        <div>
            {props.loadingUnits ? <div style={{padding:'200px', textAlign:'center'}}><Spin indicator={loadingIcon} /></div>
            :
            <div>
                <Col lg={24}>
                    <div style={{margin: '10px 0px'}}>
                        <Input.Search 
                        style={{width: '300px', background:'#e9ecef'}} 
                        size='large'
                        placeholder={`Search Units`}
                        onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Link style={{color:'#fff'}} to={`/properties/${propertyId}/units/create/`}>
                            <Button 
                            style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
                            type='primary' 
                            size='large'
                            icon={<PlusOutlined />}
                            >
                            Unit
                            </Button>
                        </Link>
                    </div>
                    <List
                    itemLayout="horizontal"
                    dataSource={searchText ? propertyUnits.filter(unit => JSON.stringify(unit).toLowerCase().includes(searchText)) : propertyUnits}
                    locale={{ emptyText: <Empty description='No Units'/> }}
                    pagination={{
                        pageSize: 10,
                        hideOnSinglePage: true,
                        size: 'default',
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    split={false}
                    renderItem={item => (
                        <List.Item>
                            <Card
                            hoverable
                            style={{width:'100%', height:'120px', borderRadius:'8px 0px 0px 8px'}}
                            bodyStyle={{padding: '0px', height:'100%', display:'flex'}}
                            bordered={false}
                            >
                                <List.Item.Meta
                                style={{height:'100%'}}
                                avatar={
                                    item.photo ? 
                                        <div>
                                            <img alt="Property Photo" 
                                            style={{width: '200px', height:'120px', objectFit:'cover', borderRadius:'8px 0px 0px 8px'}} 
                                            src={item.photo_url} 
                                            /> 
                                            <div style={{
                                                position:'absolute',
                                                height:'20px',
                                                width: 'auto',
                                                left: 0, 
                                                top: 15, 
                                                padding: '1px 8px', 
                                                color: item.residents_count > 0 ? '#fff' : '#fff', 
                                                background: item.residents_count > 0 ? '#52c41a' : '#f5222d',
                                                fontSize: '12px'
                                            }}>
                                                <span>{item.residents_count > 0 ? 'Occupied' : 'Vacant'}</span>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div 
                                            style={{
                                                height:'120px', 
                                                width:'200px', 
                                                backgroundColor:'#e9ecef', 
                                                textAlign:'center', 
                                                verticalAlign:'middle', 
                                                paddingTop:'35px',
                                                borderRadius:'8px 0px 0px 8px'
                                            }}>
                                                <span style={{width:'100%', height:'100%'}}>
                                                    <PictureOutlined style={{color:'#999', fontSize:'50px'}} />
                                                </span>
                                            </div>
                                            <div style={{
                                                position:'absolute',
                                                height:'20px',
                                                width: 'auto',
                                                left: 0, 
                                                top: 15, 
                                                padding: '1px 8px', 
                                                color: item.residents_count > 0 ? '#fff' : '#fff', 
                                                background: item.residents_count > 0 ? '#52c41a' : '#f5222d',
                                                fontSize: '12px'
                                            }}>
                                                <span>{item.residents_count > 0 ? 'Occupied' : 'Vacant'}</span>
                                            </div>
                                        </div>
                                }
                                title={
                                    <div style={{padding:'20px 20px 0px 0px', fontSize:'20px', fontWeight:400}}>
                                        <Link to={`/properties/${propertyId}/units/${item.id}`}>{item.unit_name}</Link>
                                        <Tag 
                                        style={{
                                        marginLeft:'15px', 
                                        borderRadius:'10px', 
                                        fontSize:'11px',
                                        backgroundColor:
                                            item.unit_status === 'Active' ? '#f6ffed' :
                                            item.unit_status === 'Listed' ? '#f0f5ff' :
                                            item.unit_status === 'Pending' ? '#fff7e6' :
                                            '#fafafa',
                                        color:
                                            item.unit_status === 'Active' ? '#43c463' :
                                            item.unit_status === 'Listed' ? '#1890ff' :
                                            item.unit_status === 'Pending' ? '#faad14' :
                                            '#000000a6',
                                        border:
                                            item.unit_status === 'Active' ? '#43c463' :
                                            item.unit_status === 'Listed' ? '#1890ff' :
                                            item.unit_status === 'Pending' ? '#faad14' :
                                            '#d9d9d9', 
                                        }}
                                        >
                                            {item.unit_status}
                                        </Tag>
                                    </div>
                                }
                                description={
                                <div>
                                    <span>{item.unit_type}</span>
                                    <div style={{marginTop:'20px', fontWeight:400, textTransform:'uppercase', fontSize:'12.5px', position:'absolute', bottom:0}}>
                                        <span>{item.bed_count} BEDS</span>
                                        <Divider type='vertical' />
                                        <span>{item.bath_count} BATHS</span>
                                        <Divider type='vertical' />
                                        <span>{item.sq_footage ? item.sq_footage : '-'} SQ.FT</span>
                                    </div>
                                </div>
                                }
                                />
                                <div style={{padding:'20px'}}>
                                    <Col style={{textAlign:'center', padding:'0px 20px'}}>
                                        <span style={{fontSize:'24.5px', fontWeight:400, lineHeight:'37px'}}>{item.residents_count}</span>
                                        <p style={{fontSize:'12.5px', fontWeight:300}}>RESIDENTS</p>
                                    </Col>
                                </div>
                                <div style={{padding:'20px'}}>
                                    <Col style={{textAlign:'center', padding:'0px 20px'}}>
                                        <span style={{fontSize:'24.5px', fontWeight:400, lineHeight:'37px'}}>${item.rent}</span>
                                        <p style={{fontSize:'12.5px', fontWeight:300}}>PER MONTH</p>
                                        <p style={{fontSize:'12.5px', fontWeight:300}}>
                                            <CreateResidentModal unitDetail={item} {...props} handleResidentEdit={props.fetchPropertyUnits} buttonType={'ghost'}/>
                                        </p>
                                    </Col>
                                </div>
                            </Card>
                        </List.Item>
                        )}
                    />
                </Col>
            </div>
            }
        </div>
    );
}
