import React, { useState } from 'react';
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button, Col, Row, Input, Select, Collapse, Form } from 'antd';
import coreApiClient from '../../../../store/apiService';

const { Option } = Select;
const Panel = Collapse.Panel;

const CreateContact = (props) => {
  const [visible, setVisibility] = useState(false)

  const [form] = Form.useForm();
  const contactDetail = props.contactDetail;

  const showDrawer = () => {
    setVisibility(true)
  };

  const onClose = () => {
    form.resetFields()
    setVisibility(false)
  };

  const handleEdit = () => {
    const contactDetail = props.contactDetail;
    form.setFieldsValue(contactDetail)
    showDrawer()
  };

  const handleSubmit = (values) => {
      console.log(values)
      for(var key in values) {
        if(values[key] === "") {
           delete values[key]
        }
    }
      if(props.editContact) {
        values['created_on'] = contactDetail.created_on
        coreApiClient.contacts.updateDetail(values, contactDetail.id)
        .then(res => {
            props.fetchContactsList()
            onClose()
        })
        .catch(error => {
            console.log(error)
        })
      } else {
        coreApiClient.contacts.createDetail(values)
        .then(res => {
            props.fetchContactsList()
            onClose()
        })
        .catch(error => {
            console.log(error)
        })
      }
  }


    const prefixSelector = (
      <Select style={{ width: 70 }}>
        <Option value="1">+1</Option>
        <Option value="44">+44</Option>
        <Option value="52">+52</Option>
        <Option value="91">+91</Option>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    );

    return (
      <div style={{width:'100%'}}>
        {props.editContact ?
          <Button 
          type='link'
          onClick={handleEdit}
          >
            <EditOutlined style={{paddingLeft:'10px'}} />
          </Button>
          :
          <Button 
          style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
          type='primary' 
          size='large'
          icon={<PlusOutlined />}
          onClick={showDrawer}
          >
            Contact
          </Button>
        }
        <Drawer
          title={<Row style={{background:'#ff7a59', padding:'18px'}}>
          <h3 style={{margin:'0px', color:'white'}}>Create Contact</h3>
          </Row>}
          width={720}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark form={form} onFinish={handleSubmit}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name" name='name' rules={[{ required: true, message: 'Enter name' }]}>
                  <Input placeholder="Please enter name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Type" name='type' rules={[{ required: true, message: 'Choose type' }]}>
                    <Select placeholder="Please choose a contact type">
                      <Option value="Investor">Investor</Option>
                      <Option value="Broker">Broker</Option>
                      <Option value="Property Manager">Property Manager</Option>
                      <Option value="Resident">Resident</Option>
                      <Option value="Agent">Agent</Option>
                      <Option value="Contractor">Contractor</Option>
                      <Option value="Seller">Seller</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Email" name='email'>
                    <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phone" name='phone'>
                    <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Notes" name='notes'>
                  <Input.TextArea rows={4} placeholder="Enter contact notes" />
                </Form.Item>
              </Col>
            </Row>
            <Collapse 
            bordered={false}
            style={{backgroundColor:'#f9fbfd'}}
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined rotate={isActive ? 90 : 0} />}               
            >
              <Panel 
              header="Additional Info" 
              key="1" 
              style={{borderBottom:'none'}}
              forceRender={true}
              >
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="Address" name='address'>
                        <Input placeholder="Please enter address" />
                      </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item label="City" name='city'>
                          <Input
                              placeholder="Please enter city"
                          />
                      </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="State" name='state'>
                          <Input placeholder="Please enter state" />
                      </Form.Item>
                      </Col>
                      <Col span={12}>
                      <Form.Item label="Zip Code" name='zip_code'>
                          <Input
                              placeholder="Please enter zip code"
                          />
                      </Form.Item>
                      </Col>
                  </Row>
                  <Row gutter={16}>
                      <Col span={12}>
                      <Form.Item label="Url" name='url'>
                          <Input
                              style={{ width: '100%' }}
                              placeholder="Please enter url"
                          />
                      </Form.Item>
                      </Col>
                  </Row>
              </Panel>
            </Collapse>
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button 
            onClick={onClose} 
            style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button 
            onClick={() => form.submit()} 
            type="primary"
            style={{borderColor: '#43c463', backgroundColor: '#43c463', float:'right'}} 
            >
              Submit
            </Button>
          </div>
        </Drawer>
      </div>
    );
}

export default CreateContact;