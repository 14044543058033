import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/auth';
import { formatPhoneNumber } from '../../../../store/utility';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Button, Input, Upload, message, Spin, Form } from 'antd';
import CardHeader from '../../../../components/CardHeader';
import Storage from '@aws-amplify/storage';
import coreApiClient from '../../../../store/apiService';

const FormItem = Form.Item;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}

function beforeUpload(file) {
  const isIMG = file.type === 'image/jpeg' || file.type === 'image/JPEG' || file.type === 'image/PNG' || file.type === 'image/png';
  if (!isIMG) {
    message.error('You can only upload image file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isIMG && isLt2M;
}

const ProfileSettings = (props) => {
  const [loading, setLoading] = useState(false)
  const [changePhoto, setChangePhoto] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [profilePicFileObj, setProfilePicFileObj] = useState(null)
  const [profilePicFileName, setProfilePicFileName] = useState(null)

  const userProfile = props.userProfile ? props.userProfile : {};

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.userProfile) {
      form.setFieldsValue({
        'given_name': props.userProfile.given_name,
        'family_name': props.userProfile.family_name,
        'company': props.userProfile.company,
        'phone_number': formatPhoneNumber(props.userProfile.phone_number),
        'address': props.userProfile.address,
        'custom:city': props.userProfile['custom:city'],
        'custom:state': props.userProfile['custom:state'],
        'custom:zip_code': props.userProfile['custom:zip_code'],
        'custom:country': props.userProfile['custom:country'],
        'custom:company': props.userProfile['custom:company'],
      });
    }
  }, [props.userProfile])

  const handleSubmit = (values) => {
      if (!formatPhoneNumber(values.phone_number)) {
        message.error('Invalid Phone Number')
        return
      }
      values['phone_number'] = `+${values['phone_number'].length === 10 ? '1' : ''}${values['phone_number'].replace(/[^0-9]+/g, '')}`
      console.log('Received values of form: ', values);

      Storage.put(`${userProfile.sub}/profile/${profilePicFileName}`, profilePicFileObj, { level: 'private' })
      .then (result => console.log(result))
      .catch(err => console.log(err));
      
      coreApiClient.profile.updateDetail(values)
      .then(res => {
        props.fetchUserProfile()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleChange = (e) => {
    console.log(e);
    getBase64(e.file.originFileObj, imageUrl => {
        form.setFieldsValue({
        photo: `${props.userID}/profile/${e.file.name}`
        })
        setImageUrl(imageUrl);
        setProfilePicFileName(e.file.name);
        setProfilePicFileObj(e.file.originFileObj);
    });
  }



    const uploadButton = (
      <div style={{padding:'40px', backgroundColor:'#e9ecef'}}>
          <LegacyIcon type={loading ? 'loading' : 'plus'} />
          <div className="ant-upload-text">Upload Photo</div>
      </div>
      );

        return (
              <Col md={18} lg={20}
              style={{paddingLeft:'40px'}}
              >
                <CardHeader title='Profile' subtitle='Settings' description='Update profile' />
              {props.loadingUserProfile ? 
              <div style={{textAlign:'center', paddingTop:'50px'}}><Spin /></div>
              :
              <div>
                  <Form onFinish={handleSubmit} layout='vertical' hideRequiredMark form={form}>
                    <Row>
                        <Col span={18} style={{paddingRight:'15px'}}>
                          <Row>
                            <Col span={12}>
                              <FormItem
                              label='First Name'
                              style={{marginBottom:'0px', marginRight:'15px'}}
                              name='given_name'
                              rules={[{ required: true, message: 'Please input your First Name' },]}
                              >
                                  <Input placeholder='First Name'/>
                              </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                              label='Last Name'
                              style={{marginBottom:'0px', marginRight:'15px'}}
                              name='family_name'
                              rules={[{ required: true, message: 'Please input your Last Name' },]}
                                >
                                    <Input placeholder='Last Name' />
                                </FormItem>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                            <FormItem
                              label='Company'
                              name='custom:company'
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='Company' />
                            </FormItem>
                            
                            </Col>
                            <Col span={12}>
                            <FormItem
                              label='Phone'
                              name='phone_number'
                              rules={[{ required: true, message: 'Please input your phone number' },]}
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='Phone Number' />
                            </FormItem>
                            </Col>
                          </Row>
                          <FormItem
                            label='Address'
                            name='address'
                            rules={[{ required: true, message: 'Please input your address' },]}
                            style={{marginBottom:'0px', marginRight:'15px'}}
                          >
                              <Input placeholder='Address' />
                          </FormItem>
                          <Row>
                            <Col span={12}>
                            <FormItem
                              label='City'
                              name='custom:city'
                              rules={[{ required: true, message: 'Please input your city' },]}
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='City' />
                            </FormItem>
                            
                            </Col>
                            <Col span={12}>
                            <FormItem
                              label='Zip Code'
                              name='custom:zip_code'
                              rules={[{ required: true, message: 'Please input your zip code' },]}
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='Zip Code' />
                            </FormItem>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                            <FormItem
                              label='State'
                              name='custom:state'
                              rules={[{ required: true, message: 'Please input your state' },]}
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='State' />
                            </FormItem>
                            </Col>
                            <Col span={12}>
                            <FormItem
                              label='Country'
                              name='custom:country'
                              rules={[{ required: true, message: 'Please input your country' },]}
                              style={{marginBottom:'0px', marginRight:'15px'}}
                            >
                                <Input placeholder='Country' />
                            </FormItem>
                            </Col>
                          </Row>      
                        </Col>
                        {/* <Col span={8}>
                            <FormItem
                            label='Profile Picture'
                            name='photo'
                            getValueFromEvent={handleChange}
                            >   
                                {changePhoto || !userProfile.photo ? 
                                <Upload 
                                name="avatar" 
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                >
                                {imageUrl ? <img src={imageUrl} style={{maxWidth:'125px',}} alt="avatar" /> : uploadButton}
                                </Upload>
                                :
                                <div style={{textAlign:'center'}}>
                                  {/* <img 
                                  style={{width:'150px'}} 
                                  src={this.props.userProfile.profile_picture ? this.props.userProfile.profile_picture : undefined} 
                                  /> 
                                  <img 
                                  src={userProfile.photo ? userProfile.photo : undefined} 
                                  height={165}
                                  width={165}
                                  style={{objectFit:'cover', borderRadius:'5px'}}
                                  />
                                </div>
                                }
                            </FormItem>
                            <div style={{textAlign:'center'}}>
                              <p>
                              {userProfile.photo ? 
                                  (changePhoto ? 
                              <Button onClick={() => setChangePhoto(!changePhoto)}>
                                Cancel
                              </Button>
                              : 
                              <Button onClick={() => setChangePhoto(!changePhoto)}>
                                Change Photo
                              </Button>
                              )
                              : 
                              undefined
                              }
                              </p>
                            </div>
                        </Col> */}
                    </Row>
                    <FormItem style={{marginBottom:'0px', marginTop:'10px'}}>
                      <Button style={{background:'#43c463', border:'none'}} type="primary" htmlType="submit" loading={props.updatingProfile}>
                      Update Profile
                      </Button>
                    </FormItem>
                  </Form>
                  </div>}
              </Col>
        )
}


const mapStateToProps = state => {
  return {
    authenticatedUser : state.user.authenticatedUser,
    userID : state.user.userSub,
    updatingProfile : state.user.updatingProfile,   
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateProfile: (values) => dispatch(actions.updateUserProfile(values)),
    getUser: () => dispatch(actions.getUserProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
