import React from 'react';
import { Switch } from 'react-router-dom';

import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';
import withTracker from './withTracker';
// Layouts
import DashboardLayout from '../layout/DashboardLayout';
import AccountLayout from '../layout/AccountLayout';

// Pages
import Dashboard from '../pages/Dashboard';
import Login from '../pages/accounts/Login';
import Properties from '../pages/properties/propertiesList/Properties';
import PropertyDetail from '../pages/properties/propertyDetail/PropertyDetail';
import UnitDetail from '../pages/properties/unitDetail/UnitDetail';
import Profile from '../pages/accounts/profile/Profile';
import Todos from '../pages/workflow/todos/Todos';
import Payments from '../pages/payments/Payments';
import Contacts from '../pages/accounts/contacts/Contacts';
import Maintenance from '../pages/workflow/maintenance/Maintenance';
import ProfileSettings from '../pages/accounts/settings/Settings';
import SupportRequest from '../pages/accounts/support/SupportRequest';
import Leases from '../pages/leases/Leases';
import CreateProperty from '../pages/properties/createProperty/CreateProperty';
import CreateUnit from '../pages/properties/createUnit/CreateUnit';
import Signup from '../pages/accounts/Signup';
import AccountConfirm from '../pages/accounts/AccountConfirm';
import CreateProfile from '../pages/accounts/profile/CreateProfile';
import CreateLease from '../pages/leases/createLease/CreateLease';
import AccountPasswordReset from '../pages/accounts/AccountPasswordReset';
import AccountPasswordResetConfirm from '../pages/accounts/AccountPasswordResetConfirm';
import LeaseDetail from '../pages/leases/leaseDetail/LeaseDetail';

const BaseRouter = () => (
    <Switch>
        <AppRoute exact path='/signup/' layout={ AccountLayout } component={withTracker(Signup)}/>
        <AppRoute exact path='/signup/confirm' layout={ AccountLayout } component={withTracker(AccountConfirm)}/>
        <AppRoute exact path='/login/' layout={ AccountLayout } component={withTracker(Login)}/>
        <AppRoute exact path='/account/password/reset/' layout={ AccountLayout } component={withTracker(AccountPasswordReset)}/>
        <AppRoute exact path='/account/password/reset/confirm/' layout={ AccountLayout } component={withTracker(AccountPasswordResetConfirm)}/>
        <PrivateRoute exact path='/' layout={ DashboardLayout } component={withTracker(Dashboard)}/>

        <PrivateRoute exact path='/account/profile/' layout={ DashboardLayout } component={withTracker(Profile)}/>
        <PrivateRoute exact path='/account/profile/create/' layout={ AccountLayout } component={withTracker(CreateProfile)}/>
        <PrivateRoute path='/account/settings/' layout={ DashboardLayout } component={withTracker(ProfileSettings)}/>
        <PrivateRoute path='/account/support/request/' layout={ DashboardLayout } component={withTracker(SupportRequest)}/>

        <PrivateRoute exact path='/properties/' layout={ DashboardLayout } component={withTracker(Properties)}/>
        <PrivateRoute exact path='/properties/create/' layout={ DashboardLayout } component={withTracker(CreateProperty)}/>
        <PrivateRoute exact path='/properties/:propertyId/' layout={ DashboardLayout } component={withTracker(PropertyDetail)}/>
        <PrivateRoute exact path='/properties/:propertyId/edit' layout={ DashboardLayout } component={withTracker(CreateProperty)}/>
        <PrivateRoute exact path='/properties/:propertyId/units/create/' layout={ DashboardLayout } component={withTracker(CreateUnit)}/>
        <PrivateRoute exact path='/properties/:propertyId/units/:unitId/' layout={ DashboardLayout } component={withTracker(UnitDetail)}/>
        <PrivateRoute exact path='/properties/:propertyId/units/:unitId/edit' layout={ DashboardLayout } component={withTracker(CreateUnit)}/>

        <PrivateRoute exact path='/payments/' layout={ DashboardLayout } component={withTracker(Payments)}/>

        <PrivateRoute exact path='/leases/' layout={ DashboardLayout } component={withTracker(Leases)}/>
        <PrivateRoute exact path='/leases/create/' layout={ DashboardLayout } component={withTracker(CreateLease)}/>
        <PrivateRoute exact path='/leases/:leaseId/' layout={ DashboardLayout } component={withTracker(LeaseDetail)}/>

        <PrivateRoute exact path='/tasks/maintenance/' layout={ DashboardLayout } component={withTracker(Maintenance)}/>
        <PrivateRoute exact path='/tasks/todos/' layout={ DashboardLayout } component={withTracker(Todos)}/>

        <PrivateRoute exact path='/contacts/' layout={ DashboardLayout } component={withTracker(Contacts)}/>
    </Switch>
);

export default BaseRouter;
