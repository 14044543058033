import React from 'react';
import { InteractionOutlined, PushpinOutlined } from '@ant-design/icons';
import { Select, Card, DatePicker, Row, Col, Radio, InputNumber, Form, Switch } from 'antd';
import moment from 'moment';

const RadioGroup = Radio.Group;
const Option = Select.Option;

const LeaseCreateStepTwo = (props) => {
  const fields = props.fields;
  const currentRent = 0;
  console.log(currentRent)

  const [form] = Form.useForm();

  return (
    <Card>
        <Form 
        layout="vertical"
        name="global_state"
        fields={fields}
        form={form}
        onFieldsChange={(changedFields, allFields) => {
          props.onChange(allFields);
        }}
        >
          <div>
            <h3>Choose the Lease Dates</h3>
              <Form.Item 
              label="Choose a Lease Type"
              name='lease_type'
              >
              <RadioGroup size='large' style={{width:'100%', display:'inline-flex'}}>
                    <Card 
                    hoverable 
                    style={{width:'265px', height:'140px', margin:'10px', background: form.getFieldValue('lease_type') === 'monthly' ? '#f2f2f2' : '#fff'}} 
                    onClick={() => form.setFieldsValue({lease_type: 'monthly'})}
                    >
                        <div style={{textAlign:'center'}}><InteractionOutlined style={{fontSize:'25px'}} /></div>
                        <p>Repeat payments each month</p>
                        <Radio value='monthly'>Month-to-Month</Radio>
                    </Card>
                    <Card 
                    hoverable 
                    style={{width:'265px', height:'140px', margin:'10px', background: form.getFieldValue('lease_type') === 'fixed' ? '#f2f2f2' : '#fff'}} 
                    onClick={() => form.setFieldsValue({lease_type: 'fixed'})}
                    >
                        <div style={{textAlign:'center'}}><PushpinOutlined style={{fontSize:'25px'}} /></div>
                        <p>Designate a start and end date</p>
                        <Radio value='fixed'>Fixed Term</Radio>
                    </Card>
              </RadioGroup>
              </Form.Item>
          </div>
          <Row type='flex'>
            <Col style={{paddingRight:'20px'}}>
              <Form.Item 
              label="Lease Start Date"
              name='start_date'
              >
                <DatePicker size='large' placeholder='Select start date' />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item 
              label="Lease End Date"
              name='end_date'
              >
                <DatePicker disabled={form.getFieldValue('lease_type') !== 'monthly' ? false : true} size='large' placeholder='Select end date' onChange={(date, dateString) => console.log(date, dateString)}/>
              </Form.Item>
            </Col>
          </Row>
          <h3>Rent Information</h3>
          <p>Use the amount set for the unit / property? Otherwise specify an amount</p>
          <Form.Item>
            <Switch checkedChildren="yes" unCheckedChildren="no" onChange={(checked) => checked ? form.setFieldsValue({amount: props.propertyUnits.find(item => item.id === props.stepOneFields.find(item => item.name.find(field => field === 'unit')).value).rent}) : form.setFieldsValue({amount: ''})}
            >
            Use unit rent amount?
            </Switch>
            </Form.Item>
            <Form.Item 
            label="Monthly Rent"
            name='amount'
            >
            <InputNumber
              size='large'
              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />  
            </Form.Item>
            <Form.Item 
            label="Monthly Due Date"
            name='monthly_due_date'
            >
            <Select size='large' defaultValue="1" style={{width:'200px'}}>
              <Option value="1">1st of the Month</Option>
              <Option value="2">2nd of the Month</Option>
              <Option value="3">3rd of the Month</Option>
              <Option value="4">4th of the Month</Option>
              <Option value="5">5th of the Month</Option>
              <Option value="6">6th of the Month</Option>
              <Option value="7">7th of the Month</Option>
              <Option value="8">8th of the Month</Option>
              <Option value="9">9th of the Month</Option>
              <Option value="10">10th of the Month</Option>
              <Option value="11">11th of the Month</Option>
              <Option value="12">12th of the Month</Option>
              <Option value="13">13th of the Month</Option>
              <Option value="14">14th of the Month</Option>
              <Option value="15">15th of the Month</Option>
              <Option value="16">16th of the Month</Option>
              <Option value="17">17th of the Month</Option>
              <Option value="18">18th of the Month</Option>
              <Option value="19">19th of the Month</Option>
              <Option value="20">20th of the Month</Option>
              <Option value="21">21st of the Month</Option>
              <Option value="22">22nd of the Month</Option>
              <Option value="23">23rd of the Month</Option>
              <Option value="24">24th of the Month</Option>
              <Option value="25">25th of the Month</Option>
              <Option value="26">26th of the Month</Option>
              <Option value="27">27th of the Month</Option>
              <Option value="last">Last Day of the Month</Option>
            </Select>
            </Form.Item>
        </Form>
    </Card>
  );
};

export default LeaseCreateStepTwo;