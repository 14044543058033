import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { List, Card, Col, Switch, Button, message, Spin } from 'antd';
import * as actions from '../../../../store/actions/auth';
import { connect } from 'react-redux';
import CardHeader from '../../../../components/CardHeader';
import coreApiClient from '../../../../store/apiService';

const NotificationSettings = (props) => {
    const userProfile = props.userProfile ? props.userProfile : {};
    const [residentAlerts, setResidentAlerts] = useState(false)
    const [maintenanceAlerts, setMaintenanceAlerts] = useState(false)
    const [applicationAlerts, setApplicationAlerts] = useState(false)
    const [leaseAlerts, setLeaseAlerts] = useState(false)
    const [realtyStackAlerts, setRealtyStackAlerts] = useState(false)

    useEffect(() => {
      setResidentAlerts(userProfile['custom:resident_alerts'] === 'true')
      setMaintenanceAlerts(userProfile['custom:maintenance_alerts'] === 'true')
      setApplicationAlerts(userProfile['custom:application_alerts'] === 'true')
      setLeaseAlerts(userProfile['custom:lease_alerts'] === 'true')
      setRealtyStackAlerts(userProfile['custom:realtystack_alerts'] === 'true')
    }, [userProfile])

    const updateProfile = (values) => {
        coreApiClient.profile.updateDetail(values)
        .then(res => {
            message.success('Updated notification preferences')
            props.fetchUserProfile()
        })
        .catch(error => {
            // handle error
            message.error(error.message)
        });
    }

    const data = [
      {
        title: 'Resident Alerts',
        description: <span>Receive notifications for resident communications and messages</span>,
        action: <span>
          <Switch 
          checked={residentAlerts} 
          onChange={(value) => {
            setResidentAlerts(value)
            updateProfile({'custom:resident_alerts': value.toString()})
          }} 
          />
          </span>,
      },
      {
        title: 'Maintenance Alerts',
        description: <span>Get notifications about maintenance requests</span>,
        action: <span><Switch checked={maintenanceAlerts} onChange={(value) => updateProfile({'custom:maintenance_alerts' : value.toString()})} /></span>,
      },
      {
        title: 'Application Alerts',
        description: <span>Get notified when a new application is submitted on one of your properties</span>,
        action: <span><Switch checked={applicationAlerts} onChange={(value) => updateProfile({'custom:application_alerts' : value.toString()})} /></span>,
      },
      {
        title: 'Lease Alerts',
        description: <span>Receive notifications leases ending in the upcoming month</span>,
        action: <span><Switch checked={leaseAlerts} onChange={(value) => updateProfile({'custom:lease_alerts' : value.toString()})} /></span>,
      },
      {
        title: 'RealtyStack Alerts',
        description: <span>Receive notifications for updates & changes to the RealtyStack platform</span>,
        action: <span><Switch checked={realtyStackAlerts} onChange={(value) => updateProfile({'custom:realtystack_alerts' : value.toString()})} /></span>,
      },
    ];

    return (
        <Col md={18} lg={20}
        style={{paddingLeft:'40px'}}
        >
          <CardHeader title='Notification' subtitle='Settings' description='Manage notification preferences' />
          {props.loadingUserProfile ? <Spin /> :
          <List
          itemLayout="horizontal"
          dataSource={data}
          loading={props.loadingUserProfile}
          renderItem={item => (
              <List.Item actions={[item.action]}>
                  <List.Item.Meta
                  title={<a href="https://ant.design">{item.title}</a>}
                  description={item.description}
                  />
              </List.Item>
              )}
          />
          }
        </Col>
    )
}

const mapStateToProps = (state) => {
  return{
      notificationPreferences: state.user.notificationPreferences,
      error: state.error,
      isAuthenticated: state.user.isAuthenticated,
      updatingProfile: state.user.updatingProfile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      updatePreferences: (values) => dispatch(actions.updateNotificationPreferences(values)),
      getUser: () => dispatch(actions.getUserProfile()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);