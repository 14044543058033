import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/auth';
import { Link } from 'react-router-dom';
import { List, Col } from 'antd';
import CardHeader from '../../../../components/CardHeader';

class SubscriptionSettings extends React.Component {
    render () {

      const data = [
        {
          title: 'Subscription Type',
          description: this.props.authenticatedUser.account_subscription,
          action: <span><Link to='/account/settings/subscription/change/'>Change</Link></span>,
        },
      ];

        return (
              <Col md={18} lg={20}
              style={{paddingLeft:'40px'}}
              >
                <CardHeader title='Subscription' subtitle='Settings' description='Manage your RealtyStack subscription' />
                <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                    <List.Item actions={[item.action]}>
                        <List.Item.Meta
                        title={<a href="https://ant.design">{item.title}</a>}
                        description={item.description}
                        />
                    </List.Item>
                    )}
                />
              </Col>
        )
    }
}

const mapStateToProps = state => {
  return {
    authenticatedUser : state.user.authenticatedUser,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(actions.getUserProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSettings);
